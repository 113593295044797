/* eslint-disable */
import { Component } from 'react';
import { FieldPlayer } from 'Components'
import { _, Utils } from 'Helpers'

class SpaceAroundRow extends Component {
  render() {
    const _LineupList = _.orderBy(
      this.props.LineupList,
      function (o) {
        return new Number(o.rank);
      },
      ['asc']
    )
    const {homeView} = this.props
    // console.log(this.props,'zoom: 84%;')
    return (
      <>
        {
          this.props.is_single_player ?
            <div {...{ className: `team-row-wrap ${this.props.className}` }}>
              {_.map(
                this.props.LineupList,
                (item, idx) => {
                  if (!_.isUndefined(this.props.position) && (Utils.PlayerPositionCheck(item.position, this.props.ALL_POSITION_CLONE[this.props.SelectedWeek]) == this.props.position)) {
                    return (
                      <FieldPlayer key={idx} item={this.props.getPlayerItem(item)} getPlayerItem={this.props.getPlayerItem(item)} isDefault={_.isNull(item.player_uid)} {...this.props} />
                    )
                  }
                  if (_.isUndefined(this.props.position)) {
                    return (
                      <FieldPlayer key={idx} item={this.props.getPlayerItem(item)} getPlayerItem={this.props.getPlayerItem(item)} isDefault={_.isNull(item.player_uid)} {...this.props} />
                    )
                  }
                })}
            </div>
            :
            <div className={`team-row ${this.props.className} ${this.props.is_bench_player ? 'bench-space-around' : ''}`}>
              <div className="space-around ">
                {_.map(
                  _.orderBy(this.props.LineupList, ['bench_order'], ['asc']),
                  (item, idx) => {
                    if ((!item.is_bench_player || item.is_bench_player == 0) && (item.position == this.props.position)) {
                      return (
                        <FieldPlayer key={idx} item={this.props.getPlayerItem(item)} getPlayerItem={this.props.getPlayerItem(item)} isDefault={_.isNull(item.player_uid)} {...this.props} />
                      )
                    }
                    if (item.is_bench_player && (item.is_bench_player == this.props.is_bench_player)) {
                      return (
                        <FieldPlayer key={idx} item={this.props.getPlayerItem(item)} getPlayerItem={this.props.getPlayerItem(item)} isDefault={_.isNull(item.player_uid)} {...this.props} benchClass={'pos-caption'}/>
                      )
                    }
                  })}
              </div>
              {/* {homeView? '':<div>
                {this.props.position == "GK" && <div className='tac'>Goal Keeper</div>}
                {this.props.position == "DF" && <div className='tac'>Defenders</div>}
                {this.props.position == "MF" && <div className='tac'>Midfielders</div>}
                {this.props.position == "FW" && <div className='tac'>Forward</div>}
              </div>} */}
            </div>
        }
      </>
    )
  }
}
SpaceAroundRow.defailtProps = {
  position: null,
  className: ''
}
export default SpaceAroundRow;