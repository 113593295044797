/* eslint-disable */
import { Component } from 'react';
import { Container } from 'reactstrap';
import { Utils, API, Http, Trans } from 'Helpers';
import { MessageBox } from 'Components';
import parse from 'html-react-parser';
import { ReduxHOC } from 'HOC';


var ourImage = new Image();
ourImage.Class = 'irimg';
// const image = document.querySelector('img');
// const parentDiv = image.parentElement.

class StaticPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      PageData: [],
      posting: true
    }
  }


  componentDidMount() {
    const { page_id } = this.props
    const { SelectedLeague } = this.state
    Http.post(API.GET_PAGE_DETAIL, {
      "page_id": page_id,
      "sports_id": SelectedLeague.sports_id
    }).then(res => {
      this.setState({
        PageData: res.data,
        posting: false
      }, () => {
        // parentDiv.classList.add('specific-class');
        this.setState({ posting: false })
      })

    })
  }

  render() {
    const { name } = this.props
    const { PageData, posting } = this.state
    const MessageBoxProps = {
      Title: name,
      Subtitle: ''
    }
    return (
      <Container className='small-container'>
        {
          posting ?
            <Utils.Spinner primary />
            :
            <>
              {
                _.isEmpty(PageData) ?
                  <MessageBox {...MessageBoxProps} />
                  :
                  <>
                    <div className="title-stp">
                      <Trans>{PageData.page_title || name}</Trans>
                    </div>
                    <div className="static-page-body">

                      {parse(PageData.page_content)}


                    </div>
                  </>
              }
            </>
        }
      </Container>
    );
  }
}

export default ReduxHOC(StaticPage);
