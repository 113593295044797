/* eslint-disable */
import { Component } from 'react';
import Moment from 'react-moment';
import { CountDownTimer } from 'Components';
import { withRouter, Trans, Utils } from 'Helpers'
import { ReduxHOC } from 'HOC';
let momentLib;
class DeadlineClock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: true,
      hoursMinSecs: {},
      days: '',
      SelectedLeague: Utils.getMultiLeg(),
    }
  }
  
  componentDidMount() {
    const { i18n, date } = this.props
    // if(i18n.language === 'ar') {
    //   momentLib = require('moment/locale/ar')
    // }
    this.timeDifference(date)
  }

  timeDifference = (dateapi) => {
    let date1 = new Date(dateapi);
    let date2 = new Date();
    var res = Math.abs(date1 - date2) / 1000;
    var days = Math.floor(res / 86400);
    var hours = Math.floor(res / 3600) % 24;
    var minutes = Math.floor(res / 60) % 60;
    var seconds = res % 60;
    this.setState({
      posting: false,
      days: days,
      hoursMinSecs: {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: Math.round(seconds),
        timertime: { hours: hours, minutes: minutes, seconds: seconds }
      }
    })
  }

  render() {
    const { gameweek, date, is_full } = this.props
    const { hoursMinSecs, posting, days, SelectedLeague } = this.state
    return (
      <div className='deadline-clock'>
        {
          is_full ?
          <>
            <i className="icon-hourglass" />
            <div>
              {
                is_full &&
                <div className="clock-title"> {gameweek} <Trans>Deadline</Trans></div>
              }
              {
                !posting &&
                <div {
                  ...{
                    className: `time-value`
                  }
                }>
                  {
                    days > 0 ?
                      <Moment date={date} format="DD MMM H:mm" />
                      :
                      !_.isEmpty(hoursMinSecs) && <CountDownTimer hoursMinSecs={hoursMinSecs} {...this.props} />
                  }
                </div>
              }
            </div>
          </>
          :
          !_.isEmpty(hoursMinSecs) && 
          <CountDownTimer hoursMinSecs={hoursMinSecs} {...this.props} />
        }
      </div>
    );
  }
}
DeadlineClock.defaultProps = {
  is_full: true
}
export default withRouter(ReduxHOC(DeadlineClock))