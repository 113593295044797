/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { _, Http, API, Utils, Constant, Trans, NavLink } from 'Helpers';

import { MessageBox, NotificationComponents } from 'Components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ReduxHOC } from 'HOC';


class Notification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      NotificationList: [],
      isLoaderShow: false,
      hasMore: false,
      Offset: Constant.CURRENT_OFFSET,
      NotificationListBlank: false
    }
  }

  getNotification = () => {
    const { Offset, NotificationList } = this.state;
    this.setState({ isLoaderShow: true })

    let param = {
      "limit": Constant.ITEMS_PERPAGE,
      "offset": Offset
    }
    Http.post(API.NOTIFICATIONS_DATA, param).then(response => {
      this.setState({
        isLoaderShow: false,
        NotificationList: Offset == Constant.CURRENT_OFFSET ? response.data.notification_list : [...NotificationList, ...response.data.notification_list],
        hasMore: response.data.is_load_more,
      }, () => {
        this.setState({
          NotificationListBlank: _.isEmpty(response.data.notification_list) && _.isEmpty(this.state.NotificationList)
        })
      });
    }).catch(error => {
      this.setState({ isLoaderShow: false });
    });
  }

  fetchMoreData = () => {
    let offset = this.state.Offset + Constant.ITEMS_PERPAGE;
    this.setState({ Offset: offset }, () => {
      this.getNotification()
    });
  }
  clearUnread = () => {
    Http.post(API.UPDATE_READ_ALL).then(response => {
      this.setState({
        unread_count: response.data.unread_count,
      });
    }).catch(error => {
    });
  }
  componentDidMount = () => {
    this.getNotification();
  }

  render() {
    const { i18n, t } = this.props;
    const { NotificationList, hasMore, isLoaderShow, NotificationListBlank } = this.state;

    const MessageBoxProps = {
      Title: t("You don't have any notifications"),
      ButtonLabel: t("Let's get started!"),
      ButtonPath: "/main",
    }

    const NotificationProps = {
      NotificationList
    }

    return (
      <Container className='small-container'>
        <Row>
          <Col>
            <div className="hero-title noti-header">
              <Trans>Notifications</Trans> 
              <UncontrolledDropdown className='notification-menu main-noti-icn'>
                <DropdownToggle tag='div' className='notification-menu-toggle'>
                  <i className="icon-others xcolor-change" />
                </DropdownToggle>
                <DropdownMenu right={i18n.language == "en" || i18n.language == "en-US"} className='dark-dropdown'>
                  <DropdownItem onClick={() => this.clearUnread()}><Trans>Mark all as read</Trans></DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              NotificationListBlank ? 
              <MessageBox {...MessageBoxProps} />
              :
                <InfiniteScroll
                  dataLength={NotificationList.length}
                  next={this.fetchMoreData}
                  hasMore={hasMore}
                >
                  <NotificationComponents {...NotificationProps} />
                  <Utils.FlexboxLoader init={isLoaderShow} className='notification-loader'/>
                </InfiniteScroll>
            }
          </Col>
        </Row>

      </Container>
    )
  }
}

export default ReduxHOC(Notification)