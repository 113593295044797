/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input, Form } from 'reactstrap'
import { Utils, Http, API, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      isMszShow: false,

      email: "",
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    this.setState({
      formValid: Utils.isFormValid(['email'], this.state)
    });
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { email } = this.state;
    Http.post(API.FORGOT_PASSWORD, {
      "email": email
    }).then(response => {
      this.setState({ 
        posting: false,
        isMszShow: true
      });
    }).catch(error => {
      this.setState({ posting: false });
    });
  }
  render() {
    const { isOpen, toggle, t } = this.props;
    const {
      email,
      formValid,
      posting,
      isMszShow
    } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className='custom-modal' size={'sm'}>
        {
          !isMszShow ?
            <Form onSubmit={this.onSubmit}>
              <ModalHeader>
                <div><Trans>Forgot Password?</Trans></div>
                <p><Trans>We'll send you a reset link to your registered email</Trans></p>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Input type="email" placeholder={t("Email")}
                    name='email'
                    required
                    maxLength={50}
                    value={email}
                    autoComplete="off"
                    onChange={this.handleChange}
                    invalid={Utils.isInvalid("email", email)}
                    valid={Utils.isValid("email", email)} />
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button disabled={!formValid || posting} block ><Trans>Submit</Trans></Button>
              </ModalFooter>
            </Form>
            :
            <div className="forgot-msz">
              <i className="icon-reset-link" />
              <div className="text-bold"><Trans>We have sent you a password reset link!</Trans></div>
              <p><Trans>Check your email for further instructions</Trans></p>
              <Button block onClick={() => toggle()}><Trans>Back to Login</Trans></Button>
            </div>
        }
      </Modal>
    )
  }
}
export default ReduxHOC(ForgotPassword)
