/* eslint-disable */
import React, { Component } from 'react';
import { Utils, _, Trans } from 'Helpers';
import { Tabs, Tab } from 'react-tabs-scrollable'
import { ReduxHOC } from 'HOC';
class GameweekNativeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.SelectedWeek,
      scrollLeftPosition: 0,
      selected_league: Utils.getMultiLeg(),
      init: false
    };
    this.tabRef = null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.gameweeklastupdate != nextProps.gameweeklastupdate) {
      if (!this.state.init) {
        //console.log('radha');
        this.setState({
          activeTab: _.findIndex(nextProps.scoring_weeks, (o) => { return o.week == nextProps.SelectedWeek }),
          init: true
        });

      }
    }
  }

  clickHandler = (e, index) => {
    const {
      SelectWeekHandle,
      scoring_weeks
    } = this.props
    this.setState({
      activeTab: index
    })
    SelectWeekHandle(scoring_weeks[index])
  }

  render() {
    const {
      scoring_weeks,
      SelectedWeek,
      active_week,
      next_week,
      last_week,
      joined_week,
      is_fixture,
      is_temp,
      i18n,
      selectedWk
    } = this.props
    const {
      selected_league,
      activeTab,
    } = this.state
   // console.log(SelectedWeek,"activeTabactiveTab");

    return (
      <Tabs
        isRTL={i18n.language == "ar"}
        activeTab={activeTab}
        onTabClick={this.clickHandler}
        tabsClassName='gameweek-inner'
        tabsContainerClassName='gameweek-weekslider native-scroll'
        rightBtnIcon={<i className="icon-next" />}
        leftBtnIcon={<i className="icon-previous" />}
      >
        {
          _.map(scoring_weeks, (item, idx) => {
            if (!(item.week <= next_week) && !is_fixture) return '';
            return (
              <Tab key={idx} {...{ className: `week-item${item.week == SelectedWeek || item.week == selectedWk ? ' active' : ''}` }}>
                <WeekCard
                  {...{
                    item,
                    selected_league,
                    is_temp,
                    active_week,
                    joined_week,
                    next_week,
                    is_fixture,
                    last_week
                  }} />
              </Tab>
            )
          })
        }
      </Tabs>
    )
  }
}
GameweekNativeSlider.defaultProps = {
  scoring_weeks: [],
  SelectedWeek: null,
  selectedWk: null,
  SelectWeekHandle: () => { },
  gameweeklastupdate: null,
  active_week: null,
  next_week: null,
  last_week: null,
  joined_week: null,
  is_fixture: false,
  is_temp: false
}
export default ReduxHOC(GameweekNativeSlider)


const WeekCard = ({
  item,
  selected_league,
  is_temp,
  active_week,
  joined_week,
  next_week,
  last_week,
  is_fixture
}) => {
  return (
    <>
      {
        is_temp ?
          <div className="week-label"><Trans>FIFA World Cup 2022</Trans></div>
          :
          <div className="week-label">{selected_league.week_label[item.week] || item.week}</div>
      }
      {
        !is_fixture &&
        <div className="week-status">
          {
            (item.week == active_week && (active_week < next_week || active_week == last_week)) &&
            <>
              <b></b>
            </>
          }
          {
            (item.week == next_week && (active_week < next_week || (joined_week == next_week && next_week != active_week) || active_week != last_week)) &&
            <>
              <span><Trans>Upcoming</Trans></span>
            </>
          }
          {
            !(item.week == next_week && (active_week < next_week || (joined_week == next_week && next_week != active_week) || active_week != last_week)) &&
            <>
              <span>{item.total_score}{" "}<Trans>Pts</Trans></span>
            </>
          }
        </div>
      }
    </>
  )

}