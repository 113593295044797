import { Component } from 'react';
import { _, API, Http, Utils, Trans, Images } from 'Helpers';
import { Col, Container, Row } from 'reactstrap';
import { Banner, MultiBanner } from 'Components';
import Slider from 'react-slick';

var settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 9,
  slidesToScroll: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1
      }
    }
  ]
};

class Prizes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      // miniLeagueData:''
      HomeData: [],
      isLoader: false,
      tabID: '1',
      allentries: false,
      weekAllentries: false,
      monthAllentries: false,
      selectedValMonth: '11'
    }

  }
  getMonthName(monthNumber) {
    const months = [
      "Jan", "Feb", "March", "Apr", "May", "June",
      "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    return months[monthNumber - 1]; // Month numbers start from 1
  }
  getPrizes = () => {
    this.setState({ isLoader: true })
    const { league_id, sports_id } = this.state.SelectedLeague;
    let param = {
      "sports_id": sports_id,
      "league_id": league_id,
    }
    Http.post(API.GET_PRIZES, param).then(res => {
      const { data } = res;
      // this.setState({ isLoaderShow: false })
      // this.GetTopScoringPlayers();
      if (res.response_code === 200) {
        this.setState({
          HomeData: data,
          isLoader: false
        })
      }

    }).catch(error => {
      console.error(error)
    });

  }
  getWinner = () => {
    this.setState({ isLoader: true })
    const { league_id } = this.state.SelectedLeague;
    let param = {
      "league_id": league_id,
    }
    Http.post(API.GET_WINNER_DATA, param).then(res => {
      const { data } = res;
      if (res.response_code === 200) {
        this.setState({
          WinnerData: data.winner_list,
          isLoader: false,
          teamList: data.team_list
        })
      }

    }).catch(error => {
      console.error(error)
    });

  }
  filterTeamsByLeagueId = (leagueId) => {
    let teamList = this.state.teamList
    return teamList.filter(team => team.team_league_id === leagueId)
      .map(team => team.team_name);
  };
  scrollToJoin = () => {
    const element = document.getElementById("prz-update");
    element.scrollIntoView({});
  };
  moreEntries(type) {
    if (type === 'overall') {
      this.setState({ allentries: !this.state.allentries })
    }
    else if (type === 'monthly') {
      this.setState({ monthAllentries: !this.state.monthAllentries })
    }
    else if (type === 'weekly') {
      this.setState({ weekAllentries: !this.state.weekAllentries })
    }
  }
  // 
  componentDidMount() {
    this.getPrizes()
    this.getWinner()

    if (!_.isUndefined(this.props.nw) && this.props.nw) {
      this.scrollToJoin()
    }
  }

  isMobileView = () => {
    return window.innerWidth <= 767;
  }
  monthSort(itm) {
    console.log(itm, 'asdsadsad')
    this.setState({ selectedValMonth: itm.month })
  }
  renderMonths(itm) {

    return (
      <>
        <Slider {...settings}> {_.map(itm, (item, idx) => {
          return (<div key={idx} className={this.state.selectedValMonth === item.month ? 'month-names selected-mth' : 'month-names'}
            onClick={() => { this.monthSort(item) }}>{this.getMonthName(item.month)}</div>)
        })}
        </Slider>
      </>
    )
  }
  render() {
    const { HomeData, isLoader, tabID, allentries, WinnerData } = this.state

    return (
      <Container className='lobby-container prize-wrap p-fix-prz'>
        <div className='multibanner-wrap mb-pd'>
          <Banner type='56' paddingBottom />
          <MultiBanner type='22' paddingBottom />
        </div>
        {isLoader ?
          <div className="spinner-static">
            <Utils.Spinner primary />
          </div>
          : <>
            {_.isEmpty(HomeData) ?
              <Row>
                <Col>
                  <div className='prizes-box'>
                    <h5 className='no-data-prize'><Trans>Prices not added by admin yet</Trans></h5>
                  </div>
                </Col>
              </Row>
              :
              <Row className='pd-fix'>

                <Col>
                  <div className='prize-main-wrapper'>
                    <div className="stats-tab prize-page-tab">
                      <span className={tabID === '1' ? 'selected min-w-unselect' : 'min-w-unselect'} onClick={() => this.setState({ tabID: '1' })}><Trans>Prizes</Trans></span>
                      <span className={tabID === '2' ? 'selected min-w-unselect' : 'min-w-unselect'} onClick={() => this.setState({ tabID: '2' })}><Trans>Winners</Trans></span>
                    </div>
                    {tabID === '1' && _.map(HomeData, (itm, idx) => {
                      return (
                        <div key={idx} id={itm.contest_access_type === '2' && idx !== 1 ? '' : idx === 1 ? 'prz-update' : ''}>
                          <div className='bold-cls'>
                            <span>
                              {itm.contest_name}
                            </span>
                            <div className='bottom-gw-disc'><Trans>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {itm.start_week} - {itm.end_week}</Trans></div>
                          </div>
                          {itm.contest_access_type === '2' &&
                            <div className='banner-img-block'>
                              {/* <img className='img-width' src={this.isMobileView() ? itm.app_banner_image : itm.web_banner_image} alt='league-banner' /> */}
                              <Banner type={this.isMobileView() ? 64 : 63} />
                              <div className='small-container m-fix'>
                                <div className='prz-wrap'>
                                  <span className='min-max-prz'><Trans>Rank</Trans></span>
                                  <span className='prz-name mr-10'><Trans>Prizes</Trans></span>
                                </div>
                              </div>
                            </div>
                          }
                          <div className='prize-ct-wrap small-container'>
                            {!_.isEmpty(itm.prize_distibution_detail) &&
                              <div className='prize-distribution-main'>
                                {_.map(itm.prize_distibution_detail
                                  , (item, index) => {

                                    return (
                                      <div className='prz-wrap' key={index}>
                                        <span className='mw-32p'>
                                          {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.LEFT_LEAFS} alt='' className='img-revert' />}
                                          <span className={(item.min === '1' || item.min === '2' || item.min === '3') ? 'xbold-txt' : 'min-max-prz'}>
                                            {item.min === item.max ? item.min : item.min + ' - ' + item.max}
                                          </span>
                                          {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' className='img-revert' />}
                                        </span>
                                        <span className={(item.min === '1' || item.min === '2' || item.min === '3') ? 'xxbold-txt prz-name' : 'prz-name'}><Trans>{item.name}</Trans></span>
                                      </div>
                                    )
                                  })}
                              </div>
                            }
                            {!_.isEmpty(itm.weekly_prizes) &&
                              <div className='weekly-wrap'>
                                {
                                  _.map(itm.weekly_prizes, (item, index) => {
                                    return (
                                      <div className='week-names' key={index}>
                                        <div className='weeks'>
                                          <span className='week-title bold-cls'>
                                            <span>
                                              <Trans>{Utils.getMultiLeg().week_label[item.week]} Prizes</Trans>
                                            </span>
                                          </span>
                                          <div className='week-subtitle bottom-gw-disc'>{Utils.getMultiLeg().week_label[item.week]}</div>
                                        </div>

                                        {!_.isEmpty(item) &&
                                          _.map(item, (weeks, idx) => {
                                            return (
                                              <div className='week-prz' key={idx}>
                                                {!_.isEmpty(weeks) &&
                                                  _.map(weeks, (itm, key) => {
                                                    return (
                                                      <div className='prz-wrap' key={key}>
                                                        <span className='mw-32p'>
                                                          {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.LEFT_LEAFS} alt='' className='img-revert' />}
                                                          <span className={itm.min === '1' || itm.min === '2' || itm.min === '3' ? 'xbold-txt' : 'min-max-prz'}>
                                                            {itm.min === itm.max ? itm.min : itm.min + ' - ' + itm.max}
                                                          </span>
                                                          {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' className='img-revert' />}
                                                        </span>
                                                        <span className={(itm.min === '1' || itm.min === '2' || itm.min === '3') ? 'xxbold-txt prz-name' : 'prz-name'}><Trans>{itm.name}</Trans></span>
                                                      </div>
                                                    )
                                                  })}

                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                            {
                              (itm.contest_access_type === '2' && !_.isEmpty(itm.monthly_prizes)) &&
                              <div className='weekly-wrap'>
                                {
                                  _.map(itm.monthly_prizes, (item, index) => {
                                    return (
                                      <div className='week-names' key={index}>
                                        <div className='weeks'>
                                          <span className='week-title bold-cls'>
                                            <span>
                                              <Trans> {item.label} Prizes</Trans>
                                            </span>
                                          </span>
                                        </div>

                                        {
                                          <div className='week-prz'>
                                            {!_.isEmpty(item) &&
                                              _.map(item.prize, (itm, key) => {
                                                return (
                                                  <div className='prz-wrap' key={key}>
                                                    <span className='mw-32p'>
                                                      {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.LEFT_LEAFS} alt='' className='img-revert' />}
                                                      <span className={itm.min === '1' || itm.min === '2' || itm.min === '3' ? 'xbold-txt' : 'min-max-prz'}>
                                                        {itm.min === itm.max ? itm.min : itm.min + ' - ' + itm.max}
                                                      </span>
                                                      {(itm.min === '1' || itm.min === '2' || itm.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' className='img-revert' />}
                                                    </span>
                                                    <span className={(itm.min === '1' || itm.min === '2' || itm.min === '3') ? 'xxbold-txt prz-name' : 'prz-name'}><Trans>{itm.name}</Trans></span>
                                                  </div>
                                                )
                                              })}

                                          </div>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            }
                          </div>

                        </div>
                      )
                    })}
                    {tabID === '2' &&
                      <div className='hw-fix'>
                        {_.map(WinnerData, (item, index) => {
                          return (
                            <>
                              {/* weekly below */}
                              {(!_.isEmpty(JSON.parse(item.weekly_winner)) && !_.isNull(JSON.parse(item.weekly_winner)) && !_.isUndefined(JSON.parse(item.weekly_winner))) &&
                                <div className='winners-container'>
                                  <div className='title-win'>{item.contest_name} <Trans>Weekly Winners</Trans></div>
                                  <Banner type='69' />
                                  {/* <img src={Images.DUMMY_WINN} alt='' className='fluid-winners' /> */}
                                  <div>
                                    <div className='dflex-table heads'>
                                      <span className='table-headings'><Trans>Gameweek</Trans></span>
                                      <span className='table-headings'><Trans>Winner’s Name</Trans></span>
                                    </div>
                                    {_.map(JSON.parse(item.weekly_winner).reverse(), (itm, idx) => {
                                      if (!this.state.weekAllentries) { if (idx > 4) { return } }
                                      return <div className='dflex-table' key={idx}>
                                        <span className='table-left'>{itm.week}</span>
                                        <span className='table-right'>{itm.winner_name}</span>
                                      </div>
                                    })}
                                  </div>
                                  <div className='btm-arrow-st'>
                                    {JSON.parse(item.weekly_winner).length > 5 && <img src={Images.BTM_ARRW_WINS} className={this.state.weekAllentries ? 'reverse-arrows' : ''}
                                      alt='' onClick={() => { this.moreEntries('weekly') }} />}
                                  </div>
                                </div>
                              }
                              {/* monthly below */}
                              {(!_.isEmpty(JSON.parse(item.monthly_winner)) && !_.isNull(JSON.parse(item.monthly_winner)) && !_.isUndefined(JSON.parse(item.monthly_winner))) &&
                                <div className='winners-container'>
                                  <div className='title-win'>{item.contest_name} <Trans>Club Monthly Winners</Trans></div>
                                  <div className='slider-container-wn'>
                                    {this.renderMonths(JSON.parse(item.monthly_winner))}
                                  </div>
                                  <Banner type='70' />
                                  {/* <img src={Images.DUMMY_WINN} alt='' className='fluid-winners' /> */}
                                  {_.map(JSON.parse(item.monthly_winner), (it, ix) => (<div>
                                    {this.state.selectedValMonth === it.month
                                      &&
                                      <div className='dflex-table heads'>
                                        <span className='table-headings'><Trans>Clubs</Trans></span>
                                        <span className='table-headings'><Trans>Winner’s Name</Trans></span>
                                      </div>}
                                    {!_.isEmpty(it.winner) ? _.map(it.winner, (itm, idx) => {
                                      if (this.state.selectedValMonth === it.month) {
                                        if (!this.state.monthAllentries) { if (idx > 4) { return } }
                                        return <div className='dflex-table' key={idx}>
                                          <span className='table-left'>{this.filterTeamsByLeagueId(itm.team_league_id)}</span>
                                          <span className='table-right'>{itm.winner_name}</span>
                                        </div>
                                      }
                                    })
                                      :
                                      this.state.selectedValMonth === it.month
                                      &&
                                      <div className="nodata-view ">
                                        <Trans>No Record Available</Trans>
                                      </div>
                                    }
                                    {this.state.selectedValMonth === it.month
                                      &&
                                      it.winner.length > 5 &&
                                      <div className='btm-arrow-st'>
                                        <img src={Images.BTM_ARRW_WINS} className={this.state.monthAllentries ? 'reverse-arrows' : ''} alt='' onClick={() => { this.moreEntries('monthly') }} />
                                      </div>}
                                  </div>))}

                                </div>
                              }
                              {/* overall below */}
                              <div className='winners-container'>
                                <div className='title-win'>{item.contest_name}</div>
                                {item.contest_id === '1' ?
                                  <Banner type='71' /> :
                                  <Banner type='72' />
                                }
                                {/* <img src={Images.DUMMY_WINN} alt='' className='fluid-winners' /> */}
                                <div>
                                  <div className='dflex-table heads'>
                                    <span className='table-headings'><Trans>Rank</Trans></span>
                                    <span className='table-headings'><Trans>Winner’s Name</Trans></span>
                                  </div>
                                  {(!_.isEmpty(JSON.parse(item.overall_winner)) && !_.isNull(JSON.parse(item.overall_winner)) && !_.isUndefined(JSON.parse(item.overall_winner))) ? _.map(JSON.parse(item.overall_winner), (itm, idx) => {
                                    if (!allentries && this.state.typeSel !== 'overall') { if (idx > 4) { return } }
                                    return <div className='dflex-table' key={idx}>
                                      <span className='table-left'>{itm.rank}</span>
                                      <span className='table-right'>{itm.winner_name}</span>
                                    </div>
                                  })
                                    :
                                    <div className="nodata-view ">
                                      <Trans>No Record Available</Trans>
                                    </div>
                                  }
                                </div>
                                <div className='btm-arrow-st'>
                                  {((!_.isEmpty(JSON.parse(item.overall_winner)) && !_.isNull(JSON.parse(item.overall_winner)) && !_.isUndefined(JSON.parse(item.overall_winner)) && JSON.parse(item.overall_winner).length > 5)) &&
                                    <img src={Images.BTM_ARRW_WINS} className={allentries ? 'reverse-arrows' : ''} alt='' onClick={() => { this.moreEntries('overall') }} />}
                                </div>
                              </div>

                            </>
                          )
                        })}
                      </div>}
                  </div>
                </Col>
              </Row>
            }
          </>}
      </Container>
    )
  }
}

export default Prizes;