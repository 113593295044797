/* eslint-disable */
import { Component } from 'react';
import { DefaultFooter, DefaultHeader, ErrorBoundary, CookieConsentWrap } from 'Components'
import {
  API, Http,
  Auth, Constant, Utils, _
} from 'Helpers'
import { Redirect, Route, Switch } from "react-router-dom";
import { ReduxHOC } from 'HOC';
// routes config
import Routes from "Routes"
import { SelectLeague } from "Components"
import LoginFormSMWraped from 'Components/LoginFormSM/LoginFormSM';
class RenderRoute extends Component {

  removeComponent = () => {
    return _.omit(this.props, ['component'])
  }

  componentDidMount() {
    const { theme, nodes } = this.props
    if (theme) document.documentElement.setAttribute("class", theme);
    setTimeout(() => {
      // nodes.DefaultHeader.setProps(this.removeComponent())
      // nodes.DefaultFooter.setProps(this.removeComponent())
    }, 0)

    if (!Utils.getMultiLeg() && this.props.name !== 'ActivateAccount') {
      this.props.history.push(Constant.DASHBOARD_PATH)
    }
  }
  componentWillUnmount() {
    document.documentElement.removeAttribute("class");
    this.setState = () => {
      return;
    };
  };

  render() {
    const { isPublic, isCommon, isAuthenticate } = this.props
    if ((isAuthenticate && Auth.getAuth()) || (isPublic && !Auth.getAuth()) || (isCommon)) {
      //   console.log(name, '| isPublic: ', isPublic, '| isAuthenticate: ', isAuthenticate, '| isCommon: ', isCommon);
      return (<this.props.component {...this.removeComponent()} />)
    }
    return (<Redirect from="*" to={Auth.getAuth() ? Constant.DASHBOARD_PATH : Constant.DEFAULT_ROOT} />)
  }
}

class Layout extends Component {
  constructor(props) {
    super(props)
    this.nodes = {}
    this.state = {
      posting: true,
      LanguageSwitchModalShow: window.ReactNativeWebView ? false : Utils.getCookie('lang') === '',
    }
  }

  // Get App Master Data
  GetAppMasterData = () => {
    if (!Utils.getMasterData()) {
      Http.post(API.GET_APP_MASTER_DATA).then(res => {
        const { actions } = this.props;

        Utils.setMasterData(res.data)
        Utils.setLang(res.data.default_language)
        actions.setAppMaster(res.data) // Redux Data Update
        if (res.data.IS_MULTILEAGUE == 1) {
          this.setState({ posting: false })
        }
        setTimeout(() => {
          if (Utils.getMultiLeg()) {
            this.setState({ posting: false })
          }
        }, 10)
      }).catch(err => {
        console.log(err);
      })
    } else {
      if (Utils.getMultiLeg()) {
        this.setState({ posting: false })
      }
    }
  }

  componentDidMount() {
    this.GetAppMasterData()
    // this.GetAdvBanner()


    const { i18n } = this.props;
    document.documentElement.setAttribute("lang", i18n.language);
    document.documentElement.setAttribute("dir", i18n.language === 'ar' ? 'rtl' : 'ltr');

    // Redux Data Update
    const { actions } = this.props
    actions.setAppMaster(Utils.getMasterData() || {})
    actions.setAdData(Utils.GetSessionStorage('banner') || {})
    if (window.ReactNativeWebView) {

      window.addEventListener('message', this.onMessageReceived);
    }
    if (Auth.getAuth()) {
      this.GetNewBanner()
      actions.setProfileData(Utils.getProfile() || {})
      actions.setCurrentLineup(Utils.getCurrentLineup())
    } else {
      this.GetNewBanner()
    }
  }
  componentWillUnmount() {
    if (window.ReactNativeWebView) {
      window.removeEventListener('message', this.onMessageReceived);
    }
  }
  sendMessageToApp(action) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    } catch (error) { }
  }
  deviceIdHandler = () => {
    if (window.ReactNativeWebView) {
      let data = {
        action: "push",
        type: 'push',
        targetFunc: "pushFunc"
      };
      this.sendMessageToApp(data);
    }
  }

  onMessageReceived = (event) => {
    const message = event.data;
    if (message.action == "app_version") {
      if (_.isUndefined(message.version) || _.isNull(message.version)) {
        this.appVersionHandler()
      } else {

        Utils.setLS('app_version', message.version)
        Utils.setLS('device_type', message.device_type)
      }
    }
    else if (message.action == "push" && message.type == 'deviceid') {

      if (_.isUndefined(message.device_id) || _.isNull(message.device_id)) {
        this.deviceIdHandler()
      } else {
        Utils.setLS('device_id', message.device_id)
      }
    }
    else if (message.action == "push" && message.type == 'receive') {
      const data = message.notif
    }
    else if (message.action == "app_dep_linking" && message.type == 'android') {
      const data = message.pathName
    }
    // else if (message.action == "login" && message.type == 'google') {
    //   const data = message.response
    //   alert(JSON.stringify(data))
    // }
    // else if (message.action == "login" && message.type == 'facebook') {
    //   const data = message.response
    // }
    else {
      // alert(JSON.stringify(message))
    }
  }
  LanguageSwitchToggle = () => {
    this.setState({
      LanguageSwitchModalShow: !this.state.LanguageSwitchModalShow
    }, () => {
      window.location.reload(false);
    })
  }

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node
    }
  }

  SelectLeagueCallback = (res) => {
    console.log(111);
    if (this.state.posting && res) {
      this.setState({ posting: false }, () => {
        window.location.reload(false);
      })
    }
  }

  // GetAdvBanner = () => {
  //   const SelectedLeague = Utils.getMultiLeg()
  //   if (!SelectedLeague) return;
  //   const { league_id, sports_id} = SelectedLeague
  //   if(!SelectedLeague) return
  //   let s3_param = [
  //     API.S3_GET_BANNER,
  //     league_id
  //   ]
  //   let param = {
  //     league_id: league_id,
  //     sports_id: sports_id
  //   }
  //   // Http.post(API.GET_BANNER, param).then(res => {
  //   Http.s3(Utils.s3UrlConvert(s3_param), API.GET_BANNER, param).then(res => {
  //     let AdData = []
  //     for (let ad of res.data.advertisements) {
  //       for (let pos of res.data.positions) {
  //         if (ad.ad_position_id == pos.ad_position_id) {
  //           AdData.push({ ...pos, ...ad })
  //         }
  //       }
  //     }
  //     Utils.SetSessionStorage('banner', AdData);
  //     // Redux Data Update
  //     const { actions } = this.props;
  //     actions.setAdData(AdData || {})
  //   }).catch(err => {
  //     console.error(err);
  //   })
  // }

  GetNewBanner = () => {
    const SelectedLeague = Utils.getMultiLeg()
    if (!SelectedLeague) return;
    const { league_id, sports_id } = SelectedLeague
    if (!SelectedLeague) return
    let s3_param = [
      API.S3_NEW_BANNER,
      league_id
    ]
    let param = {
      league_id: league_id,
      sports_id: sports_id
    }
    // Http.post(API.NEW_BANNER, param).then(res => {
    Http.s3(Utils.s3UrlConvert(s3_param, true), API.NEW_BANNER, param).then(res => {
      let AdData = res.data.result;
      // for (let ad of res.data.result) {
      //   console.log(res.data.result)
      //   // for (let pos of res.data.positions) {
      //   //   console.log('position',res.data.positions)
      //   //   if (ad.ad_position_id == pos.ad_position_id) {
      //       AdData.push({ ...pos })
      //   //   }
      // //   // }
      // }
      Utils.SetSessionStorage('banner', AdData);
      // Redux Data Update
      const { actions } = this.props;
      actions.setAdData(AdData || {})
    }).catch(err => {
      console.error(err);
    })
  }

  render() {
    const {
      posting
      // , LanguageSwitchModalShow
    } = this.state
    // const LanguageSwitchModalProps = {
    //   isOpen: LanguageSwitchModalShow,
    //   toggle: this.LanguageSwitchToggle
    // }

    if (posting) return (
      <>
        <Utils.Spinner />
        {
          !Utils.getMultiLeg() &&
          <SelectLeague callback={this.SelectLeagueCallback} is_root />
        }
      </>
    )
    return (
      <>
        <div className='app-container'>
          <ErrorBoundary>
            <DefaultHeader getInstance={this.setNodeRef.bind(this, 'DefaultHeader')} />
            <main className='main'>
              <Switch>
                {
                  Routes.map((route, idx) => {
                    return route.component ?
                      <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={
                        props => <RenderRoute
                          nodes={this.nodes}
                          isPublic={route.isPublic || false}
                          isAuthenticate={route.isAuthenticate || false}
                          isCommon={route.isCommon || false}
                          name={route.name || ''}
                          page_id={route.page_id || ''}
                          meta={route.meta || {}}

                          headerHide={route.headerHide || false}
                          footerHideSm={route.footerHideSm || false}
                          footerHide={route.footerHide || false}

                          component={route.component}
                          {...route.screen}
                          {...props} />
                      } />
                      :
                      null;
                  })
                }
                <Redirect from="*" to={Auth.getAuth() ? Constant.DEFAULT_ROOT : Constant.DEFAULT_ROOT} />
              </Switch>
            </main>
            <DefaultFooter getInstance={this.setNodeRef.bind(this, 'DefaultFooter')} />
            <CookieConsentWrap />
          </ErrorBoundary>
        </div>
        <Utils.NavigationPromptWrap />
      </>
    );
  }
}

export default ReduxHOC(Layout);
