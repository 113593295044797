/* eslint-disable */
import { Images } from 'Helpers';
import { ReduxHOC } from 'HOC';
import { Component } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap'

const { REACT_APP_LANDING_TITLE } = process.env;

class AppDownloadModal extends Component {
  render() {
    const { root } = this.props
    const { isOpen = true, toggle = () => { } } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='download-modal-modal' size={'md'}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalBody>
          <Row className='download-modal-wrapper no-gutters'>
            <Col xs={12} sm={5}>
              <div className="phone-img">
                <img src={Images.PRIVATE_CONTEST_PHONE} width="114" height="230" alt="" />
              </div>
            </Col>
            <Col xs={12}  sm={7} className="justify-center">
              <div className="justify-center">
                <h1 className='title'>You’ll find this section in our app. Download our app for added fun</h1>
                <p>Download <b>{REACT_APP_LANDING_TITLE}</b> App from your android or iPhone.</p>
                <div className="store-btn">
                  {
                    root.AppMasterData.ios_app_link != "" &&
                    <a href={root.AppMasterData.ios_app_link} target='_blank' rel="noopener noreferrer"><img src={Images.APP_STORE_BADGE} alt="" /></a>
                  }
                  {
                    root.AppMasterData.android_apk_link != "" &&
                    <a href={root.AppMasterData.android_apk_link} target='_blank' rel="noopener noreferrer"><img src={Images.PLAY_STORE_BADGE} alt="" /></a>
                  }
                </div>
              </div>
            </Col>
          </Row>

        </ModalBody>

      </Modal>
    )
  }
}
export default ReduxHOC(AppDownloadModal);
