/* eslint-disable */
import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Utils, _, Http, API, Images, withRouter, Trans } from 'Helpers';
import { DeadlineClock } from 'Components';
import { ReduxHOC } from 'HOC';

class ConfirmTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OutPlayers: [],
      InPlayers: [],
      // OutPlayers: Utils.getTransferData().out,
      // InPlayers: Utils.getTransferData().in,
      SelectedLeague: Utils.getMultiLeg(),
      is_single_player: Utils.isSinglePlayer(),
      PlayerList: [],
      posting: true
    }
  }
  getPos = (val) => {
    return ''
    // const { ALL_POSITION } = this.props;
    // Utils.check_position(val.position, o.position_clone.split('to'))
  }
  onSubmit = () => {
    const { lineup_master_id, toggle, default_formation, SALARY_CAP } = this.props;
    const { SelectedLeague, OutPlayers, InPlayers, is_single_player, TransferPlayerData } = this.state;
    const { league_id, sports_id, sports_config } = SelectedLeague;

    let _transfer = []
    if (is_single_player) {
      let _pos = "1to184"
      for (let i = 0; i < InPlayers.length; i++) {
        _transfer.push({
          "out_player_id": OutPlayers[i] ? OutPlayers[i].player_uid : '',
          "out_player_team_id": OutPlayers[i] ? OutPlayers[i].player_team_id : '',
          "in_player_id": InPlayers[i].player_uid,
          "in_player_team_id": InPlayers[i].player_team_id,

          "out_player_pos": is_single_player ? _pos : OutPlayers[i].position,
          "in_player_pos": is_single_player ? _pos : InPlayers[i].position,
          ...((SALARY_CAP != 0) ? {
            "in_player_sal": OutPlayers[i].salary,
            "out_player_sal": InPlayers[i].salary,
          } : {})
        })
      }
    } else {
      _transfer = TransferPlayerData
    }
    let param = {
      "league_id": league_id,
      "sports_id": sports_id,
      "lineup_master_id": lineup_master_id,
      "transfer": _transfer,
      "selected_formation": default_formation,
    }
    Http.post(API.PROCESS_PLAYER_TRANSFER, param).then(res => {
      Utils.notify(res.message);
      Utils.removeTransferData()
      toggle(true)
    }).catch(err => {
      Utils.notify(err.message);
      console.error(err)
    })
  }
  makeTransferData = async () => {
    try {
      const { OutPlayers, InPlayers, PlayerList } = this.state;
      let constant_pos = Utils.getFormationInitial()
      let TransferPlayerData = [];

      await _.forEach(constant_pos, (item) => {

        let filterArrIn = _.filter(Utils.getTransferData().in, function (o) { return item == o.position; });
        let filterArrOut = _.filter(Utils.getTransferData().out, function (o) { return item == o.position; });

        if (filterArrOut.length == 0 || filterArrIn.length == 0) {
          return;
        }
        _.map(filterArrIn, (Player, idx) => {
          let player_obj = {
            "out_player_id": filterArrOut[idx].player_uid,
            "out_player_pos": filterArrOut[idx].position,
            "out_player_sal": filterArrOut[idx].salary,
            "out_player_team_id": filterArrOut[idx].player_team_id,

            "in_player_id": Player.player_uid,
            "in_player_pos": Player.position,
            "in_player_sal": Player.salary,
            "in_player_team_id": Player.player_team_id
          }
          TransferPlayerData.push(player_obj)

          PlayerList.push({
            "in": Player,
            "out": filterArrOut[idx]
          })
          InPlayers.push(Player)
          OutPlayers.push(filterArrOut[idx])
        })
      });

      this.setState({
        PlayerList: PlayerList,
        TransferPlayerData: TransferPlayerData,
        InPlayers: InPlayers,
        OutPlayers: OutPlayers,
      }, () => {
        this.setState({
          posting: false
        });
      });
    } catch (e) {
      console.log(e);
    }
  }
  UNSAFE_componentWillMount() {
    const { is_single_player } = this.state;
    if (is_single_player) {
      this.setState({
        OutPlayers: Utils.getTransferData().out,
        InPlayers: Utils.getTransferData().in,
        posting: false
      });
    } else {
      this.makeTransferData()
    }
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }



  render() {
    const {
      t,
      isOpen,
      toggle,
      next_week_start_time,
      next_week,
      team_info,
      ConsumeFreeTransfers,
      TransfersCast
    } = this.props;
    const {
      OutPlayers, InPlayers, is_single_player, posting
    } = this.state

    const DeadlineClockProps = {
      date: Utils.getUtcToLocal(next_week_start_time),
      gameweek: next_week
    }

    return (
      <Modal isOpen={isOpen} toggle={() => toggle(false)} className='custom-modal confirm-transfer-modal' size={'sm'}>
        <i className="close-btn icon-cancel" onClick={() => toggle()} />
        <ModalHeader>
          <div><Trans>Confirm Transfers</Trans></div>
        </ModalHeader>
        <ModalBody>
          <div className="deadline-clock-wrap">
            {
              next_week_start_time &&
              <DeadlineClock {...DeadlineClockProps} />
            }
          </div>
          <div className='in-out-wrap'>
            <p className='help-txt'><Trans>You're about to transfer</Trans></p>
            <div className='in-out'>
              <div className='arrow-txt'>
                {/* <img src={Images.DOWN_ARR} alt='' /> */}
                <span className='down-arrow-cls' />
                <span>Out</span>
              </div>
              <div className='arrow-txt border-l'>
                {/* <img src={Images.UP_ARR} alt='' /> */}
                <span className='up-arrow-cls' />
                <span>In</span>
              </div>
            </div>
          </div>

          {
            posting ?
              <></>
              :
              <>
                <div className="transfer-player-wrap">
                  {
                    !_.isEmpty(OutPlayers) &&
                    <div className="transfer-player-col">
                      {/* <div className="cell head"><Trans>Transfer Out</Trans></div> */}

                      {
                        _.map(OutPlayers, (item, key) => {
                          return (
                            <div className="cell" key={key}>
                              <div className='player-item'>
                                <div className="thumb-wrap">
                                  {
                                    is_single_player ?
                                      <img src={item.player_image || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                                      :
                                      <>
                                        <img src={item.jersey || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                                        <img src={item.flag || Images.DEFAULT_FLAG} alt="" className='flag' />
                                      </>
                                  }
                                </div>
                                <div className="player-info">
                                  <div className="player-name"><span title={item.full_name}>{item.full_name}</span></div>
                                  <div className="player-team">{item.team_abbr_lang}</div>
                                </div>
                              </div>
                            </div>

                          )
                        })
                      }

                    </div>
                  }

                  <div className="transfer-player-col">
                    {/* <div className="cell head"><Trans>Transfer In</Trans></div> */}
                    {
                      _.map(InPlayers, (item, key) => {
                        return (
                          <div className="cell" key={key}>
                            {
                              _.isEmpty(OutPlayers) ?
                                <div className='player-item'>
                                  <div className="thumb-wrap">
                                    {
                                      is_single_player ?
                                        <img src={item.player_image || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                                        :
                                        <>
                                          <img src={item.jersey || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                                          <img src={item.flag || Images.DEFAULT_FLAG} alt="" className='flag' />
                                        </>
                                    }
                                  </div>
                                  <div className="player-info">
                                    <div className="player-name"><span title={item.full_name}>{item.full_name}</span></div>
                                    <div className="player-team">{item.team_abbr_lang}</div>
                                  </div>
                                </div>
                                :
                                <div className='player-item'>
                                  <div className="player-info">
                                    <div className="player-name"><span title={item.full_name}>{item.full_name}</span></div>
                                    <div className="player-team">{item.team_abbr_lang}</div>
                                  </div>
                                  <div className="thumb-wrap">
                                    {
                                      is_single_player ?
                                        <img src={item.player_image || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                                        :
                                        <>
                                          <img src={item.jersey || Images.DEFAULT_JERSEY} alt="" className='thumb' />
                                          <img src={item.flag || Images.DEFAULT_FLAG} alt="" className='flag' />
                                        </>
                                    }
                                  </div>
                                </div>
                            }

                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <p className='help-txt bottom-captions'><Trans>Transfers will be active for</Trans>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {next_week} <Trans>if made before the deadline.</Trans> </p>

                <div className='points-wrap'>
                  <div className="sec-head"><Trans>Points Overview</Trans> </div>

                  <div className="transfer-point-row">
                    <div className="cell">
                      <Trans>Free Transfers Used</Trans>
                    </div>
                    {/* <div className="cell minw"></div> */}
                    <div className="cell minw">
                      {ConsumeFreeTransfers(team_info.available_transfer)}
                    </div>
                  </div>

                  <div className="transfer-point-row">
                    <div className="cell">
                      <Trans>Additional Transfers Used</Trans>
                    </div>
                    {/* <div className="cell minw">
                      {TransfersCast(team_info.available_transfer) > 0 ? `(${InPlayers.length - team_info.available_transfer} X ${team_info.per_transfer_deduct} ${t('Pts')})` : ''}
                    </div> */}
                    <div className="cell minw">
                      <b>{TransfersCast(team_info.available_transfer)} <Trans>Pts</Trans> </b>
                    </div>
                  </div>
                </div>
              </>
          }

        </ModalBody>
        <ModalFooter className='footer-cls-modal'>
          <Button className='confirm-changes' block onClick={() => this.onSubmit()}><Trans>Confirm</Trans></Button>
        </ModalFooter>
      </Modal>
    );
  }
}
const ConfirmTransferWraped = ReduxHOC(ConfirmTransfer);
export default withRouter(ConfirmTransferWraped)