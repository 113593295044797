import { Component } from 'react';

class AdItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <div className="banner-item">
        <a href={item.target_url} target='_blank' rel="noopener noreferrer">
          <img src={item.image_adsense} alt="" 
            style={{
              width: `${item.width}px`, height: `${item.height}px`
            }}
          />
        </a>
      </div>
    );
  }
}

export default AdItem;
