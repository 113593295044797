/* eslint-disable */
import { Component } from 'react';
import { ReduxHOC } from 'HOC';
import { LoginFormSM, SignupFormSM } from 'Components';
import { Utils, Images, Trans } from 'Helpers';

const { REACT_APP_LANDING_TITLE } = process.env
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginForm: true
    }
  }
  toggleForm = (bool) => {
    this.setState({
      isLoginForm: bool
    })
  } 
  
  render() {
    const { isLoginForm } = this.state;
    const FormProps = {
      toggleForm: this.toggleForm,
    }
    return (
        <div className="onboard-wrap">
          <img src={Images.BLACK_LOGO} alt="" className='onboard-brand-logo' />
          <>
            {
              (isLoginForm) ? 
              <LoginFormSM {...FormProps} />
              : 
              <SignupFormSM {...FormProps} />
            }
          </>
        </div>
    );
  }
}

export default ReduxHOC(LandingPage);