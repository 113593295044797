import { Images } from 'Helpers';
import { Component } from 'react';

class PitchLoader extends Component {
  render() {
    return (
        <div className="pitch-loader">
          <div className="circular circular--solid"><img src={Images.PITCH_LOADER} alt="" /></div>
        </div>
    )
  }
}

export default PitchLoader;
