import { Component } from 'react';
import { Modal, Button } from 'reactstrap'
import { Trans } from 'Helpers';

class AlertDialog extends Component {
  render() {
    const { isOpen, toggle, onCancel, onConfirm } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={() => toggle(true)} className='alert-dialog' size={'sm'}>
        <div className='title'><Trans>Alert!</Trans></div>
        <div><Trans>This will reset your team, are you sure you want to proceed?</Trans></div>
        <div className="alert-dialog-footer">
          <Button color="success" onClick={onCancel}><Trans>Cancel</Trans></Button>
          <Button color="secondary" onClick={onConfirm}><Trans>Continue</Trans></Button>
        </div>
      </Modal>
    )
  }
}
AlertDialog.defaultProps = {
  isOpen: false,
  toggle: () => {}
}
export default AlertDialog
