/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Container } from 'reactstrap';
import { Utils } from 'Helpers';
//Auth
import { Banner, FixtureComponent, MultiBanner } from 'Components';
//TempFixture
import { ReduxHOC } from 'HOC';
import { Trans } from 'react-i18next';

class Fixtures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_league: Utils.getMultiLeg(),

      WeekPosting: false,
      Weeks: [],
      SelectedWeek: '',

      posting: false,
      FixturesList: [],

      opening: false,
      openingItem: '',
      CurrentFixtuerSelected: '',
      MatchSummary: {},
      lastupdate: new Date().valueOf(),
    }
    this.slider = null
  }

  LeagueToggle = (item) => {
    this.setState({
      selected_league: item,
      lastupdate: new Date().valueOf()
    })
  }

  render() {
    const {
      selected_league,
      lastupdate

    } = this.state;


    return (
      <Container className='pt28-p'>
        {/* <div className="hero-title">
          <Trans>Fixtures</Trans>
        </div> */}
        <div className="heading-leagues" id='padding-fx-b'>
          <div className="heading-main"><Trans>Fixtures</Trans></div>
          {/* <Select
              {...SelectProps}
              placeholder={t("Select Captain")}
              value={league}
              options={LeagueType}
              onChange={(e) => this.handleChange(e, "league")}
            /> */}
        </div>
        <div className='fixt-bne multibanner-wrap'>
          <Banner type='55' paddingBottom />
          <MultiBanner type='52' />
        </div>
        {
          // Auth.getAuth() ?
          // <FixtureComponent
          //   selected_league={selected_league}
          //   lastupdate={lastupdate}
          // />
          // :
          // <TempFixture />
          <div className='ourt-white-block'>
              <FixtureComponent
                selected_league={selected_league}
                lastupdate={lastupdate}
              />
          </div>
          
        }
      </Container>
    )
  }
}

Fixtures.propTypes = {}
export default ReduxHOC(Fixtures)