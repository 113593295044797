import { withTranslation } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Actions } from "ReduxLib";
import { _ } from "Helpers";
import { Helmet } from 'react-helmet';
const {
  REACT_APP_DOC_TITLE,
  REACT_APP_DOC_DEC
} = process.env

// The HOC
const withMyHoc = (ReduxWrappedComponent) => props => {
  const { meta } = props;
  return (
    <>
      {
        !_.isUndefined(meta) &&
        <Helmet
          defaultTitle={REACT_APP_DOC_TITLE}
          titleTemplate={`%s • ${REACT_APP_DOC_TITLE}`}>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description || REACT_APP_DOC_DEC} />
        </Helmet>
      }
      <ReduxWrappedComponent {...props} />
    </>
  )
}

// redux props
function mapStateToProps(state) {
  return {
    root: state.root
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch)
  };
}

const ReduxHOC = (WrappedComponent) =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withMyHoc(withTranslation()(WrappedComponent)));

export default ReduxHOC;