/* eslint-disable */
import { Component } from 'react';
import { CreateJoinButton, AllLeague, Banner } from 'Components';
import { _, Utils, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';

class MyTeamInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      CurrentLineup: props.root.CurrentLineup,
      is_single_player: Utils.isSinglePlayer(),
      my_performance: props.my_performance,
      team_info: props.team_info
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.updateLineupInfo != nextProps.updateLineupInfo) {
      this.setState({
        my_performance: nextProps.my_performance,
        team_info: nextProps.team_info
      })
    }
  }

  render() {
    const { updateLineupInfo, SelectedWeekObj } = this.props
    const {
      my_performance,
      team_info,
      CurrentLineup
    } = this.state;

    return (
      <>
        <div className="lineup-info-wrap">
          <div className="titlle-header">
            <div className="label-text"><Trans>Your Team Name</Trans></div>
            <div className="value-text">{my_performance.team_name}</div>
          </div>

          <div className="score-grid">
            <div className="title"><Trans>Points</Trans> / <Trans>Rankings</Trans></div>
            <div className="score-grid-content">

              <div className="cell">
                <div className="value">
                  {SelectedWeekObj.total_score || '--'}
                </div>
                <div className="label"><Trans>Points</Trans></div>
              </div>
              <div className="cell">
                <div className="value">
                  {SelectedWeekObj.week_rank || '--'}
                </div>
                <div className="label"><Trans>Rank</Trans></div>
              </div>


              <div className="cell">
                <div className="value">
                  {SelectedWeekObj.high_score || '--'}
                </div>
                <div className="label"><Trans>Highest Points</Trans></div>
              </div>
              <div className="cell">
                <div className="value">
                  {team_info.total_transfer || '--'}
                </div>
                <div className="label"><Trans>Transfers</Trans></div>
              </div>
            </div>
          </div>
          <AllLeague {...{
            updateLineupInfo: updateLineupInfo
          }} />
          {
            CurrentLineup.week_info &&
            <>
              {
                CurrentLineup.week_info.last_week != CurrentLineup.week_info.active_week &&
                <div className="lineup-info-footer">
                  <CreateJoinButton />
                </div>
              }
            </>
          }

        </div>
        {/* <Banner type="7" paddingTop /> */}
      </>
    );
  }
}

export default ReduxHOC(MyTeamInfo);
