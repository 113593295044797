import { Component } from 'react';
import { SelectLeague } from 'Components';


class SwitchLeague extends Component {
  render() {
    return <SelectLeague />;
  }
}

export default SwitchLeague;
