/* eslint-disable eqeqeq */
import { Component } from 'react';
import { MessageBox } from 'Components';
import { _, Http, API, Utils } from 'Helpers';

class JoinLeaguePage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      posting: true,
      result: false
    }
  }
  onSubmit = (join_code) => {
    this.setState({ posting: true });
    const { 
      SelectedLeague
    } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let param = {
      "league_id": league_id,
      "join_code": join_code
    };
    Http.post(API.CHECK_ELIGIBILITY, param).then(res => {
      const { data } = res;
      if (!_.isNull(data.global_lineup_master_id)) {
        let join_game_param = {
          "sports_id": sports_id,
          "league_id": league_id,
          "contest_id": data.contest_info.contest_id,
          "lineup_master_id": data.global_lineup_master_id
        }
        Http.post(API.JOIN_GAME, join_game_param).then(responseJson => {
          Utils.notify(responseJson.message, "success", 5000);
          this.setState({posting: false});

          this.props.history.push('/leagues')
        }).catch(error => {
          console.error(error);
          this.setState({ 
            posting: false
          });
        });
      }
    }).catch(error => {
      console.error(error);
      this.setState({ 
        posting: false
      });
    });
  }
  componentDidMount() {
    const { params } = this.props.match
    this.onSubmit(params.join_code)
  }
  
  render() {
    const { posting } = this.state;

    const MessageBoxProps = {
      Title: 'Joining private league...',
      Subtitle: 'This can take several minutes. This page will automatically update to display the status of your league.'
    }

    const MessageBoxError = {
      Title: 'Invalid league code',
      Subtitle: 'Please use valid league code to join the league',
      LinkLabel: 'Back to Login',
      LinkPath: '',
    }
    return (
      posting ?
        <MessageBox {...MessageBoxProps} />
        :
        <MessageBox {...MessageBoxError} />
    )
  }
}

export default JoinLeaguePage;
