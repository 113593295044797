/* eslint-disable */
import { Component } from 'react';
import { ReduxHOC } from 'HOC';
import { _ } from 'Helpers'
import Slider from 'react-slick';


function AdItem({ item, className = '' }) {
  return (
    <div {...{ className: `banner-item ${className}` }}>
      {item.target_url ? <a href={item.target_url} target='_blank' rel="noopener noreferrer">
        <img src={item.image} alt="" />
      </a>
        :
        <img src={item.image} alt="" />
      }
    </div>
  )
}

class Banner extends Component {
  render() {
    const { root, type, paddingTop, paddingBottom } = this.props;
    if (type === '') return null
    const _bannerData = _.filter(root.BannerData, o => o.banner_type_id == type)

    var SliderSettingsOne = {
      infinite: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            // rows: 2,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          }
        },
      ]
    };

    return (
      !_.isEmpty(_bannerData) && <>
        <div {...{
          className: `wfix banner-wrapper${paddingTop ? ' p-t' : ''}${paddingBottom ? ' p-b' : ''}`
        }}>
          {/* {
          _bannerData.length == 1 && <AdItem item={_bannerData[0]} className={`box${_bannerData[0].height}x${_bannerData[0].width}`} />
        } */}

          {
            _bannerData.length == 1 ?
              <AdItem item={_bannerData[0]} className={`box${_bannerData[0].height}x${_bannerData[0].width}`} />
              :
              <Slider {...SliderSettingsOne}>
                {_.map(_bannerData, (item, idx) => {
                  {/* console.log(item,'bannerData') */ }
                  return (
                    <AdItem item={item} key={idx} className={`box${_bannerData[0].height}x${_bannerData[0].width}`} />
                  )
                })}
              </Slider>
          }
        </div>
      </>
    )
  }
}

Banner.defaultProps = {
  type: '',
  'paddingTop': false,
  'paddingBottom': false,
}
export default ReduxHOC(Banner);
/* 
  type value should be:
  default: ''
  7: position1 (1x1)
  8: position2 (1x2)
  9: position3 (1x3)
  10: position4 (1x2)
  11: position5 (1x3)
 */