const CONSTANT = {
  DEFAULT_LNG: "en",
  GK: "GK",
  DF: "DF",
  MF: "MF",
  FW: "FW",
  SUCCESS_CODE: 200,
  DASHBOARD_PATH: '/',
  DEFAULT_ROOT: '/',
  ONBOARD_ROOT: '/login',
  MAINTENANCE_PATH: '/maintenance',
  DEVICE_TYPE: 3,
  DEVICE_ID: "",
  ITEMS_PERPAGE: 20,
  ITEMS_PERPAGE_SM: 10,
  CURRENT_OFFSET: 0,
  SORT_ORDER: "ASC",
  SORT_ORDER_DESC: "DESC",
  sm: 576,
  md: 768,
  lg: 960,
  xl: 1440,

  LINEUP_STAGE_CREATE: "CREATE",
  LINEUP_STAGE_EDIT: "EDIT",
  LINEUP_STAGE_VIEW: "VIEW",
  DATE_FORMAT: "DD MMM HH:MM",
  TIMEZONE: "",
  WEEK_JUMPED: false,
  USER_AGE_LIMIT: 13,
  CLIENT_URL:"https://matchroom3.com/sso?client_id=56c7debc-a3a6-4a99-bf61-957a5851a8b3&callback_url=https://predevsl.vinfotechprojects.com"
}
const MSZ = {
  MSZ_LINEUP_FULL: "You have already selected {{num}} players",
  // MSZ_BUDGET_INSUFFICIENT: "You have exceeded your budget",
  MSZ_BUDGET_INSUFFICIENT: "Your available budget is not enough to complete the team",
  MSZ_POSITION_NOT_SELECTED: "position player can not be selected",
  MSZ_MAX_POSITION_SELECTED: "You can't add any more players in that position",
  MSZ_MAX_PLAYER_PER_TEAM: "You can't select more than {{num}} players from one team",

  MSZ_SOCIAL_EMAIL_NOT_FOUND: "This email ID is restricted. Please try again or contact us for more information.",
  MSZ_FIXTURE_NOT_FOUND: "There are no details available for this fixture",
  verify_email_msg: "Please verify your email address",
  LEAGUE_OOPS_MSZ: "Leagues standings will be updated after the league starts",
  COPY_URL_MSZ: "Link has been copied!",
  COPY_CODE_MSZ: "Code has been copied!"
}
const _constant = {...CONSTANT, ...MSZ}
export default _constant