import { Component } from 'react';
import { Modal } from 'reactstrap'

class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  render() {
    const { children } = this.props;
    const { isOpen } = this.state;
    return (
      <>
      <i className="icon-about global-info-icn" onClick={this.toggle}/>
      <Modal isOpen={isOpen} toggle={this.toggle} className='league-info-modal' size={'sm'}>
        <i className="close-btn icon-cancel" onClick={this.toggle}/>
        <i className="icon-about"/>
        {children}
      </Modal>
      </>
    )
  }
}
InfoModal.defaultProps = {
  children: 'There is no content available!'
}

export default InfoModal