import { Images, Trans } from 'Helpers';
import { Component } from 'react';
import Notification from 'react-notify-toast';

class Maintenance extends Component {
  render() {
    return (
      <div className="flex-full-height">
        <Notification options={{ zIndex: 99999 }} />
        <div className="vcenter">
          <img src={Images.MAINTENANCE_BG} alt="" />
          <h2><Trans>We are Under Maintenance.</Trans></h2>
          <p><Trans>Will be Back Soon!</Trans></p>
        </div>
      </div>
    );
  }
}

export default Maintenance;
