/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Utils, Http, API, Images, Trans, Constant } from 'Helpers';
import md5 from 'md5';
import { Form, FormGroup, Input, Button } from 'reactstrap';
import { ReduxHOC } from 'HOC';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,

      confirmPassword: "",
      password: "",
      old_password: ""
    }
  }


  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };
  validateForm() {
    this.setState({
      formValid: Utils.isFormValid(['password'], this.state) &&
        !this.comparePassword() && this.state.confirmPassword != ''
    });
  }

  comparePassword() {
    const { confirmPassword, password } = this.state;
    return confirmPassword != '' && confirmPassword != password
  }
  
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { password } = this.state;
    const { params } = this.props.match

    Http.post(API.RESET_PASSWORD, {
      "key": params.key,
      "password": md5(password),
    }).then(response => {
      Utils.notify(response.message);
      this.setState({ posting: false });
      this.props.history.push(Constant.DEFAULT_ROOT)
    }).catch(error => {
      this.setState({ posting: false });
    });

  }
  
  render() {
    const { t } = this.props;
    const {
      confirmPassword,
      password,
      formValid,
      posting
    } = this.state;
    return (
        <div className="flexrow">
          <div className="flex-box reset-pwd-container">
              {/* <i className="icon-copa-logo copa-logo"/> */}
              <span className="brand-logo">
                <img src={Images.LOGO_EN} alt="" />
              </span>
              <Form onSubmit={this.onSubmit} className="pwd-form-box">
                <div className="pwd-form-header">
                  <h3><Trans>Reset Password</Trans></h3>
                  <p><Trans>Enter a new password for this account</Trans></p>
                </div>
                <FormGroup>
                  <Input type="password" placeholder={t('New Password')} 
                  name='password'
                  required
                  maxLength={50}
                  value={password}
                  autocomplete="off"
                  onChange={this.handleChange}
                  invalid={Utils.isInvalid("password", password)}
                  valid={Utils.isValid("password", password)}/>
                </FormGroup>
                <FormGroup>
                  <Input type="password"  placeholder={t('Confirm Password')} 
                    name='confirmPassword'
                    required
                    maxLength={50}
                    value={confirmPassword}
                    autocomplete="off"
                    onChange={this.handleChange}
                    invalid={this.comparePassword()}
                    valid={!this.comparePassword() && confirmPassword != ''}/>
                </FormGroup>
                <div className="pwd-form-footer">
                  <Button size='lg' disabled={!formValid || posting} block color="primary"><Trans>Reset</Trans></Button>
                </div>
              </Form>
          </div>
        </div>
    )
  }
}

ResetPassword.propTypes = {}

export default ReduxHOC(ResetPassword);
