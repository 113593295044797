import { Component } from 'react';
import { ReduxHOC } from 'HOC';
import { Utils } from 'Helpers';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error, info) {
    this.setState({ 
      hasError: true
    }, () => {
      setTimeout(() => { 
        window.location.reload(true)
       }, 2000)
    });
    console.error(error.message)
    console.warn(info)
    const { actions } = this.props
    actions.gtmEventFire('error_boundary')
  }
  render() {
    if (this.state.hasError) {
      return (
        <Utils.Spinner />
      );
    }
    return this.props.children;
  }
}
export default ReduxHOC(ErrorBoundary);
