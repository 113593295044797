import { Component } from 'react';
import { API, Http, Utils } from 'Helpers';
import { ShareLeagueInfo } from 'Modals';
import { ReduxHOC } from 'HOC';

class ShareButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      isShareModalShow: false,
      leagueName: "",
      inviteData: {},
    }
  }

  shraeModalInit = (data) => {
    const { SelectedLeague } = this.state;
    const { league_id } = SelectedLeague;

    let param = {
      "league_id": league_id,
      "contest_id": data.contest_id
    }
    Http.post(API.GET_INVITE_CODE, param).then((res) => {
      this.setState({
        leagueName: data.contest_info.contest_name,
        inviteData: res.data
      }, () => {
        const { actions } = this.props;
        actions.gtmEventFire('button_click', {
          "button_name": "Invite Players"
        });
        this.toggleShareModal()
      })
    }).catch(err => {
      console.error(err);
    })
  }
  toggleShareModal = () => {
    this.setState({
      isShareModalShow: !this.state.isShareModalShow
    })
  }

  render() {
    const { item } = this.props
    const {
      isShareModalShow,
      inviteData,
      leagueName,
    } = this.state;
    const ShareLeagueInfoProps = {
      inviteData,
      leagueName,
      isOpen: isShareModalShow,
      toggle: this.toggleShareModal
    }
    return (
      <>
        <span className='icon-share' onClick={() => this.shraeModalInit(item)}></span>
        {
          isShareModalShow &&
          <ShareLeagueInfo {...ShareLeagueInfoProps} />
        }
      </>
    );
  }
}

export default ReduxHOC(ShareButton);
