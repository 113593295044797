/* eslint-disable */
import { DashLeague, LineupInfo, PlayerList } from 'Components';
import { NavLink, _ } from 'Helpers';
import { Component } from 'react';
import { Trans } from 'react-i18next';

class MyTeamRoster extends Component {
  constructor(props) {
    super(props);
    this.nodes = {}
    this._node = null;
    this.setInstance = this.setInstance.bind(this)
    this.state = {
      updateLineupInfo: new Date().valueOf(),
      updateLineupInfoLeague: new Date().valueOf(),
    }
  }

  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node
    }
  }

  setInstance(_node) {
    this._node = _node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        rosterFilterUpdate: this.rosterFilterUpdate
      })
    }
  }

  rosterFilterUpdate = (position) => {
    this.nodes.PlayerList.rosterFilterUpdate(position);
  }
  render() {
    const {
      user_join_count,
      IS_MYTEAM,
      IS_TRASFER,
      TRF,
      MYTEAM,
      lineup_master_id,
      PlayerListProps,
      LineupInfoProps,
      next_week,
      joined_week,
      last_week,
      active_week
    } = this.props

    const { updateLineupInfoLeague } = this.state
    const AllLeagueProps = {
      updateLineupInfo: updateLineupInfoLeague
    }
    return (
      <span ref={this.setInstance}>
        {/* My Team */}
        {
          (!_.isNull(user_join_count) && (IS_MYTEAM || MYTEAM)) &&
          <>
            {
              (
                (user_join_count == 1) &&
                (next_week > joined_week || active_week == last_week) && (IS_MYTEAM || MYTEAM) && joined_week
              ) &&
              <LineupInfo {...LineupInfoProps} />
            }
            {
              ((!lineup_master_id && (user_join_count == 0) || (next_week == joined_week)) && active_week != last_week) &&
              <PlayerList getInstance={this.setNodeRef.bind(this, 'PlayerList')} {...PlayerListProps} />
            }
            {(
              (user_join_count == 1) &&
              (next_week > joined_week || active_week == last_week) && (IS_MYTEAM || MYTEAM) && joined_week
            ) &&
              <>
                <div className='jl-txt'><Trans>Joined Leagues</Trans></div>
                <div className='joined-container dash-league-mtym'>
                  <div className='classic-joined'><Trans>Classic Leagues</Trans>
                    <span><Trans>full Season</Trans></span>
                  </div>
                  <div className='title-joined'>
                    <span><Trans>League</Trans></span>
                    <span><Trans>Rank</Trans></span>
                  </div>
                  <div>
                    <DashLeague {...AllLeagueProps} />
                  </div>
                  <NavLink to='/prizes/1' className='spcl-prizes d-block' nw={true}><Trans>See the Special Leagues Prizes</Trans><span className='blue-arrs'> {'>>'}</span> </NavLink>
                </div>
              </>}
          </>
        }

        {/* TRANSFER */}
        {
          ((!_.isNull(user_join_count) && (IS_TRASFER || TRF)) && lineup_master_id && joined_week) &&
          <>
            <PlayerList getInstance={this.setNodeRef.bind(this, 'PlayerList')} {...PlayerListProps} />
          </>
        }
      </span>
    )
  }
}

export default MyTeamRoster;
