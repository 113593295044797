import { Component } from 'react';
import { Modal } from 'reactstrap'

class YoutubeVideoModal extends Component {
  render() {
    const { isOpen, children, toggleModal } = this.props;
    return (
      <Modal isOpen={isOpen} centered toggle={toggleModal} className='youtube-video-modal' size={'lg'}>
        <div className="videoWrapper">
          {children}
        </div>
      </Modal>
    );
  }
}

export default YoutubeVideoModal;
