/* eslint-disable */
import { useState, useEffect } from 'react';
import { Trans } from 'Helpers';
import { ConfirmAlert } from 'Modals';

const CountDownTimer = ({ hoursMinSecs = {
    hours: 0,
    minutes: 0,
    seconds: 5
},
    history,
    isScroeClock = false,
    callback,
    is_full,
}) => {
    const { days = 0, hours = 0, minutes = 0, seconds = 60 } = hoursMinSecs;
    const [[dys, hrs, mins, secs], setTime] = useState([days, hours, minutes, seconds]);
    const [confirmModal, setConfirmModal] = useState(false);
    const tick = () => {
        if (dys === 0 && hrs === 0 && mins === 0 && secs === 0) {
            // reset()
            if (isScroeClock) {
                callback()
            } else {
                setConfirmModal(true)
            }
        }
        else if (mins === 0 && secs === 0) {
            setTime([dys, hrs - 1, 59, 59]);
        } else if (secs === 0) {
            setTime([dys, hrs, mins - 1, 59]);
        } else {
            setTime([dys, hrs, mins, secs - 1]);
        }
    };


    // const reset = () => setTime([parseInt(hours), parseInt(minutes), parseInt(seconds)]);


    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    return (
        <>

            {
                is_full ?
                    <text className="timer-text"
                        {
                        ...{
                            className: `timer-text ${(hrs < 1) ? 'warn-col' : ''} ${(hrs == 0 && mins < 1 ? 'danger-col' : '')}`
                        }
                        }
                    >
                        {`${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}
                    </text>
                    :
                    <>      <i className='icon-stopwatch' />
                        {/* <i className="icon-clock" /> */}
                        <div className="time-cell fsc">
                            <div className='val'>{dys}</div>
                            <div className='lbl'><Trans>Days</Trans></div>
                        </div>
                        <div className='colon-l'>:</div>
                        <div className="time-cell">
                            <div className='val'>{hrs.toString().padStart(2, '0')}</div>
                            <div className='lbl'><Trans>Hours</Trans></div>
                        </div>
                        <div className='colon-r'>:</div>
                        <div className="time-cell">
                            <div className='val'>{mins.toString().padStart(2, '0')}</div>
                            <div className='lbl'><Trans>Mins</Trans></div>
                        </div>
                        {/* <div className="time-cell">
                            <div className='val'>{secs.toString().padStart(2, '0')}</div>
                            <div className='lbl'><Trans>Secs</Trans></div>
                        </div> */}
                    </>
            }

            {
                (confirmModal) &&
                <ConfirmAlert history={history} />
            }
        </>
    );
}

export default CountDownTimer;