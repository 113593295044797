/* eslint-disable eqeqeq */
import { Component } from 'react';
import { MessageBox } from 'Components';
import { Http, API, Utils, Constant } from 'Helpers';

class ActivateAccount extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      posting: true,
      result: false
    }
  }
  parseErrorMessage = (response) => {
    var errorMsg = response.message != "" ? response.message : response.global_error;
    if (errorMsg == "") {
      for (var key in response.error) {
        errorMsg = response.error[key];
        if (errorMsg != "") {
          break;
        }
      }
    }
    return errorMsg
  }

  componentDidMount = () => {
    const { params } = this.props.match
    setTimeout(() => {
      Http.post(API.ACTIVATE_ACCOUNT, {
        "key": params.key
      }, true).then(response => {
        Utils.notify(response.message);
        this.props.history.push(Constant.DEFAULT_ROOT);

      }).catch(error => {
        if (error.data.email) {

          Http.post(API.RESEND_EMAIL_VERIFICATION_LINK, {
            "email": error.data.email
          }).then(response => {
            Utils.notify(response.message);
            this.props.history.push(Constant.DEFAULT_ROOT);
          }).catch(error => {
            this.setState({ posting: false });
          });
        } else {
          Utils.notify(this.parseErrorMessage(error), "error", 2500);
          this.setState({ posting: false });
        }
      });
    }, 1000);
  };
  componentWillUnmount() {
    this.setState = () =>{
      return;
    };
  }
  render() {
    const { posting } = this.state;

    const MessageBoxProps = {
      className: 'full-height',
      Title: 'Activating your account...',
      Subtitle: 'This can take several minutes. This page will automatically update to display the status of your activation.'
    }

    const MessageBoxError = {
      className: 'full-height',
      Title: 'Invalid Activation',
      Subtitle: 'Please check your email again for the activation link',
      LinkLabel: 'Back to Login',
      LinkPath: Constant.DEFAULT_ROOT,
    }
    return (
      posting ?
        <MessageBox {...MessageBoxProps} />
        :
        <MessageBox {...MessageBoxError} />
    )
  }
}

export default ActivateAccount
