/* eslint-disable eqeqeq */
import { Component, Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Utils, _, Http, API, Constant, Images, Trans } from 'Helpers';
import { Img, GameweekNativeSlider } from 'Components';
import { ReduxHOC } from 'HOC';

class FixtureComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_league: Utils.getMultiLeg(),

      WeekPosting: false,
      Weeks: [],
      SelectedWeek: '',

      posting: false,
      FixturesList: [],

      opening: false,
      openingItem: '',
      CurrentFixtuerSelected: '',
      MatchSummary: {},
      gameweeklastupdate: new Date().valueOf(),
      is_single_player: Utils.isSinglePlayer(),
    }
    this.slider = null
  }

  SelectWeekHandle = (item) => {
    // console.log(item,'selectedweek')
    this.setState({
      SelectedWeek: item.season_week,
      posting: true,
      gameweeklastupdate: new Date().valueOf()
    }, () => this.GetFixtureList())
  }

  GetAllWeek = () => {
    const {
      selected_league
    } = this.state;
    let param = {
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
    }

    this.setState({ WeekPosting: true })
    Http.post(API.GET_ALL_WEEK, param).then(response => {
      const { data } = response
      data.all_week = _.map(data.all_week, (item) => {
        return { ...item, week: item.season_week, id: item.season_week }
      })
      this.setState({
        Weeks: data.all_week,
        SelectedWeek: data.current_week,
        WeekPosting: false,
        gameweeklastupdate: new Date().valueOf()
      }, () => {
        Utils.setNext(data.next_week)
        const { SelectedWeek } = this.state;
        if (!_.isNull(this.slider)) {
          this.slider.slickGoTo(Number(SelectedWeek) - 1)
        }
        this.GetFixtureList()
      })
    }).catch(error => {
      console.error(error)
    });
  }

  GetFixtureList = () => {
    const { is_current, selectedweek } = this.props;

    const {
      selected_league,
      SelectedWeek
    } = this.state;

    let _SelectedWeek = is_current ? selectedweek : SelectedWeek;

    let param = {
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
      "league_week": _SelectedWeek
    }

    let s3_param = [
      API.S3_GET_FIXTURE_LIST,
      selected_league.league_id,
      _SelectedWeek
    ]
    this.setState({
      posting: true
    })
    // Http.post(API.GET_FIXTURE_LIST, param).then(response => {
    Http.s3(Utils.s3UrlConvert(s3_param), API.GET_FIXTURE_LIST, param).then(response => {
      let match_list_clone = _.cloneDeep(response.data.match_list)
      let match_list = {}
      _.forEach(match_list_clone, (item, key) => {
        if (_.isEmpty(match_list)) {
          match_list = item
        } else {
          match_list = _.concat(match_list, item)
        }
      })

      this.setState({
        FixturesList: []
      }, () => {
        this.setState({
          posting: false,
          FixturesList: match_list
        })
      })
    }).catch(error => {
      this.setState({
        posting: false,
      })
      console.error(error)
    });
  }

  toggleFixtureDetail = (item) => {
    const { t } = this.props;
    const {
      CurrentFixtuerSelected,
      selected_league
    } = this.state;
    if (CurrentFixtuerSelected != item.season_game_uid) {
      let param = {
        "league_id": selected_league.league_id,
        "sports_id": selected_league.sports_id,
        "season_game_uid": item.season_game_uid
      }
      this.setState({
        openingItem: item.season_game_uid
      })

      Http.post(API.GET_MATCH_SUMMARY, param).then(response => {
        this.setState({
          MatchSummary: response.data.match_summary,
          openingItem: ''
        }, () => {
          console.log(this.isMatchSummaryEmpty())
          if (this.isMatchSummaryEmpty()) {
            this.setState({
              CurrentFixtuerSelected: ''
            }, () => Utils.notify(t(Constant.MSZ_FIXTURE_NOT_FOUND)))
          } else {
            console.log(item);
            this.setState({
              CurrentFixtuerSelected: item.season_game_uid
            })
          }
        })
      }).catch(error => {
        this.setState({
          opening: false,
          openingItem: ''
        })
        console.error(error)
      });
    } else {
      this.setState({ CurrentFixtuerSelected: '' });
    }
  }

  componentDidMount() {
    const { is_current } = this.props;
    if (is_current) {
      this.GetFixtureList()
    } else {
      this.GetAllWeek()
    }
  }

  isMatchSummaryEmpty = () => {
    const { MatchSummary } = this.state;
    let _arr = []
    _.forEach(MatchSummary.stats, (item, idx) => {
      if (!_.isEmpty(item)) {
        _arr.push(idx)
      }
    })
    return _arr.length == 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lastupdate !== this.props.lastupdate) {
      this.setState({
        selected_league: nextProps.selected_league
      }, this.GetAllWeek());
    }
  }

  render() {
    const { is_current
      // is_leaderboard
      // i18n
    } = this.props;
    const {
      posting,

      // WeekPosting,
      Weeks,
      SelectedWeek,

      FixturesList,
      CurrentFixtuerSelected,
      opening,
      openingItem,
      gameweeklastupdate,
      is_single_player

    } = this.state;

    let current_date = '';

    const FixtureDetails = () => {
      const { MatchSummary } = this.state;
      return (
        <div className='game-details'>
          {_.map(MatchSummary.stats, (item, idx) => {
            return (
              (!_.isEmpty(item.home) || !_.isEmpty(item.away)) &&
              <div className="fixture-details" key={idx}>
                <div className="title">{MatchSummary.stats_lang[idx]}</div>
                <div className="content">
                  <div className="cell">
                    {
                      is_single_player ?
                        <div>
                          {item.home}
                        </div>
                        :
                        <>
                          {
                            item.home ?
                              _.map(item.home, (obj, index) => {
                                return (
                                  <div title={obj.full_name} key={index}>
                                    <span className="text-ellipsis before-circle-l">{obj.full_name}</span>{" "}
                                    {
                                      Number(obj.value) > 0 &&
                                      `(${obj.value})`
                                    }
                                  </div>
                                )
                              })
                              :
                              "--"
                          }
                        </>
                    }

                  </div>
                  <div className="cell">
                    {
                      is_single_player ?
                        <div>
                          {item.away}
                        </div>
                        :
                        <>
                          {
                            item.away ?
                              _.map(item.away, (obj, index) => {
                                return (
                                  <div title={obj.full_name} key={index}>
                                    <span className="text-ellipsis before-circle">{obj.full_name}</span>{" "}
                                    {
                                      Number(obj.value) > 0 &&
                                      `(${obj.value})`
                                    }
                                  </div>
                                )
                              })
                              :
                              "--"
                          }

                        </>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
    return (
      <div {...{ className: is_current ? `selected-week-fixture` : `` }}>
        {
          is_current ?
            <Row>
              <Col>
                <div className="selected-week-fixture-header">{Utils.getMultiLeg().week_label[SelectedWeek]}</div>
                {/* <div className="selected-week-fixture-header"><Trans>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])}</Trans>{" "}{is_current ? selectedweek : SelectedWeek}</div> */}
              </Col>
            </Row>
            :
            <Row>
              <Col>
                <div className='native-slider-wrap'>
                  <GameweekNativeSlider {...{
                    scoring_weeks: Weeks,
                    SelectedWeek,
                    gameweeklastupdate,
                    SelectWeekHandle: this.SelectWeekHandle,
                    is_fixture: true
                  }} />
                </div>

              </Col>
            </Row>
        }
        <div className='self-block'>
        <Row>
          <Col>
            <div className="weeek-block">{Utils.getMultiLeg().week_label[SelectedWeek]}</div>
          </Col>
        </Row>
        <Row className='pfix-row'>
          {
            !posting ?
              <Col className='fixtures-list-wrap'>
                <div></div>
                <div className="fixtures-list" >
                  {
                    _.map(FixturesList, (item, index) => {
                      let firstChar = Utils.getUtcToLocal(item.season_scheduled_date, "dddd, MMM D");
                      let ShowHeadShow = false;
                      if (firstChar != current_date) {
                        current_date = firstChar
                        ShowHeadShow = true;
                      }
                      return (
                        <Fragment key={index}>
                          {
                            ShowHeadShow && firstChar !== '' &&
                            <div className="fixture-header h-fix-fxhead">
                              <span>
                                {firstChar}
                              </span>
                            </div>
                          }

                          {
                            is_single_player ?
                              <>
                                <div className={`fixture-item${item.season_game_uid == CurrentFixtuerSelected && !this.isMatchSummaryEmpty() ? ' active' : ''}${item.season_game_uid == openingItem ? ' opening' : ''}`} onClick={(!opening && Utils.isDateTimePast(item.season_scheduled_date, item)) ? () => this.toggleFixtureDetail(item) : null}>

                                  <div className="cell">
                                    <div {...{ className: `fx-name ${Number(item.home_score) > Number(item.away_score) ? 'winner' : ''}` }} title={item.home_full_name}>{item.home_full_name}</div>
                                    <figure className='fx-flag'>
                                      <Img src={item.home_player_image} fallbacksrc={Images.COUNTRY_FLAG} alt="" />
                                    </figure>
                                  </div>

                                  <div className="cell mid">
                                    <div className="score-capsule">
                                      {
                                        Utils.isDateTimePast(item.season_scheduled_date, item) ?
                                          item.home_score + ' - ' + item.away_score
                                          :
                                          Utils.DateFormat(Utils.getUtcToLocal(item.season_scheduled_date), "HH:mm")
                                      }
                                    </div>
                                  </div>
                                  <div className="cell last">
                                    <figure className='fx-flag'>
                                      <Img src={item.away_player_image} fallbacksrc={Images.COUNTRY_FLAG} alt="" />
                                    </figure>
                                    <div {...{ className: `fx-name ${Number(item.home_score) < Number(item.away_score) ? 'winner' : ''}` }} title={item.away_full_name}>{item.away_full_name}</div>
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                <div className={`fixture-item${item.season_game_uid == CurrentFixtuerSelected && !this.isMatchSummaryEmpty() ? ' active' : ''}${item.season_game_uid == openingItem ? ' opening' : ''}`} onClick={(!opening && Utils.isDateTimePast(item.season_scheduled_date, item)) ? () => this.toggleFixtureDetail(item) : null}>
                                  <div className="cell">
                                    <div {...{ className: `fx-name ${(Number(item.home_team_goal) > Number(item.away_team_goal) || Number(item.home_team_score) > Number(item.away_team_score)) ? 'winner' : ''}` }} title={item.home_team_name}>
                                    <span className='web-l-name'>{item.home_team_name}</span>
                                    <span className='mob-l-name'>{item.home}</span>
                                      {
                                        (!item.home_team_goal && Utils.isDateTimePast(item.season_scheduled_date, item)) &&
                                        <div className="fx-score-wrap">
                                          <b>{item.home_team_score}/{item.home_wickets}</b> <small>({item.home_overs} <Trans>OVR</Trans>)</small>
                                        </div>
                                      }
                                    </div>
                                    <figure className='fx-flag'>
                                      <Img src={item.flag_home} fallbacksrc={Images.DEFAULT_FLAG} alt="" />
                                    </figure>
                                  </div>


                                  <div className="cell mid">
                                    <div className="score-capsule">
                                      {
                                        Utils.isDateTimePast(item.season_scheduled_date, item) ?
                                          (item.home_team_goal ? (item.home_team_goal + ' - ' + item.away_team_goal) : 'vs')
                                          // (item.home_team_goal || `${item.home_team_score}/${item.home_wickets} - Ov: ${item.home_overs}`) + ' - ' + (item.away_team_goal || `${item.away_team_score}/${item.away_wickets} - Ov: ${item.away_overs}`)
                                          :
                                          Utils.DateFormat(Utils.getUtcToLocal(item.season_scheduled_date), "HH:mm")
                                      }
                                    </div>
                                  </div>

                                  <div className="cell last">
                                    <figure className='fx-flag'>
                                      <Img src={item.flag_away} fallbacksrc={Images.DEFAULT_FLAG} alt="" />
                                    </figure>
                                    <div {...{ className: `fx-name ${(Number(item.home_team_goal) < Number(item.away_team_goal) || Number(item.home_team_score) < Number(item.away_team_score)) ? 'winner' : ''}` }} title={item.away_team_name}>
                                    <span className='web-l-name'>{item.away_team_name}</span>
                                    <span className='mob-l-name'>{item.away}</span>
                                      {
                                        (!item.away_team_goal && Utils.isDateTimePast(item.season_scheduled_date, item)) &&
                                        <div className="fx-score-wrap">
                                          <b>{item.away_team_score}/{item.away_wickets}</b> <small>({item.away_overs} <Trans>OVR</Trans>)</small>
                                        </div>
                                      }
                                    </div>
                                  </div>

                                </div>
                              </>
                          }
                          {
                            item.season_game_uid == CurrentFixtuerSelected &&
                            <FixtureDetails />
                          }
                        </Fragment>
                      )
                    })
                  }
                </div>
              </Col>
              :
              <Utils.Spinner />
          }

        </Row>
        </div>
      </div>
    )
  }
}

FixtureComponent.defaultProps = {
  selectedweek: '',
  is_current: false,
  no_ad: false,
  is_leaderboard: false,
  lastupdate: new Date().valueOf()
}

export default ReduxHOC(FixtureComponent)