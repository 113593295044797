/* eslint-disable */
import { Component } from 'react';
import { Utils, _ } from 'Helpers';
import { ReduxHOC } from 'HOC';

class NotificationComponents extends Component {

  _renderTagMessage = (rowData) => {
    const { t, i18n } = this.props;

    var msg = rowData.message;
    var content = rowData.content;
    // var content = JSON.parse(rowData.content);
    _.map(Object.keys(content), (key, idx) => {
      msg = msg.replaceAll("{{" + key + "}}", '<span>' + content[key] + '</span>');
      // msg = msg.replace("{{" + key + "}}", '<span>' + t(_.isUndefined(content[i18n.language+"_"+key]) ? content[key] : content[i18n.language+"_"+key]) + '</span>');
    });
    return msg;
  };

  render() {
    const { NotificationList } = this.props
    return (
      <div className="notification-list">
        {
          _.map(NotificationList, (item, idx) => {
            return (
              //unread
              <div className={`notification-item ${item.notification_status == 1 ? '' : ''}`} key={idx}>
                <i className="icn icon-league" />
                <div className="overflow-hidden">
                  <p dangerouslySetInnerHTML={{ __html: this._renderTagMessage(item) }} />
                  <small>{Utils.DateFormat(Utils.getUtcToLocal(item.added_date), 'ddd, MMM DD hh:mm a')}</small>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}

NotificationComponents.defaultProps = {
  NotificationList: []
}

export default ReduxHOC(NotificationComponents);

