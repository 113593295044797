/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input } from 'reactstrap'
import { Utils, Http, API, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';

const md5 = require('md5');
class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,

      confirmPassword: "",
      password: "",
      old_password: ""
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };
  
  validateForm() {
    this.setState({
      formValid: Utils.isFormValid(['password', 'old_password'], this.state) &&
        !this.comparePassword() && this.state.confirmPassword != ''
    });
  }

  comparePassword() {
    const { confirmPassword, password } = this.state;
    return confirmPassword != '' && confirmPassword != password
  }

  onSubmit = () => {
    // e.preventDefault();
    this.setState({ posting: true });
    const { password, old_password } = this.state;

    Http.post(API.CHANGE_PASSWORD, {
      "old_password": md5(old_password),
      "password": md5(password),

    }).then(response => {
      Utils.notify(response.message);
      this.setState({ posting: false }, this.props.toggle)
    }).catch(error => {
      this.setState({ posting: false });
    });

  }
  render() {
    const { isOpen, toggle, t } = this.props;
    const {
      confirmPassword,
      password,
      old_password,
      formValid,
      posting
    } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className='custom-modal' size={'sm'}>

          <ModalHeader><Trans>Change Password</Trans></ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input type="password" placeholder={t("Current Password")}
              name='old_password'
              required
              maxLength={50}
              value={old_password}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("old_password", old_password)}
              valid={Utils.isValid("old_password", old_password)}  />
            </FormGroup>
            <FormGroup>
              <Input type="password" placeholder={t("New Password" )}
              name='password'
              required
              maxLength={50}
              value={password}
              autoComplete="off"
              onChange={this.handleChange}
              invalid={Utils.isInvalid("password", password)}
              valid={Utils.isValid("password", password)}/>
            </FormGroup>
            <FormGroup>
              <Input type="password"  placeholder={t("Confirm Password" )}
                name='confirmPassword'
                required
                maxLength={50}
                value={confirmPassword}
                autoComplete="off"
                onChange={this.handleChange}
                invalid={this.comparePassword()}
                valid={!this.comparePassword() && confirmPassword != ''}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button size='lg' disabled={!formValid || posting} block color="primary" onClick={() => this.onSubmit()}><Trans>Change Password</Trans></Button>
          </ModalFooter>
      </Modal>
    )
  }
}
ChangePasswordModal.defaultProps = {
  modalShow: false,
  toggle: () => {}
}
export default ReduxHOC(ChangePasswordModal)