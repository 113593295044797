/* eslint-disable */
import { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Utils, withRouter, _ } from 'Helpers';
import { ReduxHOC } from 'HOC';
import { Images } from 'Helpers';
class MiniLeagueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  //Handlers 
  goToPrizes = (item) => {
    this.props.history.push('/prizes/' + item.contest_id)
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    const { } = this.state;
    const { isOpen, toggle, t, selectedMinileague, joinLeagues } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}
        {...{
          className: `custom-modal mini-league-modal`
        }} size={'md'}>
        <i className="close-btn icon-cancel" onClick={() => toggle()} />
        <ModalHeader>{selectedMinileague.contest_name}</ModalHeader>
        <ModalBody>
          <div className='mini-league-head'>
            {/* <h4>Join the<span>{selectedMinileague.contest_name}</span></h4> */}
            <p>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {selectedMinileague.start_week} <span>to</span> {selectedMinileague.end_week}</p>
          </div>
          {(!_.isEmpty(selectedMinileague.prize_distribution_detail))
            ? <div className='prize-list global-prizes h-fix-pp'>
              <div className='th-prizes-joinpopup'>
                <span>Rank</span>
                <span>Prizes</span>
              </div>

              {
                _.map(selectedMinileague.prize_distribution_detail, (item, idx) => {
                  return (

                    <div className='fdr-parent'>
                      <div key={idx} className='fdc w-fix-ribbons'>
                        {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.LEFT_LEAFS} alt='' />}
                        {/* <img src={item.image_url} alt="" className='img-hfix' /> */}
                        <span className={(item.min === '1' || item.min === '2' || item.min === '3') && 'bold-fonts'}>{item.min === item.max ? item.min : <span>{item.min + '-' + item.max}</span>}</span>
                        {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' />}
                      </div>
                      <div className='prize-name'><span className={(item.min === '1' || item.min === '2' || item.min === '3') && 'black-color-bold'}>{item.name}</span></div>
                    </div>
                  )
                })

              }

            </div> :
            <div className='prize-list'>
              No prizes available for this league!
            </div>
          }
          <div className='mini-league-action'>
            {/* <a className='btn-link' onClick={() => this.goToPrizes(selectedMinileague)}>View All Prizes</a> */}
            <button className='btn-secondary btn btn-rounded' onClick={() => joinLeagues()}>JOIN League</button>
          </div>

          <div className='mini-banner-image'>
            {/* <img src={selectedMinileague.web_banner_image_url} alt="" /> */}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default withRouter(ReduxHOC(MiniLeagueModal))