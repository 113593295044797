// wrapper for localstorage and auth object setting
import  Utils  from '../Utils/Utils';
export function getAuth() {
  const auth = localStorage.getItem('auth');
//!auth && 
if( Utils.getMasterData() !== null && Utils.getMasterData().allow_sso === "1"){
  //console.log(Utils.getCookie('_URC'),"_URC");
  if (!Utils.getCookie('_URC') || Utils.getCookie('_URC') === 'null' || Utils.getCookie('_URC') === '') {
    localStorage.removeItem('auth');
    localStorage.removeItem('profile');
    localStorage.removeItem(`current_lineup`);
    localStorage.removeItem(`lineup`);
    localStorage.removeItem(`auth`);
    return null;
  } else {
    return JSON.parse(auth);
  }
} else {
  if (!auth) {
    return null;
  }
  return JSON.parse(auth);
}

 
}

export function setAuth(auth) {
  localStorage.setItem('auth', JSON.stringify(auth));
}

export function removeAuth() {
  localStorage.removeItem('auth');
}


const Auth = {getAuth, setAuth, removeAuth};
export default Auth;