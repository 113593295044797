import { Component } from 'react';
import { UncontrolledPopover } from 'reactstrap';

class InfoPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    }
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  render() {
    const { children, id } = this.props;
    return (
     <>
        <i className="icon-about global-info-icn" id={'Popover-' + id}/>
        <UncontrolledPopover 
          placement="bottom"
          trigger="legacy"
          target={'Popover-' + id}
          className='league-info-modal'
          hideArrow>
          {children}
        </UncontrolledPopover>
     </>
    );
  }
}
InfoPopover.defaultProps = {
  children: 'There is no content available!',
  id: 'default'
}
export default InfoPopover;
