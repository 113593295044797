/* eslint-disable */
import { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Utils, withRouter, _, Auth, Trans, NavLink, Constant, Images } from 'Helpers';
import { ReduxHOC } from 'HOC';
class GlobalLeagueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }

  }

  //Handlers 
  goToPrizes = (HomeData) => {
    this.props.history.push('/prizes/' + HomeData.contest_id)
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    const { } = this.state;
    const { isOpen, toggle, HomeData } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}
        {...{
          className: `custom-modal mini-league-modal`
        }} size={'sm'} fullscreen={'md'}>
        <i className="close-btn icon-cancel" onClick={() => toggle()} />
        <ModalHeader>{HomeData.contest_name}- Prize</ModalHeader>
        <ModalBody className='modal-body-clr'>
          <div className='mini-league-head'>
            {/* <h4 className='league-name-fix'>Join the {HomeData.contest_name}</h4> */}
            <p className='league-disc'>{Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} {HomeData.next_week} <span>to</span> {HomeData.last_week}</p>
          </div>
          <div className='prize-list global-prizes'>
            <div className='th-prizes-joinpopup'>
              <span>Rank</span>
              <span>Prizes</span>
            </div>
            {
              _.map(HomeData.prize_distribution_detail, (item, idx) => {
                return (

                  <div className='fdr-parent'>
                    <div key={idx} className='fdc'>
                      {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.LEFT_LEAFS} alt='' />}
                      {/* <img src={item.image_url} alt="" className='img-hfix' /> */}
                      <span className={(item.min === '1' || item.min === '2' || item.min === '3') && 'bold-fonts'}>{item.min === item.max ? item.min : <span>{item.min + '-' + item.max}</span>}</span>
                      {(item.min === '1' || item.min === '2' || item.min === '3') && < img src={Images.RIGHT_LEAFS} alt='' />}
                    </div>
                    <div className='prize-name'><span className={(item.min === '1' || item.min === '2' || item.min === '3') && 'black-color-bold'}>{item.name}</span></div>
                  </div>
                )
              })
            }
          </div>
          <div className='mini-league-action'>
            {/* <a className='btn-link' onClick={() => this.goToPrizes(HomeData)}>View All Prizes</a> */}
            <NavLink className='btn-secondary btn btn-rounded btn-shadows' to={{ pathname: Auth.getAuth() ? '/my-team' : Constant.ONBOARD_ROOT, state: { c_id: '', s_id: '', l_id: '', c_data_block: '' } }}>
              <Trans>Create Your Team</Trans>
            </NavLink>

          </div>


        </ModalBody>
      </Modal>
    );
  }
}
export default withRouter(ReduxHOC(GlobalLeagueModal))