import { Component } from 'react';
import Notification from 'react-notify-toast';
import { Trans } from 'Helpers';
class ComingSoon extends Component {
  render() {
    return (
      <div className="flex-full-height coming-soon">
        <Notification options={{ zIndex: 99999 }} />
        <div className="coming-soon-head">
          <h2><Trans>COMING</Trans></h2>
          <h3><Trans>SOON</Trans></h3>
        </div>
      </div>
    );
  }
}

export default ComingSoon;
