import { Trans } from 'Helpers';
import { Alert } from 'reactstrap';

const SeasonEndAlert = () => {
  return (
    <Alert color="warn" className='text-center'>
      <Trans>Season Ended!</Trans>, <Trans>Check out after sometime for the next season.</Trans>
    </Alert>
  );
};

export default SeasonEndAlert;
