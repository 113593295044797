/* eslint-disable */
import { Images, Trans, Utils } from 'Helpers';
import { Img } from 'Components';

const TopPLItem = ({ 
  item, 
  PlayerCardInit, 
  is_percentage = false, 
  is_single_player = Utils.isSinglePlayer(),
  is_manage = false,
  showImage
}) => {
  return (
    <div className="player-card-item wfix-tpl" onClick={() => PlayerCardInit(item)}>
      <figure className="plater-thumb-wrap headshot">
        <Img src={showImage(item)} fallbacksrc={Images.DEFAULT_JERSEY} alt="" className='thumb w-fix-img' />
        {/* is_single_player ? item.player_image : item.jersey */}
        {
          !is_single_player &&
          <Img src={item.flag} fallbacksrc={Images.DEFAULT_FLAG} alt="" className='flag' />
        }
        
          {
            item.player_role == 1 ? <div className="player-pos-circle">C</div> : (item.player_role == 2 ? <div className="player-pos-circle">V</div> : '')
          }
        <span className='player-pos'>
        
          {
            is_single_player ? 'Rank' : ''
          }
          {" "}{item.position}
        </span>
      </figure>
      <div className="player-card-details">
        <div className="title">{item.full_name}</div>
        {
          is_manage ? 
          <>
              {
                is_percentage ?
                  <div className="score">
                    <div>{item.captain_percentage || '--'}{item.captain_percentage ? <span className="lbl">%</span> : ''}</div>
                  </div>
                  :
                  <div className="score">
                    <div>{'--'}</div>
                    <div className="lbl"><Trans>Points</Trans> </div>
                  </div>
              }
          </>
          :
          <>
            {
              is_percentage ?
              <div className="score">
                <div>{item.captain_percentage || '--'}{item.captain_percentage ? <span className="lbl">%</span> : ''}</div>
              </div>
              :
              <div className="score">
                <div className='pts-block'>{(item.top_score || item.score) || '--'}<Trans> pts</Trans></div>
                {/* <div className="lbl"><Trans>Points</Trans> </div> */}
              </div>
            }
          </>
        }
      </div>
    </div>
  );
};

export default TopPLItem;
