/* eslint-disable */
import { _ } from 'Helpers';

const {
    REACT_APP_BASE_URL_DEV,
    REACT_APP_BASE_URL_PROD,
    REACT_APP_FB_APP_ID_DEV,
    REACT_APP_GOOGLE_CLIENT_ID_DEV,
    REACT_APP_FB_APP_ID_PROD,
    REACT_APP_GOOGLE_CLIENT_ID_PROD,
    REACT_APP_BUCKET_STATIC_DATA_ALLOWED_DEV,
    REACT_APP_HTTP_PROTOCOL_DEV,
    REACT_APP_BUCKET_NAME_DEV,
    REACT_APP_BUCKET_DATA_PREFIX_DEV,
    REACT_APP_BUCKET_STATIC_DATA_ALLOWED,
    REACT_APP_HTTP_PROTOCOL,
    REACT_APP_BUCKET_NAME,
    REACT_APP_BUCKET_DATA_PREFIX,
    REACT_APP_BUCKET_REGION_DEV,
    REACT_APP_BUCKET_REGION
} = process.env

const HostnameCollection = [
    'localhost',
    REACT_APP_BASE_URL_DEV.replace(/(^\w+:|^)\/\//, ''),
    'fantasy-stg.indiansuperleague.com',
    'isl-loadtest.vinfotech.org'
]
const QAHostnameCollection = [
    'fantasy-stg.indiansuperleague.com'
]

const LoadHostnameCollection = [
    'isl-loadtest.vinfotech.org'
]

const regex = /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm
const hostname = window.location.hostname;

const dev = {
    apiGateway: {
        URL: REACT_APP_BASE_URL_DEV,
    },
    cognito: {
        FB_APP_ID: REACT_APP_FB_APP_ID_DEV,
        GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID_DEV,
    },
    BUCKET_STATIC_DATA_ALLOWED: REACT_APP_BUCKET_STATIC_DATA_ALLOWED_DEV,
    HTTP_PROTOCOL: REACT_APP_HTTP_PROTOCOL_DEV,
    BUCKET_NAME: REACT_APP_BUCKET_NAME_DEV,
    BUCKET_DATA_PREFIX: REACT_APP_BUCKET_DATA_PREFIX_DEV,
    BUCKET_REGION: REACT_APP_BUCKET_REGION_DEV
};

const qa = {
    apiGateway: {
        URL: 'https://fantasy-stg.indiansuperleague.com',
    },
    cognito: {
        FB_APP_ID: REACT_APP_FB_APP_ID_DEV,
        GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID_DEV,
    },
    BUCKET_STATIC_DATA_ALLOWED: REACT_APP_BUCKET_STATIC_DATA_ALLOWED_DEV,
    HTTP_PROTOCOL: REACT_APP_HTTP_PROTOCOL,
    BUCKET_NAME: REACT_APP_BUCKET_NAME_DEV,
    BUCKET_DATA_PREFIX: REACT_APP_BUCKET_DATA_PREFIX_DEV,
    BUCKET_REGION: REACT_APP_BUCKET_REGION_DEV
};

const load = {
    apiGateway: {
        URL: 'http://isl-loadtest.vinfotech.org',
    },
    cognito: {
        FB_APP_ID: REACT_APP_FB_APP_ID_DEV,
        GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID_DEV,
    },
    BUCKET_STATIC_DATA_ALLOWED: REACT_APP_BUCKET_STATIC_DATA_ALLOWED_DEV,
    HTTP_PROTOCOL: REACT_APP_HTTP_PROTOCOL_DEV,
    BUCKET_NAME: 'isl-loadtest',
    BUCKET_DATA_PREFIX: 'isllt_',
    BUCKET_REGION: 'us-east-1'
};

const prod = {
    apiGateway: {
        URL: REACT_APP_BASE_URL_PROD,
    },
    cognito: {
        FB_APP_ID: REACT_APP_FB_APP_ID_PROD,
        GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID_PROD,
    },
    BUCKET_STATIC_DATA_ALLOWED: REACT_APP_BUCKET_STATIC_DATA_ALLOWED,
    HTTP_PROTOCOL: REACT_APP_HTTP_PROTOCOL,
    BUCKET_NAME: REACT_APP_BUCKET_NAME,
    BUCKET_DATA_PREFIX: REACT_APP_BUCKET_DATA_PREFIX,
    BUCKET_REGION: REACT_APP_BUCKET_REGION
};
//|| hostname.match(regex)
const config = _.includes(HostnameCollection, hostname) 
    ? (
        _.includes(QAHostnameCollection, hostname) ?
            qa
            : _.includes(LoadHostnameCollection, hostname) ?
            load : dev)
    : prod;

    console.log(config,"config");
export default {
    ...config,
    debug_mode: _.includes(HostnameCollection, hostname) || hostname.match(regex)
}
