/* eslint-disable */
import { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Input } from 'reactstrap'
import { Utils, _, Http, API, Auth, Images, Constant, withRouter, Trans } from 'Helpers';
import Select from 'react-select';
import { OnboardModal } from 'Modals';
import { ReduxHOC } from 'HOC';
const {
  REACT_APP_CLIENT_URL,
  REACT_APP_BASE_URL_PROD
} = process.env
class TeamSubmiModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      formValid: false,
      team_name: "",
      // team_name: props.RosterMasterData.team_name,
      captain: null,
      vice_captain: null,
      LineupList: props.LineupList,
      selected_league: Utils.getMultiLeg(),
      OnboardModalShow: false,
      OnboardModalType: 1,
      is_single_player: Utils.isSinglePlayer(),
      fav_club: null,
      showErrorMessage: false
    }
  }

  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    this.setState({ [name]: value }, () => {
      this.validateForm()
    });
  };

  getValidationState() {
    const { sports_config } = this.props;
    const { captain, vice_captain, fav_club } = this.state;
    let isValidate = null;
    let isCap = null;
    let isViceCap = null;
    let isFavClub = !_.isEmpty(fav_club);
    let capVc = null;

    if (sports_config.is_single_player == 1) {
      if (sports_config.is_captain_available == 1) {
        if (captain) {
          isCap = !_.isNull(captain.player_uid)
        }
      } else {
        isCap = true
      }
      if (sports_config.is_vicecaptain_available == 1) {
        if (vice_captain) {
          isViceCap = !_.isNull(vice_captain.player_uid)
        }
      } else {
        isViceCap = true
      }
      isValidate = (isCap && isViceCap && isFavClub)
        ? 'success' : 'error';
    } else {
      if (captain && vice_captain && fav_club) {
        isValidate = ((captain.player_uid != vice_captain.player_uid)
          && !(vice_captain.position == 'GK' && captain.position == 'GK') && isFavClub)
          ? 'success' : 'error'
      }
    }

    return isValidate;
  }
  getErrorUpdate() {
    const { captain, vice_captain } = this.state;
    let c_vc = null
    if (captain && vice_captain) {
      c_vc = ((captain.player_uid != vice_captain.player_uid) ? 'success' : 'error');
    }
    return c_vc
  }

  validateForm() {
    this.setState({
      formValid:
        this.getValidationState() == 'success' &&
        Utils.isFormValid(['team_name'], this.state)
    });

  }
  onSubmit = (is_sm, is_guest = false) => {
    const {
      LineupList,
      updateLineupList,
      lineup_master_id,
      contest_id,
      sports_config,
      t,
      default_formation
    } = this.props;
    const {
      captain,
      vice_captain,
      team_name,
      selected_league,
      fav_club
    } = this.state;

    let LineupFieldPlayer = _.filter(LineupList, o => {
      return o.is_bench_player != 1
    })
    let LineupBenchPlayer = _.filter(LineupList, o => {
      return o.is_bench_player == 1 && o.position != 'GK'
    })
    let LineupGKBenchPlayer = _.filter(LineupList, o => {
      return o.is_bench_player == 1 && o.position == 'GK'
    })

    _.map(LineupFieldPlayer, (item) => {
      item.player_role = '0'

      if (sports_config.is_single_player == 1) {
        item.position = item.position_clone
      }

      if (captain && (item.player_uid == captain.player_uid)) {
        item.player_role = '1'
      }
      if (vice_captain && (item.player_uid == vice_captain.player_uid)) {
        item.player_role = '2'
      }
      return item;
    });

    _.map(LineupGKBenchPlayer, item => {
      item.bench_order = 1;
      return item;
    })

    let k = 2;
    _.map(LineupBenchPlayer, item => {
      item.bench_order = k;
      k = k + 1
      return item;
    })

    let _LineupList = [...LineupFieldPlayer, ...LineupGKBenchPlayer, ...LineupBenchPlayer]


    let finalLineup = _.map(_LineupList, ({
      player_id,
      player_uid,
      player_team_id,
      position,
      salary,
      team_league_id,
      player_role,
      is_bench_player,
      bench_order,
      ...rest
    }) => {
      return {
        player_id,
        player_uid,
        player_team_id,
        position,
        salary,
        team_league_id,
        player_role,
        is_bench_player,
        bench_order
      }
    })

    let param = {
      "league_id": selected_league.league_id,
      "sports_id": selected_league.sports_id,
      "team_name": team_name,
      "lineup_master_id": lineup_master_id,
      "contest_id": contest_id,
      "lineup": finalLineup,
      "selected_formation": default_formation,
      "team_id": fav_club.value
    }



    if (!Auth.getAuth() && is_guest == false) {
      Utils.setGuestTeam(param)
      const originalUrl = REACT_APP_BASE_URL_PROD;

      const encodedUrl = encodeURIComponent(originalUrl);
      const finalUrl = `${REACT_APP_CLIENT_URL}?cbkurl=${encodedUrl}`;
      let status = Utils.isNativeWeb()
      switch(status){
        case 0:
          window.open(finalUrl, "_self")
          break;
        case 1:
          window.mobileApp.nativeLogin()
          break;
        case 2:
          window.webkit.messageHandlers.nativeLogin.postMessage("IOS")
          break;
        default:
          break;
      }
      
      window.open(finalUrl, "_self")
    }

    if (Auth.getAuth() || is_guest) {
      if (is_guest) {
        param = Utils.getGuestTeam()
      }
      this.setState({
        posting: true,
        OnboardModalShow: false
      })
      Http.post(API.LINEUP_PROCCESS, param).then(response => {
        setTimeout(() => {

          let lineup = []
          if (sports_config.is_single_player == 1) {
            lineup = _.map(_LineupList, (item) => {
              item.position = item.rank;
            })
          } else {
            lineup = _LineupList;
          }
          Http.post(API.JOIN_GAME, {
            "league_id": selected_league.league_id,
            "sports_id": selected_league.sports_id,
            "contest_id": contest_id,
            "lineup_master_id": response.data.lineup_master_id
          }).then(res => {
            this.setState({
              posting: false
            })
            this.joinLeague(response.data.lineup_master_id)
            Utils.setLineupMasterId().then(res => {
             // updateLineupList(lineup)
              // this.props.actions.setCurrentLineup(Utils.getCurrentLineup())
              Utils.notify(t(response.message))
              this.props.history.push(Constant.DASHBOARD_PATH)
            }).catch(err => {
              console.log(err);
            })
          }).catch(err => {
            this.setState({ posting: false })
            console.log(err)
          })
        }, 700)
      }).catch(error => {
        this.setState({ posting: false })
        console.log(error)
      });
    }

  }


  joinLeague = (lm_id) => {
    const { c_id, s_id, l_id } = this.props
    
    // const { SelectedLeague, CurrentLineup, selectedMinileague } = this.state;
    // const { league_id, sports_id } = SelectedLeague;
    if ((c_id === '' || _.isUndefined(c_id))  || (l_id === '' || _.isUndefined(l_id)) || (s_id === '' || _.isUndefined(s_id))) {
      //alert('radheeeeeeeeeee')
    }
    else {

      let join_game_param = {
        "sports_id": s_id,
        "league_id": l_id,
        "contest_id": c_id,
        "lineup_master_id": lm_id,
      }
      Http.post(API.JOIN_GAME, join_game_param).then(responseJson => {
        Utils.notify(responseJson.message, "success", 5000);

        this.props.history.push('/leagues')
        this.setState({ posting: false });

      }).catch(error => {
        console.error(error);
        this.setState({
          posting: false,
          formValid: false,
          join_code: '',
        });
      });
    }
  }


  componentDidMount() {
    // console.log(this.props.ALL_TEAM, 'ALL_TEAMALL_TEAM')
    document.body.style.overflow = 'hidden';
    // console.log(this.props, 'faafs')
    const { LineupList } = this.state;

    _.forEach(LineupList, item => {
      if (item.player_role == 1) {
        this.setState({ captain: item }, () => {
          this.validateForm()
        })
      }
      if (item.player_role == 2) {
        this.setState({ vice_captain: item }, () => {
          this.validateForm()
        })
      }
    })
    try {
      const { formation_positions } = this.props;
      if (formation_positions) {
        let _arr = ['GK', ...formation_positions.split('-')]
        this.setState({
          LineupList: [
            ..._.filter(LineupList, o => o.position == _arr[0]),
            ..._.filter(LineupList, o => o.position == _arr[1]),
            ..._.filter(LineupList, o => o.position == _arr[2]),
            ..._.filter(LineupList, o => o.position == _arr[3])
          ]
        })
      }
    } catch (error) {
      console.log(error);
    }

    if (Utils.isDev()) {
      const random = require('random-name');
      this.setState({
        team_name: `${random.place()} ${random.middle()}`
      }, this.validateForm)
    }
    // console.log(this.props.LineupList,"LineupListLineupList");
  }
  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }

  OnboardModalToggle = (type) => {
    if (window.ReactNativeWebView) {
      let action = {
        action: "dologin",
        type: type,
        targetFunc: "dologin",
      };
      setTimeout(() => {
        try {
          window.ReactNativeWebView.postMessage(JSON.stringify(action));
        } catch (error) { }
      }, 500);
    } else {
      this.setState({
        OnboardModalType: this.state.OnboardModalShow ? 0 : type,
        OnboardModalShow: !this.state.OnboardModalShow
      })
    }
    this.props.toggle()
  }

  render() {
    const { isOpen, toggle, t, sports_config } = this.props;

    const {
      team_name,
      captain,
      vice_captain,
      formValid,
      posting,
      LineupList,
      OnboardModalShow,
      OnboardModalType,
      is_single_player,
      fav_club,
      showErrorMessage
    } = this.state;


    const OnboardModalProps = {
      ...this.props,
      isOpen: OnboardModalShow,
      type: OnboardModalType,
      toggle: this.OnboardModalToggle,
      TeamSubmit: this.onSubmit,
      isGuestUser: true
    }


    const SelectProps = {
      isSearchable: false,
      className: 'react-select plain',
      classNamePrefix: 'react-select',
    }

    return (
      <Modal isOpen={isOpen} toggle={() => toggle(false)} className='custom-modal' size={'sm'}>
        <Fragment>
          <ModalHeader>
            {`${t('Select')} Favourite Club, ${sports_config.is_captain_available == 1 ? t('Captain') : ''} ${sports_config.is_vicecaptain_available == 1 ? '& ' + t('Vice-Captain') : ''} `}

          </ModalHeader>
          <ModalBody>
            {/* <img src={Images.CNV_SELECT} alt="" className='cnv-select-img'/> */}
            <FormGroup>
              <Input type="text" placeholder={t("Team Name")}
                name='team_name'
                required
                maxLength={50}
                value={team_name}
                autoComplete="off"
                onChange={this.handleChange}
                invalid={Utils.isInvalid("team_name", team_name)}
                valid={Utils.isValid("team_name", team_name)} />
            </FormGroup>
            {
              <FormGroup
                validationState={this.getValidationState()}>
                <Select
                  {...SelectProps}
                  placeholder={t("Select favourite Club")}
                  value={fav_club}
                  options={this.props.ALL_TEAM}
                  onChange={e => this.handleChange(e, 'fav_club')}
                  getOptionLabel={({ team_name }) => (team_name)}
                  getOptionValue={({ value }) => value}
                />
              </FormGroup>
            }
            {
              sports_config.is_captain_available == 1 &&
              <FormGroup
                validationState={this.getValidationState()}>
                <Select
                  {...SelectProps}
                  placeholder={t("Select Captain")}
                  value={captain}
                  options={_.filter(LineupList, o => o.is_bench_player == 0)}
                  onChange={e => this.handleChange(e, 'captain')}
                  getOptionLabel={({ full_name, position, rank }) => (`${full_name} ${is_single_player ? `(Rank - ${rank})` : `(${position})`}`)}
                  getOptionValue={({ player_uid }) => player_uid}
                />
              </FormGroup>
            }
            {
              sports_config.is_vicecaptain_available == 1 &&
              <FormGroup
                validationState={this.getValidationState()}>
                <Select
                  {...SelectProps}
                  placeholder={t("Select Vice-Captain")}
                  value={vice_captain}
                  options={_.filter(LineupList, o => o.is_bench_player == 0)}
                  onChange={e => this.handleChange(e, 'vice_captain')}
                  getOptionLabel={({ full_name, position, rank }) => (`${full_name} ${is_single_player ? `(Rank - ${rank})` : `(${position})`}`)}
                  getOptionValue={({ player_uid }) => player_uid}
                />
              </FormGroup>
            }
            {this.getErrorUpdate() == 'error' &&
              <div className='red-txt'>
                <Trans>Please Select Different C / VC</Trans>
              </div>
            }
          </ModalBody>
          <ModalFooter>
            <Button className='mobile-hidden' disabled={!formValid || posting || team_name == ""} block onClick={() => this.onSubmit(false)}><Trans>Submit</Trans></Button>
            <Button className='mobile-visible' disabled={!formValid || posting || team_name == ""} block onClick={() => this.onSubmit(true)}><Trans>Submit</Trans></Button>
          </ModalFooter>
        </Fragment>


        {
          !Auth.getAuth() &&
          // <SSOLogin isOpen={!Auth.getAuth()} toggleForm={this.OnboardModalToggle}/>

          <OnboardModal {...OnboardModalProps} />
        }
      </Modal>
    )
  }
}

export default ReduxHOC(withRouter(TeamSubmiModal))