
import { Component } from 'react';
import { Images, NavLink } from 'Helpers';
import { Container } from 'reactstrap';
import Slider from "react-slick";
import { Banner, MultiBanner } from 'Components';
import { _, API, Http, Utils, Constant } from 'Helpers';
// import GuestPitch from 'Components/GuestPitch/GuestPitch';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { Trans } from 'react-i18next';
// import { FormationModal } from 'Modals';
import Hometeam from 'Pages/HomeTeam/Hometeam';

const dummyData = [
    {
        title: 'Exciting Boosters',
        subtitle: 'Four types of boosters are available for users to enhance performance and score higher points.'
    },
    {
        title: 'Jump in at any time',
        subtitle: 'Missed a week? No problem. With weekly prizing, you can hop it at any time and compete for great prizes.'
    },
    {
        title: 'Classic leagues',
        subtitle: 'Users can join various types of leagues to compete with friends and other game players. The types are.'
    },
    {
        title: 'Auto substitution',
        subtitle: 'Automatically replace your players in a gameweek, he will be replaced by the highest priority substitute bench player.'
    },
    {
        title: 'More stats, better lineups',
        subtitle: 'Best-in-class player statistics, leaderboards with ranking, and scores of the players will help users staying ahead.'
    },
    {
        title: 'Special and Private leagues',
        subtitle: 'Keep looking for special leagues with exciting prizes or create private leagues to play only with your friends.'
    },
]
const BG_IMG_DATA = [
    { image: Images.S1_HTP },
    { image: Images.S2_HTP },
    { image: Images.S3_HTP },
    { image: Images.S4_HTP },
]
const RS_IMG_DATA = [
    { image: Images.S1_HTP1 },
    { image: Images.S2_HTP2 },
    { image: Images.S3_HTP3 },
    { image: Images.S4_HTP4 },
]

const HTP_DISC_DATA = [
    {
        title: 'League Selection',
        disc1: 'Choose your favourite league to play.',
        disc2: '',
        disc3: '',
        disc4: ''
    },
    {
        title: 'Create Your Team',
        disc1: 'You need to select total 15 players.11 on the field and 4 on the bench to complete your team.',
        disc2: 'Just click on blank jersey to add your favourite player.',
        disc3: '',
        disc4: ''
    },
    {
        title: 'Make Transfers',
        disc1: 'Every week you get a chance to exchange 3 players for free and additional exchanges will cost you some points.',
        disc2: 'Simply remove an existing player and add another as a replacement.',
        disc3: '',
        disc4: ''
    },
    {
        title: 'Use Amazing Boosters',
        disc1: 'Wild Card - Change your whole team for free permanently.',
        disc2: 'Triple Captain - Get 3x points for your captain.',
        disc3: 'Bench Boost - Get bench player points also added to your total points.',
        disc4: 'Free Hit - Change your whole team for one week, free.'
    },
]
class PublicLobby extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grayscale: 1,
            SelectedLeague: Utils.getMultiLeg(),
            HomeData: {},
            next_week_start: '',
            next_week: '',
            MinVal: {},
            ALL_POSITION: [],
            team_name: '',
            booster_list: [],
            SORT_BY_LIST: [],
            SALARY_CAP: null,
            default_formation: '',
            default_formation_clone: '',
            active_week: '',
            last_week: '',
            lineup_master_id: null,
            LineupStage: '', // 'CREATE', 'EDIT,
            // AppMasterData: props.root.AppMasterData,
            LineupList: Utils.getLineup() || [],
            is_single_player: Utils.isSinglePlayer(),
            sports_config: {},
            FormationModalShow: false,
            user_join_count: null,
            nav1: null,
            nav2: null,
            surrentSlide: '1',
            androidLink: Utils.getMasterData().android_apk_link,
            iosLink: Utils.getMasterData().ios_app_link

        }
    }
    componentDidMount() {
        // this.getAllTeam()
        // const { league_id, sports_id, sports_config } = this.state.SelectedLeague;
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        const { sports_config } = this.state.SelectedLeague
        this.setState({
            sports_config
        })
        this.getHomeData();
        // this.getLineupMasterData()
        // this.getAllTeam()
        // this.MasterData()
    }

    FormationModalToggle = () => {
        this.setState({
            FormationModalShow: !this.state.FormationModalShow
        })
    }
    getAllTeam = (param) => {
        const { t } = this.props;
        let s3_param = [
            API.S3_GET_ALL_TEAM,
            param.league_id
        ]
        Http.s3(Utils.s3UrlConvert(s3_param), API.GET_ALL_TEAM, param).then((response) => {
            // Http.post(API.GET_ALL_TEAM, param).then(response => {
            const { data } = response;
            // console.log('object')
            let TempViews = [{
                value: '',
                team_name: '',
                team_abbr: "All",
                // max_player_per_position: null,
            }]
            _.map(data, (it) => {
                TempViews.push({ value: it.team_id, team_name: it.team_name, team_abbr: it.team_abbr })
                // console.log(TempViews)
            })
            const idx = TempViews.map(o => o.team_id)
            const filters = TempViews.filter(({ value }, index) => !idx.includes(value, index + 1))
            this.setState({
                updatePlayerList: new Date().valueOf(),
                team_update: filters,
                ALL_TEAM: [{
                    team_id: '',
                    team_abbr: t('All'),
                    team_name: t('All'),
                }, ...data],
            })

        }).catch(error => {
            console.error(error)
        });
    }
    getLineupMasterData = () => {
        const { league_id, sports_id } = this.state.SelectedLeague;
        let param = {
            league_id: league_id,
            sports_id: sports_id
        }
        const { t } = this.props;
        const { is_single_player } = this.state;
        Http.post(API.GET_LINEUP_MASTER_DATA, param).then(response => {
            const { data } = response
            this.setState({
                MinVal: data.lineup_formation ? this.GetMinPosValue(data.lineup_formation.formation, data.formation_positions) : [],
                RosterMasterData: data,
                ALL_POSITION_CLONE: data.all_position,
                ALL_POSITION: [{
                    position_display_name: t('All'),
                    max_player_per_position: null,
                    position: ""
                },
                ...(is_single_player ? data.all_position[data.next_week] : data.all_position)
                ],
                team_name: data.team_name,
                booster_list: data.booster_list,
                SORT_BY_LIST: [{
                    value: '',
                    label: t('All'),
                }, ...data.sort_by_list],
                SALARY_CAP: data.salary_cap,
                default_formation: _.cloneDeep(data.default_formation),
                default_formation_clone: _.cloneDeep(data.default_formation),
                next_week: data.next_week,
                active_week: data.active_week,
                last_week: data.last_week,
                lineup_master_id: data.lineup_master_id,
                LineupStage: (data.lineup_master_id && data.lineup_master_id !== 0 ? Constant.LINEUP_STAGE_EDIT : Constant.LINEUP_STAGE_CREATE),
                // AppMasterData: props.root.AppMasterData,
                LineupList: Utils.getLineup() || [],

            });


        }).catch(error => {
            this.props.history.push(Constant.DASHBOARD_PATH)
            console.error(error)
        });
    }
    getHomeData = () => {
        const { league_id, sports_id } = this.state.SelectedLeague;
        let param = {
            "sports_id": sports_id,
            "league_id": league_id,
            is_top_players_req: 0,
            is_match_list_req: 0
        }
        // this.setState({ isLoaderShow: true })
        Http.post(API.GET_HOME_DATA, param).then(res => {
            const { data } = res;
            // this.setState({ isLoaderShow: false })
            // this.GetTopScoringPlayers();
            this.setState({
                HomeData: data,
                next_week_start: data.next_week_start_time,
                // game_week_scores: data.game_week_scores,
                next_week: data.next_week
                // freeTransfers: data.my_transafer.available_transfer
            })

            // if (data.winner_prizes.length > 2) {
            //     this.setState({
            //         // firstPrizeDetail: data.winner_prizes[0],
            //         // secondPrizeDetail: data.winner_prizes[1],
            //         // thirdPrizeDetail: data.winner_prizes[2]
            //     })
            // }

        }).catch(error => {
            console.error(error)
        });

    }
    MasterData = () => {
        // console.log(Utils.getMasterData(), 'Utils.getMasterData()')
        // return

        // Utils.getMasterData()


        // if (_.isUndefined(Utils.getMasterData()._mdt) || _.isEmpty(Utils.getMasterData()._mdt)) {
        //     Http.post(API.GET_APP_MASTER_DATA).then(res => {
        //         const data = res.data
        //         Utils.setMasterData(data)
        //         // this.setState({
        //         //     androidLink: data.android_apk_link,
        //         //     iosLink: data.ios_app_link,
        //         // })
        //     }).catch(err => {
        //         console.log(err);
        //     })
        // }
        // else { }
    }
    onClickArrowLeft = () => {

        if (this.state.grayscale !== 1) {
            this.setState({ grayscale: this.state.grayscale - 1 });
        }

    }
    onClickArrowRight = () => {
        if (this.state.grayscale !== 4) {
            this.setState({ grayscale: this.state.grayscale + 1 });
        }
    }
    isMobileView = () => {
        return window.innerWidth <= 767;
    }
    render() {

        const sliderSett = {
            afterChange: (current) => this.setState({ surrentSlide: current + 1 }),
        }
        const result = HTP_DISC_DATA.length;
        const { next_week_start, androidLink, iosLink, surrentSlide } = this.state

        // console.log(SALARY_CAP, LineupList, 'Utils.AvailableSalary(SALARY_CAP, LineupList)')

        return (
            <>
                <div className='home-ground'>
                    <Container className='ground-bg-main'>
                        <div className='myteam-area-home'>
                            <Hometeam next_wk={next_week_start} />
                        </div>
                    </Container>
                </div>
                <div className='white-bg-home'>
                    <Container className='container-home-wrap'>
                        <div className='mr-23d multibanner-wrap mr-fix'>
                            <Banner type='53' paddingBottom />
                            <MultiBanner type='21' />
                        </div>
                        <div className='htp-wrap'>
                            <div className='htp-title'>
                                <span className='tpcl'><Trans>How To Play</Trans></span>
                            </div>
                            <div className='htp-slider'>
                                {!this.isMobileView() ? <Slider
                                    autoplay={true}
                                    autoplaySpeed={2000}
                                    asNavFor={this.state.nav2}
                                    ref={slider => (this.slider1 = slider)}
                                    arrows={false}
                                >
                                    {_.map(BG_IMG_DATA, (item, idx) => {
                                        return (
                                            <div className='' key={idx}>
                                                <img src={item.image} alt=''></img>
                                            </div>
                                        )
                                    })}
                                </Slider>
                                    :
                                    <Slider
                                        autoplay={true}
                                        autoplaySpeed={4000}
                                        asNavFor={this.state.nav2}
                                        ref={slider => (this.slider1 = slider)}
                                        arrows={false}
                                    >
                                        {_.map(RS_IMG_DATA, (item, idx) => {
                                            return (
                                                <div className='' key={idx}>
                                                    <img className='slide-img' src={item.image} alt=''></img>
                                                </div>
                                            )
                                        })}
                                    </Slider>}
                                <div className='disc-block'>
                                    <Slider
                                        asNavFor={this.state.nav1}
                                        ref={slider => (this.slider2 = slider)}
                                        // slidesToShow={3}
                                        // swipeToSlide={true}
                                        focusOnSelect={true}
                                        nextArrow=<img className={surrentSlide === '1' ? 'grayscale1' : ''} src={Images.RIGHT_ARR} alt='' />
                                        prevArrow=<img className={surrentSlide === '4' ? 'grayscale1' : ''} src={Images.LEFT_ARR} alt='' />
                                        {...sliderSett}
                                    >
                                        {_.map(HTP_DISC_DATA, (item, idx) => {
                                            return (
                                                <div key={idx}>
                                                    <div className='slider-title'><Trans>{item.title}</Trans></div>
                                                    <div>
                                                        <div className='slide-disc'><Trans>{item.disc1}</Trans></div>
                                                        <div className='slide-disc'><Trans>{item.disc2}</Trans></div>
                                                        <div className='slide-disc'><Trans>{item.disc3}</Trans></div>
                                                        <div className='slide-disc'><Trans>{item.disc4}</Trans></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                    <div className='navigation-section clr-wt'>
                                        <NavLink to='/rules-and-scoring' className='navlink-click'><Trans>See Rules & Scoring</Trans></NavLink>
                                    </div>
                                    <div className='slider-count'>{surrentSlide}/{result}</div>
                                </div>

                            </div>
                        </div>
                    </Container >
                    <section className='color-win'>
                        <Container>
                            <div className='htp-wrap win-pb'>
                                <div className='htp-title'>
                                    <span className='game-win'><Trans>win exciting prizes</Trans></span>
                                </div>
                                <div className='home-banner-wrapper'>
                                    <Banner type='26' />
                                </div>
                                <div className='prizes-btn'>
                                    <NavLink to='/prizes/1'><div className='view-all-btn'><Trans>View All Prizes</Trans></div></NavLink>
                                </div>
                            </div>
                        </Container>
                    </section>
                    <Container className='game-features-cont'>
                        <div className={(iosLink || androidLink) ? 'pbfix htp-wrap game-features' : 'htp-wrap game-features'}>
                            <div className='htp-title'>
                                <span className='game-win'><Trans>2023 Game features</Trans></span>
                            </div>
                            <div className="parent">
                                {_.map(dummyData, (item, idx) => {
                                    return (
                                        <div className='main-grid-block'>
                                            <div className='title-box'>
                                                <span>
                                                    <Trans>{item.title}</Trans>
                                                </span></div>
                                            <div className='subtitle-box'><Trans>{item.subtitle}</Trans></div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Container>
                    {
                        (iosLink || androidLink) &&
                        <section className='color-win'>
                            <Container>
                                <div className='fix-height-downloads'>
                                    <div>
                                        <img src={Images.MOBILE_HOME} className='left-img' alt='' />
                                        <img src={Images.LIMG} className='l-img2' alt='' />
                                    </div>
                                    <div className='text-container-downloadables'>
                                        <h1><Trans>Manage from anywhere</Trans></h1>
                                        <p><Trans>Download the Season Long Fantasy App to track your roster and stay up-to-date with goal, lineup and injury alerts wherever you are.</Trans></p>
                                        <div className='image-section'>
                                            {iosLink && <a target='blank' href={iosLink}><img src={Images.APP_STORE_BADGE} alt='' /></a>}
                                            {androidLink && <a target='blank' href={androidLink}><img src={Images.PLAY_STORE_BADGE} alt='' /></a>}
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </section>
                    }
                    <Container className='btm-banner-container'>
                        {!this.isMobileView() ?
                            <Banner type='1' />
                            :
                            <Banner type='33' />}
                    </Container>
                </div >
            </>
        );
    }
}

export default withRouter(PublicLobby);
