/* eslint-disable */
import { Component } from 'react';
import { ReduxHOC } from 'HOC';
import PrivateLobby from 'Pages/PrivateLobby';
import PublicLobby from 'Pages/PublicLobby';
import { Auth } from 'Helpers';

// const { REACT_APP_DOC_TITLE } = process.env;
class Lobby extends Component {
  render() {
    return (
      Auth.getAuth() ?
      <PrivateLobby {...this.props} />
      :
      <PublicLobby {...this.props} />
    );
  }
}


export default ReduxHOC(Lobby);