/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Modal } from 'reactstrap'
import { LoginFormSM, SignupFormSM } from 'Components';
import Utils from 'Helpers/Utils/Utils';
// import { Images } from 'Helpers';

class OnboardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoginForm: props.type === 0 ? true : false
    }
  }
  toggleForm = (bool) => {
    this.setState({
      isLoginForm: bool
    })
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.type !== nextProps.type) {
      this.toggleForm(nextProps.type === 0 ? true : false)
    }
  }
  componentDidMount() {
  }

  onTeamSubmit = () => {
    const { isGuestUser, TeamSubmit } = this.props
    const param = Utils.getGuestTeam()
    TeamSubmit(param.is_sm, isGuestUser)
  }

  closeModal = () => {
    const { isLoginForm } = this.state;
    const { toggle } = this.props;
    toggle(false, isLoginForm ? 0 : 1)
  }
  render() {
    const { isOpen, isGuestUser } = this.props;
    const { isLoginForm } = this.state;
    const FormProps = {
      toggleForm: this.toggleForm,
      onTeamSubmit: this.onTeamSubmit,
      closeModal: this.closeModal,
      isGuestUser
    }

    return (
      <Modal isOpen={isOpen} toggle={() => this.closeModal()} className='custom-modal onboard-modal' size={'sm'}>
        <i className="close-btn icon-cancel" onClick={() => this.closeModal()} />
        <>
          {
            (isLoginForm) ?
              <LoginFormSM {...FormProps} />
              :
              <SignupFormSM {...FormProps} />
          }
        </>
      </Modal>
    )
  }
}
OnboardModal.defaultProps = {
  type: 0,
  isGuestUser: false
}
export default OnboardModal
