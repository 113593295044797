/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Modal } from 'reactstrap'
import { Utils, Auth, Images } from 'Helpers';
import { ReduxHOC } from 'HOC';

class LanguageSwitchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLanguage: Utils.getLang('lang')
    }
  }
  handleChange = (lang) => {
    this.setState({
      SelectedLanguage: lang
    })
  }
  
  onSubmit = () => {
    const { SelectedLanguage } = this.state;
    const { i18n } = this.props;
    i18n.changeLanguage(SelectedLanguage);
    Utils.setLang(SelectedLanguage)
    if(Auth.getAuth()){
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
    this.props.toggle()
  }
  
  render() {
    const { isOpen, toggle } = this.props;
    const { SelectedLanguage } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='language-dialog' size={'sm'} backdrop='static'>
        <img src={Images.LANG_ICON} alt="" className="icn-lang"/>
        <h2 className='title'>Select Your Langauge</h2>
        <h2 className='title ar'>حدد اللغة الخاصة بك</h2>
        <div className={`lang-btn ${SelectedLanguage == 'en' ? 'active' : ''}`} onClick={() => this.handleChange('en')}>
          <h3 className='text'>English</h3>
          <h3 className='text ar'>الإنجليزية</h3>
        </div>
        <div className={`lang-btn ${SelectedLanguage == 'ar' ? 'active' : ''}`} onClick={() => this.handleChange('ar')}>
          <h3 className='text'>Arabic</h3>
          <h3 className='text ar'>عربي</h3>
        </div>
        <button disabled={SelectedLanguage == ''} className="confirm-btn" onClick={() => this.onSubmit() }/>
      </Modal>
    )
  }
}

LanguageSwitchModal.defaultProps = {
  isOpen: true,
  toggle: () => {}
}
export default ReduxHOC(LanguageSwitchModal)
