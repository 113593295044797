/* eslint-disable */
import { Component } from "react";
import { Col, Container, Row, Button } from "reactstrap";
import Select from "react-select";
import { _, Utils, Trans, Images } from "Helpers";
import { AllLeague, Banner, CreateJoinButton, MultiBanner } from "Components";
import { ReduxHOC } from "HOC";

class League extends Component {
  constructor(props) {
    super(props);
    this.nodes = {};
    const { t } = props;
    this.state = {
      is_single_player: Utils.isSinglePlayer(),
      league: null,
      LeagueType: [
        { value: "all_league", label: t("All Leagues") },
        { value: "global_league", label: t("Global Leagues") },
        { value: "other_league", label: t("Private Leagues") },
      ],
      hideLEeague: false
    };
  }
  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node;
    }
  }
  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    this.setState({ [name]: value });
    if (e.label === "Private Leagues") {
      this.setState({ hideLEeague: true })
    }
    else {
      this.setState({ hideLEeague: false })
    }
  };

  componentDidMount() {
    this.setState({
      league: this.state.LeagueType[0],
    });
  }

  GetAllLeague = () => {
    this.nodes.AllLeague.GetAllLeague();
  };

  render() {
    const { t } = this.props;
    const { league, LeagueType } = this.state;

    const SelectProps = {
      isSearchable: false,
      className: "react-select",
      classNamePrefix: "react-select",
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }} className="p-t-wrap">
        {/* <div className="hide-on-sm" style={{ paddingTop: '100px', paddingRight: '50px' }}>
          <Banner type="9" paddingTop paddingBottom />
        </div> */}
        <div>
          {/* <Container className="small-container">
            <Row className="gutters-5px margin-top-default mb-5p">
              <Col>
                <CreateJoinButton callback={this.GetAllLeague} />
              </Col>
              <Col>
                <Select
                  {...SelectProps}
                  placeholder={t("Select Captain")}
                  value={league}
                  options={LeagueType}
                  onChange={(e) => this.handleChange(e, "league")}
                />
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <AllLeague
                  extend
                  className="league-page bg-fixclr"
                  selected={league}
                  getInstance={this.setNodeRef.bind(this, "AllLeague")}
                />
              </Col>
            </Row>
          </Container> */}
        </div>
        <Container className="container-up">
          <div className="heading-leagues">
            <div className="heading-main"><Trans>Leagues</Trans></div>
            <Select
              {...SelectProps}
              placeholder={t("Select Captain")}
              value={league}
              options={LeagueType}
              onChange={(e) => this.handleChange(e, "league")}

            />
          </div>
          <div className="multi-banner-wrap multibanner-wrap">
            <Banner type='54' />
            <MultiBanner type='30' />
          </div>
          <Row className="row-leagues p-fix-leagues">
            <Col md={6} className="left-wrap">
              <div className="heading-block">
                <span className="title-id"><Trans>Joined Leagues</Trans></span>
                <span id="prix">
                  <img src={Images.TROPHY_IC} alt='trophy ic' id='trophy' /><Trans>In the winning zone</Trans></span>
              </div>
              <AllLeague
                extend
                className="league-page bg-fixclr"
                selected={league}
                hideLEeague={this.state.hideLEeague}
                getInstance={this.setNodeRef.bind(this, "AllLeague")}
              /></Col>
            <Col md={6} className="right-wrap">
              {/* <CreateJoinButton */}
              <CreateJoinButton is_banner {...this.props} league={true} />
              <div style={{ height: '15px' }}></div>
              <Banner type="10" />
            </Col>
          </Row>

        </Container>
        <div className="hide-on-sm">
          {/* <Banner type="10" paddingTop /> */}
        </div>
      </div>
    );
  }
}

export default ReduxHOC(League);
