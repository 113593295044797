
import CONSTANT from "../ActionTypes";
import { _ } from 'Helpers'

const initialState = {
  HeaderConfig: {},
  AppMasterData: {},
  ProfileData: {},
  CurrentLineup: {},
  BannerData: {},
  gtm_event: {},
};
const NonRemovable = ['AppMasterData', 'BannerData']

export default function application(state = initialState, action) {
  switch (action.type) {

    case CONSTANT.SET_APP_MASTER:
      return Object.assign({}, state, {
        AppMasterData: action.payload
      });
    case CONSTANT.SET_HEADER_CONFIG:
      return Object.assign({}, state, {
        HeaderConfig: action.payload
      });

    case CONSTANT.SET_PROFILE_DATA:
      return Object.assign({}, state, {
        ProfileData: action.payload
      });

    case CONSTANT.SET_CURRENT_LINEUP:
      return Object.assign({}, state, {
        CurrentLineup: action.payload
      });

    case CONSTANT.SET_AD_DATA:
      return Object.assign({}, state, {
        BannerData: action.payload
      });

    case CONSTANT.GTM_EVENT_FIRE:
      return Object.assign({}, state, {
        gtm_event: action.payload
      });

    case CONSTANT.LOGOUT:
      return Object.assign({}, state, _.omit(initialState, NonRemovable ));

    default:
      return state;
  }
}
