/* eslint-disable eqeqeq, jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */

import { Component } from 'react';
import { Row, Col } from 'reactstrap'
import { _, Trans } from 'Helpers';
class ProfileView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      UserProfile: {},
      posting: true
    }
    this.node = null;
    this.setInstance = this.setInstance.bind(this)
  }
  setInstance(node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        ProfileViewUpdate: this.ProfileViewUpdate
      })
    }
  }
  ProfileViewUpdate = (data) => {
    setTimeout(() => {
      this.setState({
        UserProfile: data
      })
    }, 200)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.UserProfile != nextProps.data) {
      this.setState({
        UserProfile: nextProps.data
      })
    }
  }

  render() {
    // const { ViewToggle } = this.props;
    const { UserProfile } = this.state;
    const { phone_no, email, dob, gender, address, city, country,
      state, zip_code } = UserProfile;
    const ProfileRow = ({ label, value }) => {
      return (
        <div {...{ className: `profile-row` }}>
          <div {...{ className: `label-text` }}><Trans>{label}</Trans></div>
          <div {...{ className: `value-text ${label == 'Gender' ? 'gender-label' : ''}` }}>{value || '--'}</div>
        </div>
      )
    }

    const full_address = `${address && address != '' ? address + ',' : ''} ${city && city != '' ? city + ',' : ''} ${!_.isUndefined(state) && !_.isUndefined(state.state_name) ? state.state_name + ',' : ''} ${!_.isUndefined(country) && !_.isUndefined(country.country_name) ? country.country_name + ',' : ''} ${zip_code && zip_code != '' ? zip_code : ''}`
    // const full_address = !_.isUndefined(state) && !_.isUndefined(state.state_name) ?
    // `${ address && address != ''  ? address+',' : ''} ${city && city != '' ? city+',' : ''} ${!_.isUndefined(state) && !_.isUndefined(state.state_name)? state.state_name+',' : ''} ${!_.isUndefined(country) && !_.isUndefined(country.country_name)? country.country_name+',' : ''} ${zip_code && zip_code != '' ? zip_code : ''}`
    // : null

    return (
      <div ref={this.setInstance} className='profile-view-mode'>
        {/*<div className="profile-heading">
          <div className="profile-form profile-form-title">
            <Trans>Basic Info</Trans>
             <a className="icon-edit bi-icon" onClick={() => ViewToggle()} />
          </div>
        </div> */}

        <div className="profile-form p-b">
          <Row>
            <Col sm={6}>
              <ProfileRow label={'Mobile'} value={phone_no} />
            </Col>
            <Col sm={6}>
              <ProfileRow label={'Email'} value={email} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <ProfileRow label={'Date of Birth'} value={dob} />
            </Col>
            <Col sm={6}>
              <ProfileRow label={'Gender'} value={gender} />
            </Col>
          </Row>
          <Row>
            <Col>
              <ProfileRow label={'Address'} value={full_address.trim() == '' ? null : full_address} />
            </Col>
          </Row>

          {/* <Row>
            <Col sm={6}>
              <ProfileRow label={'Street Address'} value={address}/>
            </Col>
            <Col sm={6}>
              <ProfileRow label={'Country'} value={!_.isUndefined(country) && (country.country_name || '--')}/>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <ProfileRow label={'State'} value={!_.isUndefined(state) && (state.state_name || '--')}/>
            </Col>
            <Col sm={6}>
              <ProfileRow label={'City'} value={city}/>
            </Col>
          </Row> */}

        </div>
      </div>
    )
  }
}

ProfileView.propTypes = {}

export default ProfileView
