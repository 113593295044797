/* eslint-disable */
import { Component } from 'react';
import { Trans } from 'Helpers';
import { Modal, ModalBody, Button } from 'reactstrap'

class ConfirmAlert extends Component {
  render() {
    return (
      <Modal backdrop='static' isOpen size='sm' centered>
        <ModalBody>
          <div className="join-league-wrap">
            <h4 className="title">
              <Trans>Time is up!</Trans>
            </h4>
            <p>
              <Trans>The deadline to update your team lineup has passed</Trans>
            </p>
            <div className="p-t-10">
              <Button type='submit' block onClick={() => {
                if (this.props.history.location.pathname != '/lobby') {
                  this.props.history.push('/lobby')
                }
                else {
                  window.location.reload(true);
                }
              }}><Trans>Ok</Trans></Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ConfirmAlert;
