/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Images, NavLink, withRouter, Trans, Utils } from 'Helpers';
import { ReduxHOC } from 'HOC';
import { LanguageSwitch } from 'Components';

const {
  REACT_APP_FB_URL,
  REACT_APP_INSTAGRAM_URL,
  REACT_APP_TW_URL,
  REACT_APP_CONTACT_EMAIL,
  REACT_APP_COPYRIGHT,
  REACT_APP_VINFOTECH_WATERMARK,
  REACT_APP_VINFOTECH_WATERMARK_URL
} = process.env


const CreditText = () => {
  return (
    <>
      <Trans>If you need a solution like this</Trans><br /><span style={{ display: 'none' }}>, </span>  <Trans>please email</Trans> <a href="mailto:info@vinfotech.com">info@vinfotech.com</a> <Trans>or WhatsApp</Trans> <a target='_blank' rel="noopener noreferrer" href="https://wa.me/919826034200">+91 982 603 4200</a>
    </>
  )
}
class DefaultFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ref: null,
      footerHide: false,
    }
    this.node = null;
    this.setInstance = this.setInstance.bind(this)
  }

  setInstance(node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        setProps: this.setProps
      })
    }
  }

  setProps = (ref) => {
    this.setState({
      ref: ref.metadata || true,
      footerHide: ref.footerHide === true ? ref.footerHide : false,
    })
  }

  render() {
    const { footerHide } = this.state
    if (footerHide) return null;
    return (
      <footer className="app-footer" ref={this.setInstance}>
        <div className="footer-top">
          <>
            <Row className='h-o-s'>
              <Col sm={10}>
                <div className="copyright">&copy; <Trans>{REACT_APP_COPYRIGHT}</Trans></div>
                <div className="footer-logo">
                  <img src={Images.LOGO_EN} alt="" />
                  {/* <img src={Images.ISL_LOGO} alt="" /> */}
                </div>
              </Col>
              <Col sm={1} className="footer-link-list">
                <ul className="footer-link-list">
                  {/* <li>
                    <NavLink to='/about-us'>
                      <Trans>About us</Trans>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to='/how-to-play'>
                      <Trans>How To Play</Trans>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/faq'>
                      <Trans>FAQ’s</Trans>
                    </NavLink>
                  </li>

                </ul>
              </Col>
              <Col sm={1} className="footer-link-list">
                <ul className="footer-link-list">
                  <li>
                    <NavLink to='/rules-and-scoring'>
                      <Trans>Rules & Scoring</Trans>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/privacy-policy'>
                      <Trans>Privacy Policy</Trans>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/terms'>
                      <Trans>Terms</Trans>
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to='/cookie-policy'>
                      <Trans>Cookie Policy</Trans>
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to='/support'>
                      <Trans>Support</Trans>
                    </NavLink>
                  </li>
                </ul>
              </Col>
              {/* <Col sm={2} className="footer-link-list last">
                
              </Col> */}
            </Row>
            <div className='footer-top-sm'>
              <ul className="footer-link-list fc">
                {/* <li>
                  <NavLink to='/about-us'>
                    <Trans>About us</Trans>
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to='/how-to-play'>
                    <Trans>How To Play</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/faq'>
                    <Trans>FAQ’s</Trans>
                  </NavLink>
                </li>

              </ul>
              <ul className="footer-link-list">
                <li>
                  <NavLink to='/rules-and-scoring'>
                    <Trans>Rules & Scoring</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/privacy-policy'>
                    <Trans>Privacy Policy</Trans>
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/terms'>
                    <Trans>Terms</Trans>
                  </NavLink>
                </li>
                {/* <li>
                    <NavLink to='/cookie-policy'>
                      <Trans>Cookie Policy</Trans>
                    </NavLink>
                  </li> */}
                <li>
                  <NavLink to='/support'>
                    <Trans>Support</Trans>
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
          <LanguageSwitch is_footer />
        </div>
        <div className={`footer-bottom ${window.location.href.includes('my-team')?'p-btm-fixed':''}`}>
          <>
            <Row className='h-o-s'>
              <Col className='footer-sm' >
                {
                  (REACT_APP_FB_URL || REACT_APP_INSTAGRAM_URL || REACT_APP_TW_URL) ?
                    <nav className="social-navigation">
                      <div className='social-nav-lbl'><Trans>Follow us on</Trans></div>
                      {
                        // REACT_APP_FB_URL &&
                        <a href={REACT_APP_FB_URL} target='_blank' rel="noopener noreferrer">
                          <i className="icon-fb"></i>
                        </a>
                      }
                      {
                        REACT_APP_TW_URL &&
                        <a href={REACT_APP_TW_URL} target='_blank' rel="noopener noreferrer">
                          <i className="icon-tw"></i>
                        </a>
                      }
                      {
                        REACT_APP_INSTAGRAM_URL &&
                        <a href={REACT_APP_INSTAGRAM_URL} target='_blank' rel="noopener noreferrer">
                          <i className="icon-insta"></i>
                        </a>
                      }
                    </nav>
                    :
                    <div className='footer-btm' style={{ display: 'flex' }}>
                      {/* <CreditText /> */}
                      Connect with us
                      <a href={REACT_APP_INSTAGRAM_URL} target='_blank' rel="noopener noreferrer">
                        <i className="icon-emai"></i>
                      </a>
                      <a href={REACT_APP_INSTAGRAM_URL} target='_blank' rel="noopener noreferrer">
                        <i className="icon-whatsapp"></i>
                      </a>
                      <a href={REACT_APP_FB_URL} target='_blank' rel="noopener noreferrer">
                        <i className='icon-fb' />
                      </a>
                      <a href={REACT_APP_TW_URL} target='_blank' rel="noopener noreferrer">
                        <i className="icon-tw" />
                      </a>
                      <a href={REACT_APP_INSTAGRAM_URL} target='_blank' rel="noopener noreferrer">
                        <i className="icon-insta"></i>
                      </a>
                    </div>
                }
              </Col>

              {/* <Col className='wtmart-remove-res'>
                {
                  REACT_APP_VINFOTECH_WATERMARK == 'true' &&
                  <div className="vinfotech-logo"> */}
                    {/* <div className="lbl"><Trans>Developed by</Trans> </div> */}
                    {/* <a href={REACT_APP_VINFOTECH_WATERMARK_URL + (Utils.getCpSessionPath() == '' ? `?utm_source=${window.location.hostname}` : Utils.getCpSessionPath())} target='_blank' rel="noopener noreferrer">
                      <i className="icon-vinfotech" />
                    </a>
                  </div>
                }
              </Col> */}
            </Row>
            {/* {
              (REACT_APP_FB_URL || REACT_APP_INSTAGRAM_URL || REACT_APP_TW_URL) &&
              <Row>
                <Col>
                  <div className="credit-text-wrap">
                    <CreditText />
                  </div>
                </Col>
              </Row>
            } */}
            <div className='footer-bottom-sm'>
              <div className="copyright">&copy; <Trans>{REACT_APP_COPYRIGHT}</Trans></div>

              <nav className="social-navigation">
                {
                  // REACT_APP_FB_URL &&
                  <a href={REACT_APP_FB_URL} target='_blank' rel="noopener noreferrer">
                    <i className="icon-fb"></i>
                  </a>
                }
                {
                  <a href={REACT_APP_TW_URL} target='_blank' rel="noopener noreferrer">
                    <i className="icon-tw"></i>
                  </a>
                }
                {
                  //REACT_APP_INSTAGRAM_URL &&
                  <a href={REACT_APP_INSTAGRAM_URL} target='_blank' rel="noopener noreferrer">
                    <i className="icon-insta"></i>
                  </a>
                }
              </nav>
            </div>
          </>
        </div>

      </footer>
    );
  }
}

export default withRouter(ReduxHOC(DefaultFooter), { withRef: true })