import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import './App.scss';
import reportWebVitals from './reportWebVitals';
import i18n from "i18n";
import App from 'App';

import { Provider } from 'react-redux';
import configureStore from './ReduxLib/Store';
import { ComingSoon, Maintenance } from 'Pages';
import { ModeHOC } from 'HOC';
import { Config } from 'Helpers';
import TagManager from 'react-gtm-module'
// import GoogleOneTapLogin from 'react-google-one-tap-login';



const {
  REACT_APP_GTM_ID
} = process.env

if (REACT_APP_GTM_ID && !Config.debug_mode) {
  console.log(Config.debug_mode);
  const tagManagerArgs = {
    gtmId: REACT_APP_GTM_ID
  }
  TagManager.initialize(tagManagerArgs)
}

ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
      {/* <GoogleOneTapLogin onError={(error) => console.log(error)} onSuccess={(response) => console.log(response)} googleAccountConfigs={{ client_id: Config.cognito.GOOGLE_CLIENT_ID}} /> */}
    <Provider store={configureStore()}>
      <ModeHOC>
        {({ mode }) => {
          switch (mode) {
            case '1':
              return <ComingSoon />
            case '2':
              return <Maintenance />
            default:
              return <App />
          }
        }}
      </ModeHOC>
    </Provider>
  </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();