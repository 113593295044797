import { Utils, NavLink } from 'Helpers';
import { ReduxHOC } from 'HOC';
import { Component } from 'react';
import CookieConsent from "react-cookie-consent";


class CookieConsentWrap extends Component {
  CookieConsentHandler = () => {
    window.location.reload(true)
    if(Utils.getProfile()) {
      Utils.setProfile(Utils.getProfile())
    }
  }
  render() {
    return (

      <CookieConsent 
        buttonClasses="cookie-consent-btn"
        onAccept={() => this.CookieConsentHandler()} 
        buttonText="I ACCEPT">
        <div className="text-semibold">This website uses cookies</div>
        <div>If you continue using this website, you will be providing your consent to our use of these. Find out more by viewing our <NavLink to='/cookie-policy'>Cookie Policy</NavLink>.</div>
      </CookieConsent>
    );
  }
}

export default ReduxHOC(CookieConsentWrap);
