/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { _, Http, API, Utils, Constant, Trans } from 'Helpers';
import Switch from "react-switch";
import { ReduxHOC } from 'HOC';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SettingList: [],
      posting: true
    }
  }

  getNotificationSettings = () => {
    const { SettingList } = this.state
    this.setState({
      posting: true
    })
    Http.post(API.GET_NOTIFICATION_SETTINGS).then(response => {
      this.setState({
        posting: false,
        SettingList: [...SettingList, ...response.data],
      });
    }).catch(error => {
      this.setState({ posting: false });
    });
  }

  componentDidMount() {
    this.getNotificationSettings()
  }
  
  handleChange = (item) => {
    const { SettingList } = this.state
    this.setState({posting: true})
    let param = {
      "setting_id": item.setting_id,
      "user_notification_setting_status": item.user_setting_status == 1 ? 0 : 1
    }
    Http.post(API.UPDATE_NOTIFICATION_SETTING, param).then(response => {
      const _SettingList = _.map(SettingList, obj => {
        if(obj.setting_id == item.setting_id) {
          obj.user_setting_status = item.user_setting_status == 1 ? 0 : 1;
        }
        return obj
      })
      this.setState({
        SettingList: _SettingList,
        posting: false
      })
    }).catch(error => {
      this.setState({ posting: false });
    });


    return

  }
  
  render() {
    const { SettingList, posting } = this.state;
    const switchProps = {
      uncheckedIcon:false,
      checkedIcon:false,
      height: 19,
      width: 48,
      handleDiameter:26
    }
    return (
      <div className='small-container m-fix'>
        <Row>
          <Col>
            <div className="hero-title color-3s">
              <Trans>Settings</Trans>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className="setting-header">
              Notification Alert
            </div>
          </Col>
          <Col>
          {
            _.map(SettingList, (item, idx) => {
              return (
                <div key={idx} className='setting-item'>
                  <div className="cell">
                    <div className="title">{item.setting_title}</div>
                    <div className="desc">{item.setting_description}</div>
                  </div>
                  <div className="cell">
                    <Switch {...switchProps} 
                      disabled={posting}
                      onChange={() => this.handleChange(item)}
                      className={`react-switch ${item.user_setting_status == 1 ? 'react-switch-checked' : ''} ${posting ? 'disabled' : ''}`}
                      checked={item.user_setting_status == 1 ? true : false} />
                  </div>
                </div>
              )
            })
          }
          </Col>
        </Row>
      </div>
    );
  }
}

export default ReduxHOC(Settings);