/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { _, Images, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';


class FormationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posting: false,
      default_formation: props.default_formation
    }
  }

  changeHandle = (default_formation) => {
    this.setState({ default_formation })
  }
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }
  componentWillUnmount() {
    document.body.style.overflow = 'unset';
    this.setState = () => {
      return;
    };
  }

  render() {
    const {
      isOpen,
      toggle,
      RosterMasterData,
      FormationChangeHandle
    } = this.props;
    // RosterMasterData.lineup_formation.formation
    const {
      posting,
      default_formation,
    } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className='formation-modal' size={'sm'}>
        <i className="close-btn icon-cancel" onClick={toggle} />
        <ModalHeader className='formation-modal-head'><Trans>Formation</Trans></ModalHeader>
        <ModalBody className='formation-list'>
          {
            !_.isEmpty(RosterMasterData) &&
            _.map(RosterMasterData.lineup_formation.formation, (item, key) => {
              return (
                <div {...{ key, className: `formation-item ${key == default_formation ? 'selected' : ''}` }}
                  onClick={() => this.changeHandle(key)}>
                  <img src={Images[key]} alt="" />
                  <div className="caption">{key}</div>
                </div>
              )
            })

          }

        </ModalBody>
        <ModalFooter className='btn-fix'>
          <Button size='lg' disabled={posting} block color="primary" className='def-formation-btn' onClick={() => FormationChangeHandle(default_formation)}><Trans>Select</Trans></Button>
        </ModalFooter>
      </Modal>
    )
  }
}
FormationModal.defaultProps = {
  isOpen: true,
  toggle: () => { }
}
export default ReduxHOC(FormationModal)
