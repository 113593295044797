/* eslint-disable */
import { Component } from 'react';
import { Modal, ModalBody, Button, FormGroup, Input, Form, ModalHeader } from 'reactstrap'
import { Utils, Http, API, Trans, withRouter } from 'Helpers';
import { ReduxHOC } from 'HOC';
class LeagueModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedLeague: Utils.getMultiLeg(),
      is_single_player: Utils.isSinglePlayer(),
      JoinLeagueShow: false,
      join_code: '',
      formValid: false,
      posting: false,
    }
  }
  toggleModal = (bool) => {
    this.setState({
      JoinLeagueShow: bool,
      join_code: '',
      formValid: false,
      posting: false,
    })
  }
  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { join_code } = this.state
    this.setState({
      formValid:
        join_code != '' &&
        Utils.isFormValid(['join_code'], this.state)
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { toggle } = this.props;
    const {
      join_code,
      SelectedLeague
    } = this.state;
    const { league_id, sports_id } = SelectedLeague;
    let param = {
      "league_id": league_id,
      "join_code": join_code
    };
    Http.post(API.CHECK_ELIGIBILITY, param).then(res => {
      console.log(res);
      const { data } = res;

      if (!_.isNull(data.global_lineup_master_id)) {
        let join_game_param = {
          "sports_id": sports_id,
          "league_id": league_id,
          "contest_id": data.contest_info.contest_id,
          "lineup_master_id": data.global_lineup_master_id
        }
        Http.post(API.JOIN_GAME, join_game_param).then(responseJson => {
          Utils.notify(responseJson.message, "success", 5000);
          this.setState({ posting: false });
          toggle('joined')
        }).catch(error => {
          console.error(error);
          this.setState({
            posting: false,
            formValid: false,
            join_code: '',
          });
        });
      }
    }).catch(error => {
      console.error(error);
      this.setState({
        posting: false,
        formValid: false,
        join_code: '',
      });
    });
  }

  gotoCreateLeague = () => {
    const { REACT_APP_IS_PRIVATE_CONTEST } = process.env;
    const { actions, history, toggle } = this.props;
    if (REACT_APP_IS_PRIVATE_CONTEST == 'false') {
      actions.gtmEventFire('button_click', {
        "button_name": "Create Private League"
      })
      history.push('/leagues/create')
    } else {
      toggle('is_private')
    }
  }


  render() {
    const {
      JoinLeagueShow,
      join_code,
      formValid,
      posting,
      is_single_player
    } = this.state;
    const { isOpen, toggle, t } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle}
        {...{
          className: `custom-modal join-n-create ${JoinLeagueShow ? 'next-popup-jn' : `league-modal-bg ${is_single_player ? 'dartbg' : ''}`}`
        }} size={'md'}>
        <i className="close-btn icon-cancel" onClick={() => toggle()} />
        <ModalBody>
          {
            JoinLeagueShow ?
              <div className="join-league-wrap">
                {/* <h4 className="title">
                  <Trans>join private league</Trans>
                </h4> */}
                <p className='join-caption-top'>
                  <Trans>Simply enter the private league code in the box below and click on 'JOIN'.</Trans>
                </p>
                <Form onSubmit={this.onSubmit}>
                  <FormGroup className='input-wrap'>
                    <Input type="join_code"
                      id='join_code'
                      name='join_code'
                      placeholder={t('Enter league code')}
                      required
                      value={join_code}
                      autoComplete="off"
                      onChange={this.handleChange}
                      invalid={Utils.isInvalid("join_code", join_code)}
                      valid={Utils.isValid("join_code", join_code)} />
                  </FormGroup>
                  <div className="p-t-10">
                    <Button type='submit' className='join-btn-league' block disabled={!formValid || posting}><Trans>Join</Trans></Button>
                  </div>
                </Form>
                {/* <div className="back-btn" onClick={() => this.toggleModal(false)}><Trans>Back</Trans></div> */}
              </div>
              :
              <div className="league-modal-wrap">
                <div className='join-league-wraps'>
                  <div className="lgm-tilte"><Trans>Join Private League</Trans></div>
                  <div className="lgm-subtitle"><Trans>Join a private or public league to compete against friends and other game players.</Trans></div>
                  <Button onClick={() => this.toggleModal(true)} className='wfix-btn'><Trans>Join A League</Trans></Button>
                </div>
                {/* <div className="sap"></div> */}
                <div className='create-league-wraps'>
                  <div className="lgm-tilte"><Trans>Create Private League</Trans></div>
                  <div className="lgm-subtitle"><Trans>Set up a special league where you can compete against your friends privately.</Trans></div>
                  <Button onClick={() => this.gotoCreateLeague()} className='wfix-btn'><Trans>Create A League</Trans></Button>
                </div>
              </div>
          }
        </ModalBody>
      </Modal>
    );
  }
}
export default withRouter(ReduxHOC(LeagueModal))