import { Utils } from 'Helpers';
import CONSTANT from '../ActionTypes';

const Actions = {
  setAdData: function setAppMaster(data) {
    return {
      type: CONSTANT.SET_AD_DATA,
      payload: data,
    }
  },
  setAppMaster: function setAppMaster(data) {
    return {
      type: CONSTANT.SET_APP_MASTER,
      payload: data,
    }
  },
  setHeaderConfig: function setHeaderConfig(data) {
    return {
      type: CONSTANT.SET_HEADER_CONFIG,
      payload: data,
    }
  },
  setProfileData: function setProfileData(data = {}) {
    return {
      type: CONSTANT.SET_PROFILE_DATA,
      payload: data,
    }
  },
  setCurrentLineup: function setCurrentLineup(data = {}) {
    return {
      type: CONSTANT.SET_CURRENT_LINEUP,
      payload: data,
    }
  },
  logout: function logout() {
    return {
      type: CONSTANT.LOGOUT
    }
  },
  gtmEventFire: function gtmEventFire(event, object = {}, is_direct = false){
    let eventPayload = {
      ...(!is_direct && { 'user_id': Utils.getProfile() && Utils.getProfile().user_unique_id }),
      ...(!is_direct && { 'user_name': Utils.getProfile() && Utils.getProfile().user_name }),
      ...object
    }
    let _obj = {
      'event': event,
      ...eventPayload
    }
    if (window.ReactNativeWebView) {
      // For Mobile App
      let data = {
          targetFunc: 'gtmEventFire',
          event: event,
          Payload: eventPayload
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
  } else {
    if (window.dataLayer) {
      window.dataLayer.push(_obj)
    }
  }
    
    return {
      type: CONSTANT.GTM_EVENT_FIRE,
      payload: _obj
    }
  }
}

export default Actions