import { Component, Fragment } from 'react';
import { Auth, _, Utils, withRouter, NavLink, Trans, Http, API, Constant } from 'Helpers';
import { ReduxHOC } from 'HOC';

const {
//  REACT_APP_SHOW_SSO_HEADER,
  REACT_APP_CLIENT_URL,
  REACT_APP_BASE_URL_PROD
} = process.env
class MoreComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      IsOthersShow: false,
    }
  }

  gotoClienturl(){
    const originalUrl = REACT_APP_BASE_URL_PROD;
    const encodedUrl = encodeURIComponent(originalUrl);
    const finalUrl = `${REACT_APP_CLIENT_URL}?cbkurl=${encodedUrl}`;
    //console.log(finalUrl,"finalUrl");
    let status = Utils.isNativeWeb()
    switch(status){
      case 0:
        window.open(finalUrl, "_self")
        break;
      case 1:
        window.mobileApp.nativeLogin()
        break;
      case 2:
        window.webkit.messageHandlers.nativeLogin.postMessage("IOS")
        break;
      default:
        break;
    }
  }

  OthersNavToggle = () => {
    this.setState({
      IsOthersShow: !this.state.IsOthersShow
    });
  }

  logout = () => {
    Http.post(API.LOGOUT, {
      session_key: Auth.getAuth()
    }).then(res => {
      this.props.onChangeDrawer()
      this.props.toggleDrawer()
      this.props.actions.logout()
      Utils.logoutClear()
      this.props.history.push(Constant.ONBOARD_ROOT);
    })
  }
  signUP = () => {
    this.props.history.push({ pathname: '/login' })
    this.props.onChangeDrawer()
    this.props.toggleDrawer()
  }
  Transfers = () => {
    this.props.history.push({ pathname: '/transfers' })
    this.props.onChangeDrawer()
    this.props.toggleDrawer()
  }
  Fixtures = () => {
    this.props.history.push({ pathname: '/fixtures' })
    this.props.onChangeDrawer()
    this.props.toggleDrawer()
  }
  Stats = () => {
    this.props.history.push({ pathname: '/statistics' })
    this.props.onChangeDrawer()
    this.props.toggleDrawer()
  }
  sendMessageToApp(action) {

    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    } catch (error) { }

  }
  handleChange = (lng) => {
    this.props.i18n.changeLanguage(lng);
    Utils.setLang(lng)

    if (Auth.getAuth()) {
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
    window.location.reload(false);
    let data = {
      action: "changeLanguage",
      type: '',
      language: lng,
      targetFunc: "changeLanguage",
    };
    this.sendMessageToApp(data)
  };
  render() {
    const { onChangeDrawer, toggleDrawer, root } = this.props
    const { IsOthersShow } = this.state
    const CurrentLineup = root.CurrentLineup;
    let showTransfer = null;
    if (JSON.stringify(CurrentLineup) !== "{}") {
      showTransfer =
        CurrentLineup.week_info.last_week !==
          CurrentLineup.week_info.active_week &&
        Utils.getJoined() !== 0 &&
        Utils.getJoined() !== Utils.getActive();
      //console.log(CurrentLineup,"innnnnnnnnnnnnnnnnnnnn");
    }
    //const is_sso = Utils.getMasterData().allow_sso;
    //console.log(is_sso,"is_sso");
    //console.log(REACT_APP_SHOW_SSO_HEADER,"REACT_APP_SHOW_SSO_HEADER");
    const MenuList = [
      {
        name: <Trans>Home</Trans>,
        path: Constant.DEFAULT_ROOT,
        exact: true,
        icon: 'icon-home',
        isPublic: !Auth.getAuth(),
      }, {
        name: <Trans>Home</Trans>,
        path: '/',
        exact: true,
        icon: 'icon-home',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>My team</Trans>,
        path: '/my-team',
        exact: true,
        icon: 'icon-my-team',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>Fixtures</Trans>,
        path: '/fixtures',
        exact: true,
        icon: 'icon-fixtures',
        isPublic: Auth.getAuth(),
      }, {
        name: <Trans>Prizes</Trans>,
        path: '/prizes/1',
        exact: true,
        icon: 'icon-fixtures',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>Statistics</Trans>,
        path: '/statistics',
        exact: true,
        icon: 'icon-statistics',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>My Profile</Trans>,
        path: '/profile',
        exact: true,
        icon: 'icon-bell',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>Notifications</Trans>,
        path: '/notification',
        exact: true,
        icon: 'icon-bell',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>How to Play</Trans>,
        path: '/how-to-play',
        exact: true,
        icon: 'icon-howplay',
        isPublic: true,
      }
      ,

      {
        name: <Trans>Other</Trans>,
        path: null,
        exact: true,
        icon: 'icon-others',
        isPublic: true,
        NavFun: this.OthersNavToggle,
        more: [
          // {
          //   name: <Trans>About us</Trans>,
          //   path: '/about-us',
          //   exact: true,
          //   icon: 'icon-about'
          // },
          {
            name: <Trans>FAQ</Trans>,
            path: '/faq',
            exact: true,
            icon: 'icon-faq'
          }
          , {
            name: <Trans>Cookie Policy</Trans>,
            path: '/cookie-policy',
            exact: true,
            icon: 'icon-privacy'
          }
          , {
            name: <Trans>Privacy Policy</Trans>,
            path: '/privacy-policy',
            exact: true,
            icon: 'icon-privacy'
          }
          , {
            name: <Trans>Terms</Trans>,
            path: '/terms',
            exact: true,
            icon: 'icon-terms'
          }
          , {
            name: <Trans>Rules & Scoring</Trans>,
            path: '/rules-and-scoring',
            exact: true,
            icon: 'icon-scoring'
          }
          , {
            name: <Trans>Support</Trans>,
            path: '/support',
            exact: true,
            icon: 'icon-support'
          }
        ]
      }
      , {
        name: <Trans>Logout</Trans>,
        path: null,
        exact: true,
        icon: 'icon-logout',
        NavFun: this.logout,
        isPublic: Auth.getAuth(),
      },
      {
        name: <Trans>Login/Sign Up</Trans>,
        path: null,
        exact: true,
        icon: 'icon-logout',
        NavFun: this.signUP,
        isPublic: !Auth.getAuth(),
      }
    ]

    const MenuListSSO = [
      {
        name: <Trans>Home</Trans>,
        path: Constant.DEFAULT_ROOT,
        exact: true,
        icon: 'icon-home',
        isPublic: !Auth.getAuth(),
      },
      {
        name: <Trans>Home</Trans>,
        path: '/',
        exact: true,
        icon: 'icon-home',
        isPublic: Auth.getAuth(),
      }
      , {
        name: <Trans>My team</Trans>,
        path: '/my-team',
        exact: true,
        icon: 'icon-my-team',
        isPublic: Auth.getAuth(),
      },
      {
        name: <Trans>Fixtures</Trans>,
        path: '/fixtures',
        exact: true,
        icon: 'icon-fixtures',
        isPublic: true,
      },
      {
        name: <Trans>Prizes</Trans>,
        path: '/prizes/1',
        exact: true,
        icon: 'icon-fixtures',
        isPublic: true,
      }
      , {
        name: <Trans>Statistics</Trans>,
        path: '/statistics',
        exact: true,
        icon: 'icon-statistics',
        isPublic: true,
      }
      , {
        name: <Trans>How to Play</Trans>,
        path: '/how-to-play',
        exact: true,
        icon: 'icon-howplay',
        isPublic: true,
      }
      , {
        name: <Trans>Other</Trans>,
        path: null,
        exact: true,
        icon: 'icon-others',
        isPublic: true,
        NavFun: this.OthersNavToggle,
        more: [
          // {
          //   name: <Trans>About us</Trans>,
          //   path: '/about-us',
          //   exact: true,
          //   icon: 'icon-about'
          // },
          {
            name: <Trans>FAQ</Trans>,
            path: '/faq',
            exact: true,
            icon: 'icon-faq'
          }
          , {
            name: <Trans>Cookie Policy</Trans>,
            path: '/cookie-policy',
            exact: true,
            icon: 'icon-privacy'
          }
          , {
            name: <Trans>Privacy Policy</Trans>,
            path: '/privacy-policy',
            exact: true,
            icon: 'icon-privacy'
          }
          , {
            name: <Trans>Terms</Trans>,
            path: '/terms',
            exact: true,
            icon: 'icon-terms'
          }
          , {
            name: <Trans>Rules & Scoring</Trans>,
            path: '/rules-and-scoring',
            exact: true,
            icon: 'icon-scoring'
          }
          , {
            name: <Trans>Support</Trans>,
            path: '/support',
            exact: true,
            icon: 'icon-support'
          }
        ]
      },
      {
        name: <Trans>Login/Sign Up</Trans>,
        path: null,
        exact: true,
        icon: 'icon-logout',
        NavFun:  this.gotoClienturl,
        isPublic: !Auth.getAuth(),
      }
    ]

    if (showTransfer) {
      let trans = {
        name: <Trans>Transfers</Trans>,
        path: null,
        exact: true,
        icon: "icon-Tranfers",
        NavFun: this.Transfers,
        isPublic: Auth.getAuth(),
      };
      let leag =  {
        name: <Trans>Leagues</Trans>,
        path: "/leagues",
        exact: true,
        icon: "icon-league",
        isPublic: Auth.getAuth(),
      }

      const insertIndex = MenuListSSO.findIndex((item) => item.name.props.children === "Home") + 3;
      const insertIndexl = MenuListSSO.findIndex((item) => item.name.props.children === "Leagues") + 4;
      //console.log(insertIndex,"insertIndexinsertIndex");
     // MenuListSSO.splice(insertIndex, 0, trans);
      MenuListSSO.splice(insertIndexl, 0, leag);
      MenuList.splice(insertIndex, 0, trans);
      MenuList.splice(insertIndexl, 0, leag);
      //console.log(MenuListSSO, "MenuListSSO");
    }

    return (
      <Fragment>
        {/* <div className={`language-switch-more`}>
          <div className="label"><Trans>Change Language</Trans></div>
          <ul className="language-switch-tabs">
            <li className={`tab ${i18n.language === 'en' ? 'active' : ''}`} onClick={() => this.handleChange('en')}><Trans>English</Trans></li>
            <li className={`tab ar ${i18n.language === 'ar' ? 'active' : ''}`} onClick={() => this.handleChange('ar')}><Trans>Arabic</Trans></li>
          </ul>
        </div> */}

        <ul className="more-navigation">
          {
            _.map(Utils.getMasterData().allow_sso !== '1' ? MenuList : MenuListSSO, (item, idx) => {
              const NavRow = ({ item }) => {
                return (
                  <Fragment key={idx}>
                    <i className={item.icon} />
                    <span className="label">
                      {item.name}
                    </span>
                  </Fragment>
                )
              }
              //console.log(item.name,"OOOOOOOOOOOOOOO");
              return (
                item.isPublic &&
                <li>
                  {
                    item.path ?
                      <NavLink to={item.path} exact={item.exact} key={idx} onClick={() => [onChangeDrawer(), toggleDrawer()]}>
                        <NavRow item={item} />
                      </NavLink>
                      :
                      <Fragment>
                        <span onClick={() => item.NavFun()}>
                          <NavRow item={item} />
                          {
                            item.more &&
                            <i className={`${IsOthersShow ? 'icon-up' : 'icon-down'}`} />
                          }
                        </span>
                        {
                          item.more &&
                          IsOthersShow &&
                          <ul className="more-navigation inner">
                            {
                              _.map(item.more, (obj, i) => {
                                return (
                                  <li>
                                    <NavLink to={obj.path} exact={obj.exact} key={i} onClick={() => [onChangeDrawer(), toggleDrawer()]}>
                                      <NavRow item={obj} />
                                    </NavLink>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        }
                      </Fragment>
                  }
                </li>
              )
            })
          }
        </ul>
      </Fragment>
    )
  }
}
MoreComponent.defaultProps = {
  onChangeDrawer: () => { },
  toggleDrawer: () => { },
}
const MoreComponentWraped = ReduxHOC(MoreComponent);
export default withRouter(MoreComponentWraped, { withRef: true })