/* eslint-disable */
import { Constant } from "Helpers";
import Loadable from "react-loadable";
const {
  REACT_APP_LANDING_PAGE
} = process.env

function Loading(props) {
  if (props.error) {
    return <div>Error! <button onClick={props.retry}>Retry</button></div>;
  } else if (props.timedOut) {
    return <div>Taking a long time... <button onClick={props.retry}>Retry</button></div>;
  } else if (props.pastDelay) {
    return '<div>Loading...</div>';
  } else {
    return null;
  }
}


const SwitchLeague = Loadable({
  loader: () => import("Pages/SwitchLeague"),
  loading: Loading
});
const LandingPage = Loadable({
  loader: () => import("Pages/LandingPage/_LandingPage"),
  loading: Loading
});
const OnboardScreens = Loadable({
  loader: () => import("Pages/LandingPage"),
  loading: Loading
});
const ActivateAccount = Loadable({
  loader: () => import("Pages/ActivateAccount"),
  loading: Loading
});
const Lobby = Loadable({
  loader: () => import("Pages/Lobby"),
  loading: Loading
});
const MyTeam = Loadable({
  loader: () => import("Pages/MyTeam"),
  loading: Loading
});
// const MyTeams = Loadable({
//   loader: () => import("Pages/MyTm"),
//   loading: Loading
// });
const MyProfile = Loadable({
  loader: () => import("Pages/MyProfile"),
  loading: Loading
});
const ResetPassword = Loadable({
  loader: () => import("Pages/ResetPassword"),
  loading: Loading
});
const Fixtures = Loadable({
  loader: () => import("Pages/Fixtures"),
  loading: Loading
});
const Notification = Loadable({
  loader: () => import("Pages/Notification"),
  loading: Loading
});
const Settings = Loadable({
  loader: () => import("Pages/Settings"),
  loading: Loading
});
const Statistics = Loadable({
  loader: () => import("Pages/Statistics"),
  loading: Loading
});
const AllStatistics = Loadable({
  loader: () => import("Pages/AllStatistics"),
  loading: Loading
});
const League = Loadable({
  loader: () => import("Pages/League"),
  loading: Loading
});
const LeagueDetails = Loadable({
  loader: () => import("Pages/LeagueDetails"),
  loading: Loading
});
const CreateLeague = Loadable({
  loader: () => import("Pages/CreateLeague"),
  loading: Loading
});
const StaticPage = Loadable({
  loader: () => import("Pages/StaticPage"),
  loading: Loading
});
const JoinLeaguePage = Loadable({
  loader: () => import("Pages/JoinLeaguePage"),
  loading: Loading
});
const Maintenance = Loadable({
  loader: () => import("Pages/Maintenance"),
  loading: Loading
});
const Prizes = Loadable({
  loader: () => import("Pages/Prizes"),
  loading: Loading
});

const GlobalPrizes = Loadable({
  loader: () => import("Pages/GlobalPrizes"),
  loading: Loading
});

const AboutUs = Loadable({
  loader: () => import("Pages/AboutUs"),
  loading: Loading
});

const Routes = [

  // {
  //   path: "/select-league",
  //   name: "SwitchLeague",
  //   component: SwitchLeague,
  //   isCommon: true,
  //   // isPublic: true,
  //   exact: true,
  //   headerHide: true,
  //   footerHide: true
  // },
  {
    path: Constant.ONBOARD_ROOT,
    name: "LandingPage",
    component: REACT_APP_LANDING_PAGE == 'true' ? LandingPage : OnboardScreens,
    isPublic: true,
    exact: true,
    screen: {
      theme: REACT_APP_LANDING_PAGE == 'true' ? '' : 'landing-backdrop'
    },
    meta: {
      title: "",
      description: "",
    }
  },
  {
    path: "/activation/:key",
    name: "ActivateAccount",
    component: ActivateAccount,
    isCommon: true,
    exact: true,
    headerHide: true,
    footerHide: true,
  },


  {
    path: "/",
    name: "Lobby",
    component: Lobby,
    exact: true,
    isCommon: true,
    // isAuthenticate: true,
    meta: {
      title: "Lobby",
      description: "",
    }
  },
  {
    path: "/invite/:join_code",
    name: "JoinLeaguePage",
    component: JoinLeaguePage,
    exact: true,
    isAuthenticate: true,
  },
  {
    path: "/my-team",
    name: "myteam",
    component: MyTeam,
    exact: true,
    isAuthenticate: true,
    footerHideSm: true,
    meta: {
      title: "My Team",
      description: "",
    }
  },
  {
    path: "/transfers",
    name: "transfers",
    component: MyTeam,
    exact: true,
    isAuthenticate: true,
    footerHideSm: true,
    meta: {
      title: "Transfers",
      description: "",
    }
  },
  // {
  //   path: "/my-teams/:id",
  //   name: "myteams",
  //   component: MyTeams,
  //   exact: true,
  //   isAuthenticate: true,
  //   footerHideSm: true,
  //   meta: {
  //     title: "My Team",
  //     description: "",
  //   }
  // },
  // {
  //   path: "/transfer/:swap",
  //   name: "transfer",
  //   component: MyTeams,
  //   exact: true,
  //   isAuthenticate: true,
  //   footerHideSm: true,
  //   meta: {
  //     title: "Transfers",
  //     description: "",
  //   }
  // },
  {
    path: "/profile",
    name: "MyProfile",
    component: MyProfile,
    exact: true,
    isAuthenticate: true,
    meta: {
      title: "My Profile",
      description: "",
    }
  },
  {
    path: "/fixtures",
    name: "Fixtures",
    component: Fixtures,
    exact: true,
    isCommon: true,
    meta: {
      title: "Fixtures",
      description: "",
    }
  },
  {
    path: "/notification",
    name: "Notification",
    component: Notification,
    exact: true,
    isAuthenticate: true,
    meta: {
      title: "Notification",
      description: "",
    }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    exact: true,
    isAuthenticate: true,
    meta: {
      title: "Notification Settings",
      description: "",
    }
  },
  {
    path: "/reset-password/:key",
    name: "ResetPassword",
    component: ResetPassword,
    exact: true,
    headerHide: true,
    footerHide: true,
    isPublic: true,
    meta: {
      title: "Reset Password",
      description: "",
    }
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    exact: true,
    isCommon: true,
    meta: {
      title: "Statistics",
      description: "",
    }
  },
  {
    path: "/statistics/all",
    name: "AllStatistics",
    component: AllStatistics,
    exact: true,
    isCommon: true,
    meta: {
      title: "All Player Statistics",
      description: "",
    }
  },
  {
    path: "/leagues",
    name: "League",
    component: League,
    exact: true,
    isAuthenticate: true,
    meta: {
      title: "League",
      description: "",
    }
  },
  {
    path: "/leagues/create",
    name: "CreateLeague",
    component: CreateLeague,
    exact: true,
    isAuthenticate: true,
    meta: {
      title: "Create League",
      description: "",
    }
  },
  {
    path: "/leagues/details",
    name: "League",
    component: LeagueDetails,
    exact: true,
    isAuthenticate: true,
    meta: {
      title: "League Details",
      description: "",
    }
  },
  //chat-room#&channel=${AppoinmentGUID}&mode=1`,
  // {
  //   path: "/chat-room",
  //   name: "ChatRoom",
  //   component: ChatRoom,
  //   exact: true,
  //   isAuthenticate: true
  // },
  {
    path: "/rules-and-scoring",
    name: "Rules and Scoring",
    component: StaticPage,
    page_id: '8',
    exact: true,
    isCommon: true,
    meta: {
      title: "Rules and Scoring",
      description: "",
    }
  },
  {
    path: "/how-to-play",
    name: "How To Play",
    component: StaticPage,
    page_id: '2',
    exact: true,
    isCommon: true,
    meta: {
      title: "How To Play",
      description: "",
    }
  },
  {
    path: "/faq",
    name: "Faq's",
    component: StaticPage,
    page_id: '5',
    exact: true,
    isCommon: true,
    meta: {
      title: "Faq's",
      description: "",
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: StaticPage,
    page_id: '3',
    exact: true,
    isCommon: true,
    meta: {
      title: "Terms",
      description: "",
    }
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: StaticPage,
    page_id: '4',
    exact: true,
    isCommon: true,
    meta: {
      title: "Privacy Policy",
      description: "",
    }
  },
  {
    path: "/support",
    name: "Support",
    component: StaticPage,
    page_id: '6',
    exact: true,
    isCommon: true,
    meta: {
      title: "Support",
      description: "",
    }
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs,
    exact: true,
    isCommon: true,
    meta: {
      title: "About Us",
      description: "",
    }
  },
  // {
  //   path: "/about-us",
  //   name: "About Us",
  //   component: StaticPage,
  //   page_id: '1',
  //   exact: true,
  //   isCommon: true,
  //   meta: {
  //     title: "About Us",
  //     description: "",
  //   }
  // },
  {
    path: Constant.MAINTENANCE_PATH,
    name: "Maintenance",
    component: Maintenance,
    isCommon: true,
    exact: true,
    headerHide: true,
    footerHide: true,
  },
  {
    path: "/cookie-policy",
    name: "Cookie Policy",
    component: StaticPage,
    page_id: '12',
    exact: true,
    isCommon: true,
    meta: {
      title: "Cookie Policy",
      description: "",
    }
  },
  {
    path: "/prizes/:contest_id/",
    name: "Prizes",
    component: Prizes,
    exact: true,
    isCommon: true,
    // isAuthenticate: true,
    meta: {
      title: "Prizes",
      description: "",
    }
  },
  {
    path: "/global-prizes/:contest_id/",
    name: "GlobalPrizes",
    component: GlobalPrizes,
    exact: true,
    // isPublic: true,
    // isAuthenticate: true,
    isCommon: true,
    meta: {
      title: "Global Prizes",
      description: "",
    }
  },

]
export default Routes;
/* 
  1= About - ✔
  2= how to play - ✔
  3= terms_of_use - ✔
  4= privacy_policy - ✔
  5= faq - ✔
  6= support - ✔
  8= score &rule - ✔
  7= affiliations
  9= carrer
  10= press_media
  11= prizes
  12= Cookie Policy
 */