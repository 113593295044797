/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col, FormGroup, Input, Button, FormFeedback, Form, Alert } from 'reactstrap';
import { _, Http, API, Utils, Trans } from 'Helpers';
import Select from 'react-select';
import { ShareLeagueInfo } from 'Modals';
import { ReduxHOC } from 'HOC';
import { Banner, MultiBanner } from 'Components';

class CreateLeague extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_league: Utils.getMultiLeg(),
      CurrentLineup: props.root.CurrentLineup,
      start_week: null,
      contest_type: null,
      game_name: '',
      minimum_size: '',
      size: '',
      gameWeekList: [],
      formValid: false,
      posting: false,


      isShareModalShow: false,
      leagueName: "",
      inviteData: {},
      contest_types_list: [],
      // WeekOptions: {}
    }
  }

  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    this.setState({ [name]: value }, () => {
      this.validateForm()
      if (name == 'contest_type' && value && value.value == '2') {
        Utils.notify("You’ll find this section in our app. Download our app for added fun", 'error');
        this.setState({
          contest_type: this.state.contest_types_list[0]
        });
      }
    });
  };


  componentDidMount() {
    const { selected_league, lineup_master_id } = this.state
    const { league_id, sports_id } = selected_league
    let param = {
      "league_id": league_id,
      "sports_id": sports_id,
    }
    Http.post(API.CREATE_LEAGUE_MASTER_DATA, param).then((res) => {
      const { data } = res

      const contest_types_list = _.map(data.contest_types, (item, key) => {
        return { label: item, value: key }
      })

      this.setState({
        contest_types_list: contest_types_list,
        gameWeekList: data.week
      })
    }).catch(err => {
      console.error(err);
    })

  }
  isInvalid = (name, state) => {
    if (state == '' || state == null) return false;
    let value = this.state[name]
    if (name == 'minimum_size') {
      return Number(value) < 2 || Number(value) > this.state.size
    } else {
      return !(Number(value) > 2 && Number(value) >= this.state.minimum_size)
    }
  }
  isValid = (name, state) => {
    if (state == '' || state == null) return false;
    let value = this.state[name]
    if (name == 'minimum_size') {
      return Number(value) >= 2 && Number(value) <= this.state.size
    } else {
      return Number(value) > 2 && Number(value) >= this.state.minimum_size
    }
  }
  validateForm() {
    const {
      start_week,
      game_name,
      minimum_size,
      size,
      // contest_type
    } = this.state;

    this.setState({
      formValid:
        game_name != '' &&
        this.isValid("minimum_size", minimum_size) &&
        this.isValid("size", size) &&
        !_.isEmpty(start_week) &&
        // !_.isEmpty(contest_type) &&

        Utils.isFormValid([
          'game_name'
        ], this.state)
    });
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });

    const { selected_league, game_name, start_week, minimum_size, size, CurrentLineup, contest_type } = this.state
    const { league_id, sports_id } = selected_league
    let param = {
      "league_id": league_id,
      "sports_id": sports_id,
      "contest_name": game_name,
      "contest_description": game_name,
      "contest_type": "1",
      // "contest_type": contest_type.value,
      "minimum_size": minimum_size,
      "size": size,
      "start_week": start_week.season_week,
      "prize_type": "1",
      "prize_pool": "0",
      "number_of_winners": 1,
      "entry_fee": "0",
      "prize_distribution_detail": [
      ]
    }

    Http.post(API.CREATE_PRIVATE_LEAGUE, param).then((res) => {
      let joinParam = {
        "league_id": league_id,
        "sports_id": sports_id,
        "contest_id": res.data.contest_id,
        "lineup_master_id": CurrentLineup.team_info.lineup_master_id,
      }
      Http.post(API.JOIN_GAME, joinParam).then((response) => {
        Utils.notify(response.message);

        const { actions } = this.props;
        actions.gtmEventFire('private_league_created');

        // Get Invite Code remains
        this.shraeModalInit(res.data)
      }).catch(err => {
        console.error(err);
      })

    }).catch(err => {
      console.error(err);
    })
  }

  shraeModalInit = (data) => {
    const { selected_league } = this.state;
    const { league_id } = selected_league;

    let param = {
      "league_id": league_id,
      "contest_id": data.contest_id
    }
    Http.post(API.GET_INVITE_CODE, param).then((res) => {
      this.setState({
        leagueName: data.contest_detail.contest_name,
        inviteData: res.data
      }, () => {
        this.toggleShareModal()
      })
    }).catch(err => {
      console.error(err);
    })
  }
  toggleShareModal = () => {
    this.setState({
      isShareModalShow: !this.state.isShareModalShow
    }, () => {
      if (!this.state.isShareModalShow) {
        this.props.history.push('/leagues')
      }
    })
  }
  render() {
    const { t } = this.props;
    const {
      selected_league,
      formValid,
      posting,
      game_name,
      gameWeekList,
      start_week,
      minimum_size,
      size,
      CurrentLineup,
      isShareModalShow,
      inviteData,
      leagueName,
      WeekOptions
      // contest_type,
      // contest_types_list
    } = this.state;
    const ShareLeagueInfoProps = {
      inviteData,
      leagueName,
      isOpen: isShareModalShow,
      toggle: this.toggleShareModal
    }

    const SelectProps = {
      isSearchable: false,
      className: 'react-select plain',
      classNamePrefix: 'react-select',
    }

    return (
      <Container className='cl-wrap'>
        <div className='multibanner-wrap'>
          <Banner />
          <MultiBanner type='52' />
        </div>
        <Row>
          <Col
            className='white-bg-wrapper'>
            <Form className="league-form" onSubmit={this.onSubmit} autoComplete='off'>
              <Row>
                <Col>
                  <div className="hero-title">
                    <h4 className='main-tl-cl'><Trans>Create Private League</Trans></h4>
                    <p className='subtitle'><Trans>Create a private league to compete against friends.</Trans></p>
                  </div>
                  {
                    CurrentLineup.user_join_count == 0 &&
                    <Alert color="danger" className='over-budget-msz'><Trans>Please submit your team!</Trans></Alert>
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className='form-lbl-group'>
                    <Input type="text"
                      id='game_name'
                      name='game_name'
                      placeholder={t('League Name')}
                      required
                      value={game_name}
                      autoComplete="off"
                      onChange={this.handleChange}
                      invalid={Utils.isInvalid("game_name", game_name)}
                      valid={Utils.isValid("game_name", game_name)} />
                    <FormFeedback className="form-lbl"><Trans>max 30 characters</Trans></FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row>
              <Col>
              <FormGroup>
                    <Select
                      {...SelectProps}
                      placeholder={t("Contest types")}
                      value={contest_type}
                      options={contest_types_list}
                      onChange={e => this.handleChange(e, 'contest_type')}
                    />
                  </FormGroup>
              </Col>
            </Row> */}
              <Row>
                <Col>
                  <FormGroup>
                    <Select
                      {...SelectProps}
                      placeholder={t("Scoring starts")}
                      value={start_week}
                      options={gameWeekList}
                      onChange={e => this.handleChange(e, 'start_week')}
                      getOptionLabel={({ week }) => (`${selected_league.week_label[week]}`)}
                      getOptionValue={(season_week) => season_week}
                    // defaultMenuIsOpen={true}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className='mid-gap-createlg'>
                <Col>
                  <FormGroup>
                    <Input type="number"
                      id='minimum_size'
                      name='minimum_size'
                      placeholder={t('Minimum Teams')}
                      required
                      value={minimum_size}
                      // options={WeekOptions}
                      autoComplete="off"
                      onChange={this.handleChange}
                      invalid={this.isInvalid("minimum_size", minimum_size)}
                      valid={this.isValid("minimum_size", minimum_size)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Input type="number"
                      id='size'
                      name='size'
                      placeholder={t('Maximum Teams')}
                      required
                      value={size}
                      autoComplete="off"
                      onChange={this.handleChange}
                      invalid={this.isInvalid("size", size)}
                      valid={this.isValid("size", size)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className='flex-btn'>
                  <Button type='submit' block
                    className='create-cl-btn'
                    disabled={
                      !formValid ||
                      posting ||
                      CurrentLineup.user_join_count == 0}
                  ><Trans>Create league</Trans></Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>


        {
          isShareModalShow &&
          <ShareLeagueInfo {...ShareLeagueInfoProps} />
        }
      </Container>
    );
  }
}

export default ReduxHOC(CreateLeague);