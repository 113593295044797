/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
// import AddToHomescreen from 'react-add-to-homescreen';
import { Component, Fragment } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Notification, { notify } from 'react-notify-toast';
import { CacheBuster, ScrollToTop } from 'Components';
import './App.scss';
import { Http, API, Utils, Auth, NavLink, Constant, _, Images } from "Helpers";
import { Layout } from "Pages";
import { ReduxHOC } from "HOC";
import { createBrowserHistory } from 'history';
import { getCookieConsentValue } from "react-cookie-consent";
import Cookies from 'js-cookie'
import {
  isAndroid,
  isIOS
} from "react-device-detect";
import { Trans } from "react-i18next";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const history = createBrowserHistory();
const location = history.location;
const queryString = require('query-string');
const parsed = queryString.parse(location.search);
let momentLib;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      AppInit: true,
      Cookies: undefined,
      forceUpdateData: {},
      showUpdateModal: false,
    }
  }
  componentDidMount() {
    this.onPageLoad()
    if (window.ReactNativeWebView) {
      setTimeout(() => {
        this.setState({
          AppInit: true
        });
      }, 800);

      setTimeout(() => {
        this.getAppSetting()
      }, 2000);
     
    }

    if (process.env.NODE_ENV !== "development") {
      // console.error = () => {};
      // console.log = () => {};
      // console.info = () => {};
    }
    if (Auth.getAuth() && Utils.getLangPosting()) {
      Http.post(API.UPDATE_LANG).then(response => {
        localStorage.removeItem('lang_posting');
      })
    }
    // if (Utils.getLang() == 'ar') momentLib = require('moment/locale/ar');

    Utils.setCpSession()

    if (!_.isNull(Utils.getMasterData()) && Utils.getMasterData().allow_sso == 1) {
      this.getSessionKey()
    }
  }

 

  getAppSetting() {

    let data = Utils.getMasterData()

    // console.log(Utils.getMasterData(), 'kkkk')
    // Http.post(API.GET_APP_SETTING).then(({ data }) => {
    const device_type = Utils.getLS('device_type')
    const av = Utils.getLS('app_version')
    const vdata = device_type == "1" ? { min: data.application_version.android_min_ver, max: data.application_version.android_latest_ver, apk_link: data.android_apk_link }
      : { min: data.application_version.ios_min_ver, max: data.application_version.ios_latest_ver, apk_link: data.ios_app_link }
    // console.log(vdata, 'vdatavdatavdatavdata')
    let _data = {
      is_android: device_type == "1",
      app_link: vdata.apk_link,
      is_force: 0
    }
    if (parseFloat(av) < parseFloat(vdata.min) || parseFloat(av) < parseFloat(vdata.max)) {
      // if (av < Number(vdata.min) || Number(vdata.min) == Number(vdata.max)) {
      //   // Force Update
      //   _data = {
      //     ..._data,
      //     is_force: 1
      //   }
      // } else if (av > Number(vdata.min) && av < Number(vdata.max)) {
      //   // Optional Update
      // }
      this.setState({ forceUpdateData: _data, update_data: vdata }, () => {

        if (Utils.getCookie('update_min') !== vdata.min && Utils.getCookie('update_max') !== vdata.max) { this.setState({ showUpdateModal: true }); }
        // console.log(this.state.forceUpdateData)
      });
    }
    // }).catch(error => {

    // });
  }
 
 
  appVersionHandler() {
    if (window.ReactNativeWebView) {
      let data = {
        action: "app_version",
        type: 'app_version',
        targetFunc: "app_version"
      };
      this.sendMessageToApp(data);
    }
  }
  showAppDownloadButton = (show = false) => {
    if (Utils.getLS('_adt')) return;
    let appDownload = document.getElementsByClassName("appDownload")[0];
    if (show) {
      appDownload.classList.add('animate_appDownload');
    } else {
      appDownload.classList.remove('animate_appDownload');
      Utils.setLS('_adt', true)
    }
    show = !show;
  }
  linkToPlayStore = () => {
    this.showAppDownloadButton()
    if (isAndroid) {
      window.open("https://play.google.com/store/apps/details?id=com.copafantasy", '_blank');
    } else if (isIOS) {
      window.open("https://apps.apple.com/app/capsigo/id1571860924", '_blank');
    } else {
      window.open("https://copafantasy.com", '_blank');
    }

  }
  getSessionKey = () => {

    // if (!_.isUndefined(parsed) && !_.isEmpty(parsed)) {
    //   if(!_.isNull(Utils.getMasterData()) && Utils.getMasterData().allow_sso_type == 'GODG'){
    //     this.authenticate(parsed.MSISDN.replace("\"", ""))
    //   } else {
    //     if(!Auth.getAuth()){
    //       this.authenticate(parsed.accessToken.replace("\"", ""))
    //     }

    //   }

    // } else {
    const _URC = Utils.getCookie('_URC')
    const _URCLS = localStorage.getItem('_URC')
    // const _USC = getCookieConsentValue() ? Utils.getCookie('_USC') : localStorage.getItem('_USC');
    // console.log(_URC,"_URC_URC");
    // console.log(_USC,"_USC_USC");
    if (_URC && _URC !== "" && _URC !== "null" && JSON.parse(_URC) !== null && !Auth.getAuth()) {
      this.authenticate(JSON.parse(_URC).user_guid)
      //if (!_URC && !_USC && !Auth.getAuth())
      //console.log('radhe radhe');
    } else {

      if (_URC && _URC !== "" && _URC != 'null' && JSON.parse(_URC) !== null) {
        //console.log(JSON.parse(_URCLS).user_guid,"JSON.parse(_URCLS).user_guid");
        //console.log(JSON.parse(_URC).user_guid,"JSON.parse(_URC).user_guid");

        if (JSON.parse(_URC).user_guid !== JSON.parse(_URCLS).user_guid) {

          localStorage.removeItem('auth');
          localStorage.removeItem('profile');
          localStorage.removeItem(`current_lineup`);
          localStorage.removeItem(`lineup`);
          localStorage.removeItem(`auth`);
          this.authenticate(JSON.parse(_URC).user_guid)
        }
      }
      //this.authenticate(JSON.parse(_URC).user_guid,_USC)
    }
    // }
  }

  authenticate = (token) => {

    this.setState({ posting: true });

    const { history, actions } = this.props;

    let param = {
      "token": token,
      //  "usctoken":"MUd2dVRRS1pyU2txWUxjQ0FRL2I1UUpyOEJlNkRuaGtLWXFNTTcxbFBpU2o5cng0SG0vQzhINHBRZEpUY3A2SVVaYlhtUWJrSFk4amxiTHZPZGlZdFNoK3FhZXl2SW1hczB5VjNPeEUyMmdDcEd5SnJKeU9vTWhLaW9XeVkzbFZYVkVKV2ZjVWtHMm1tTit5d3ZNQXBBTEwyZFhkYSsxZzZVcGJVMzVuVEVKUFFTRVJvNEFpMkJJMko2WTFNQ3pTK25ucW84V0lkVnQrYTQ5YnF5QlVYeHJEWEJiWTlRU1lBYkZZV000c2luRWZLUXJmanBERzlYeW5wZkJQMkhqblJPUmtSbkhOWkN0L0ovcEJLbG9YaHhON1EyUnlEMDJaditaM29QdWhUQU1HWlVVMVhJWGpwcEI3eFZJRklYNFBCNkVVRDM4aHJsNmdOVng2cHU3Vm4wVURLMG9qUW1wSWxNUlNGSHVEcm4vclROc2d5Q1cyUzRBdlY0bGx3OVNEbjVyTWVLQ0M1am9ZbnZJUHNwbjM1b0lxdFV0bWNHMkxXb1RxR0ZiK2ROWUV5U2JnRUo1NGJ5NDlUVDhycjV1Kw=="
    }
    // console.log(param,"RRRRRRRRRRR");
    // return ;
    Http.post(API.LOGIN, param).then(response => {
      if (window.ReactNativeWebView) {
        let data = {
          action: "login",
          type: 'login',
          targetFunc: "loginFunc",
          userData: response.data
        };
        this.sendMessageToApp(data);
      }
      localStorage.setItem('_URC', Utils.getCookie('_URC'))
      Auth.setAuth(response.data.session_key);
      Utils.setProfile(response.data.user_profile);
      Utils.setExistingUser(response.data.user_profile.existing_user)


      actions.gtmEventFire('login', {
        'user_id': response.data.user_profile.user_unique_id,
        'user_name': response.data.user_profile.user_name,
        'login_method': 'email',
      }, true)

      Utils.setLineupMasterId().then(res => {
        actions.setAppMaster(Utils.getMasterData() || {})
        actions.setCurrentLineup(Utils.getCurrentLineup())
        actions.setProfileData(response.data.user_profile)
      })
      history.push(Constant.DASHBOARD_PATH);


    }).catch(error => {
      console.log(error)
      if (error.data) {
        window.open(error.data.redirection, "_self")
      }
      this.setState({ posting: false });
      // if(!isGuestUser) {
      // }

    });
  }

  handleAddToHomescreen = () => {
    // Utils.setCookie('is_cancel_prompt', true, 15)
    // alert('1. Open Share menu\n2. Tap on "Add to Home Screen" button');
  };
  externalLinkHandler = (app_url) => {
    if (window.ReactNativeWebView) {
      let data = {
        action: "external_link",
        type: 'external_link',
        targetFunc: "external_link",
        url: app_url
      };
      this.sendMessageToApp(data);
    }
  }
  sendMessageToApp(action) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    } catch (error) { }
  }
  toggleModal(e) {
    const { forceUpdateData, update_data } = this.state
    if (forceUpdateData.is_force !== 0) {
      this.setState({
        showUpdateModal: !this.state.showUpdateModal
      })
    }
    if (e == 'fmd') {// if(update_data.min)
      Utils.setCookie('update_min', update_data.min, 25)
      Utils.setCookie('update_max', update_data.max, 25)
      this.setState({
        showUpdateModal: !this.state.showUpdateModal
      })
    }
  }
  onPageLoad() {
    if (window.ReactNativeWebView) {
      let data = {
        action: "web_loaded",
        type: '',
        targetFunc: "web_loaded",
      };
      this.sendMessageToApp(data)
    }
  }

  render() {
    const { showUpdateModal, forceUpdateData } = this.state;
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }
          return (
            <>
              {this.state.AppInit ?
                <Fragment>
                  {
                    isAndroid
                      ?
                      <div id="appDownload" className="appDownload" onClick={() => this.linkToPlayStore()}>
                        <i className="icon-cancel" onClick={(e) => [e.stopPropagation(), this.showAppDownloadButton()]} />
                        <i className="icon-copa-fantasy-logo ilogo" />
                        <span>Download App</span>
                      </div>
                      :
                      null
                  }
                  <Notification options={{ zIndex: 99999 }} />
                  <BrowserRouter>
                    <ScrollToTop>
                      <Switch>
                        <Route exact component={Layout} />
                        <Redirect from="*" to={Constant.DEFAULT_ROOT} />
                      </Switch>
                    </ScrollToTop>
                  </BrowserRouter>
                </Fragment> : ''}
              <div className="update-wrap">
                <Modal isOpen={showUpdateModal} toggle={() => { this.toggleModal('fmd') }} className='update-noti'>
                  <ModalBody>

                    <div className="popup-text">
                      <div className="heading-ts">
                        <Trans>Time for an upgrade!</Trans>
                      </div>
                      <div>
                        <Trans>You're not forced to update right now, but you'd be missing out on some great new features!</Trans>
                      </div>
                    </div>
                    {forceUpdateData.is_force == 0 ?
                      <div className="multi-btn">
                        <div className="upgrade-btn btn2-close" onClick={() => { this.toggleModal('fmd') }}>
                          <Trans>Close</Trans>
                        </div>
                        <div onClick={() => this.externalLinkHandler(forceUpdateData.app_link)} target='blank' className="upgrade-btn">
                          <Trans>Upgrade now</Trans>
                        </div>

                      </div> :
                      <div onClick={() => this.externalLinkHandler(forceUpdateData.app_link)} className="upgrade-btn">
                        <Trans>Upgrade now</Trans>
                      </div>
                    }
                  </ModalBody>
                </Modal>
              </div>
            </>
          );
        }}
      </CacheBuster>
    );
  }
}

export default ReduxHOC(App);