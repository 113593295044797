import { Component } from 'react';
import PropTypes from 'prop-types'

class Img extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: props.src,
      errored: false,
    };
  }

  onError = () => {
    if (!this.state.errored) {
      this.setState({
        src: this.props.fallbacksrc,
        errored: true,
      });
    }
  }

  render() {
    const { src, errored } = this.state;
    return (
      <img {...this.props} src={src} alt="" onError={this.onError} {...{ className: `${this.props.className} ${errored ? 'errored' : ''}` }} />
    )
  }
}
Img.propTypes = {
  src: PropTypes.string,
  fallbacksrc: PropTypes.string,
}
Img.defaultProps = {
  className: ''
}

export default Img