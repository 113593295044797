/* eslint-disable */
import { Component } from 'react';
import { ReduxHOC } from 'HOC';
import Slider from 'react-slick';
import { _ } from 'Helpers'
import { AdItem } from 'Components'

class AdSlider extends Component {
  render() {
    const { root, type } = this.props
    var SliderSettingsOne = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      fade: true
    };

    const _bannerData = _.filter(root.BannerData, o => o.ad_position_id == type)
    return (
      <div className="banner-slider">
        <Slider {...SliderSettingsOne}>
          {
            _.map(_bannerData, (item, idx) => {
              return <AdItem key={idx} item={item} />
            })
          }
        </Slider>
      </div>
    );
  }
}
AdSlider.defaultProps = {
  type: '1' // 1 = "", 2 = "Horizontal Big", 3 = "Horizontal small"
}
export default ReduxHOC(AdSlider);
