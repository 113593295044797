
/* eslint-disable */
import { Component } from 'react';
import SocialButton from './SocialButton';
import { Http, API, Auth, Utils, Config, Images, Constant, withRouter, Trans } from 'Helpers';
import { ReduxHOC } from 'HOC';
// import AppleLogin from 'react-apple-login'

// const UserObjFB = {
//   _profile: {
//     "email": "drasya.sharma@gmail.com",
//     "name": "Clint Barton",
//     "id": "3351586234858085",
//     "first_name": "Clint",
//     "last_name": "Barton",
//     "picture": {
//       "data": {
//         "height": 50,
//         "is_silhouette": false,
//         "url": "https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1289467317891370&height=50&width=50&ext=1566106368&hash=AeSMVcvSaIGeF-ie",
//         "width": 50
//       }
//     }
//   },
//   _provider: "facebook",
//   _token: {
//     accessToken: "EAAgk8mYfr3gBABjLHvPfv4crz4mK2f8dPK3TGZCwVU2quk0ES9W22COi3UkUDnjU3XMeCEiZCdOe1wGbQNbZA1cm1ZCbHzfeaAYlwXanYANH7UT8AUgLZCZAEGbKrPZC9S3ziO85634yIxo4lCm06eZC9kiKUv8643VHjeZCNJdIH8h611m1lSTGYwQfv5uUx4XkJzKcjOfpGkgZDZD",
//     expiresAt: 1583150401097
//   }
// }


// const UserObjGoogle = {
//   _profile: {
//     email: "vinfotech001@gmail.com",
//     firstName: "Dharmendra",
//     id: "103955283481988416838",
//     lastName: "Sharma",
//     name: "Dharmendra Sharma",
//     profilePicURL: "https://lh3.googleusercontent.com/-0A-VOMz0BVg/AAAAAAAAAAI/AAAAAAAAAEs/N8Ec4gsTUX0/s96-c/photo.jpg"
//   },
//   _provider: "google",
//   _token: {
//     accessToken: "ya29.GltKByEuQFtOWNxlmVWM7TpicM7RYQQKsk1rzHi91xV-5tKh7F-XOF-TMGau4Kq5zYcmLq6xYoixRc1Aq0uUTEEbgC9dPLLI7WjjmWECu8wHqt7w1_04DLg-jFq-",
//     expiresAt: 1563533143941,
//     expiresIn: 3600,
//     firstIssued_at: 1563529543941,
//     idToken: "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijg0ZjI5NGM0NTE2MDA4OGQwNzlmZWU2ODEzOGY1MjEzM2QzZTIyOGMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMTU3MTcxNjExNjk5LTZ1MHI4cHRvZ3VybWgwcGFhMWNtOTNjbTJxMnE0MjJvLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTU3MTcxNjExNjk5LTZ1MHI4cHRvZ3VybWgwcGFhMWNtOTNjbTJxMnE0MjJvLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTAzOTU1MjgzNDgxOTg4NDE2ODM4IiwiZW1haWwiOiJ2aW5mb3RlY2gwMDFAZ21haWwuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImF0X2hhc2giOiJTeGZQWHA5a0FGZkgwVXZ5amRrd3ZBIiwibmFtZSI6IkRoYXJtZW5kcmEgU2hhcm1hIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS8tMEEtVk9NejBCVmcvQUFBQUFBQUFBQUkvQUFBQUFBQUFBRXMvTjhFYzRnc1RVWDAvczk2LWMvcGhvdG8uanBnIiwiZ2l2ZW5fbmFtZSI6IkRoYXJtZW5kcmEiLCJmYW1pbHlfbmFtZSI6IlNoYXJtYSIsImxvY2FsZSI6ImVuLUdCIiwiaWF0IjoxNTYzNTI5NTQzLCJleHAiOjE1NjM1MzMxNDMsImp0aSI6ImU1MGZiZWUzOTE5ZWQyZmZjMDU0NWVhZDkwZDdhYjFmZjUzODkzNjgifQ.IFzbcEhdgDG1maEPwSDN9GtDKaqJ-UA3SgGGZTPe5b--ij6uagxegoB2MhfXAyrfWpb6T2tJy1008Pn8meBMJhneePM5vRYGlnvDlSP9NWDKBWMOHQa1IfFuvmt9yGAlF7t61f-Wzx9A35K07NlbpxXFYi6Cud1gEjuVBrPow7uHWgGuVgE7e-HNOidlHGR8ENZP1YdPqLgFng7QM1i-EvdKeJGYtZhBpSbgvhl2Cc92gRk_WyWpa_DPEEfzZsl7Nfp7Pctph20EB9qLo9XU3CINMjKxUv5EPmxzTlNZCjgmhuCxDTv1pdr0DZYFmBaJBuFzqQrJ7fxbZ-gZ7TOoWQ",
//     scope: "email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"
//   }
// }

class SocialForm extends Component {
  constructor(props) {
    super(props)
    this.nodes = {}
    this.state = {
      posting: false,
      logged: false,
      currentProvider: ''
    }
  }
  setNodeRef(provider, node) {
    if (node) {
      this.nodes[provider] = node
    }
  }

  Login = (user) => {
    console.log(user)
    const { isGuestUser, history, closeModal, t, actions } = this.props;
    if (!user._profile.email) {
      Utils.notify(t(Constant.MSZ_SOCIAL_EMAIL_NOT_FOUND), 'error')
      this.logout()
      return;
    }
    let param = {
      facebook: {
        "device_type": 3,
        "device_id": "",
        "password": "",
        "facebook_id": user._profile.id,
        "facebook_access_token": user._token.accessToken,
        "email": user._profile.email,
      },
      google: {
        "device_type": 3,
        "device_id": "",
        "password": "",
        "google_id": user._profile.id,
        "google_access_token": user._token.idToken,
        "email": user._profile.email
      }
    }
    if (window.ReactNativeWebView) { }
    else { }
    Http.post(API.LOGIN, param[user._provider]).then(response => {

      Auth.setAuth(response.data.session_key);
      Utils.setProfile(response.data.user_profile);
      Utils.setExistingUser(response.data.user_profile.existing_user)

      actions.gtmEventFire('login', {
        'user_id': response.data.user_profile.user_unique_id,
        'user_name': response.data.user_profile.user_name,
        'login_method': user._provider,
      }, true)
      Utils.setLineupMasterId().then(res => {
        actions.setAppMaster(Utils.getMasterData() || {})
        actions.setCurrentLineup(Utils.getCurrentLineup())
        actions.setProfileData(response.data.user_profile)
      })
      history.push(Constant.DASHBOARD_PATH);
      closeModal()
    }).catch(error => {
      // if (!isGuestUser) {
      // }
      closeModal()
    });

  }
  googleNativeLogin = (user, provider) => {
    let resp = user.response
    const { isGuestUser, history, closeModal, t, actions } = this.props;
    if (!resp.user.email) {
      Utils.notify(t(Constant.MSZ_SOCIAL_EMAIL_NOT_FOUND), 'error')
      this.logout()
      return;
    }
    let param = {
      "device_type": 3,
      "device_id": "",
      "password": "",
      "google_id": resp.user.id,
      "google_access_token": resp.idToken,
      "email": resp.user.email

    }
    Http.post(API.LOGIN, param).then(response => {
      Auth.setAuth(response.data.session_key);
      Utils.setProfile(response.data.user_profile);
      Utils.setExistingUser(response.data.user_profile.existing_user)

      actions.gtmEventFire('login', {
        'user_id': response.data.user_profile.user_unique_id,
        'user_name': response.data.user_profile.user_name,
        'login_method': provider,
      }, true)
      Utils.setLineupMasterId().then(res => {
        actions.setAppMaster(Utils.getMasterData() || {})
        actions.setCurrentLineup(Utils.getCurrentLineup())
        actions.setProfileData(response.data.user_profile)
      })
      history.push(Constant.DASHBOARD_PATH);
      closeModal()
    }).catch(error => {
      // if (!isGuestUser) {
      // }
      closeModal()
    });

  }
  logout = () => {
    const { logged, currentProvider } = this.state
    if (logged && currentProvider) {
      this.nodes[currentProvider].props.triggerLogout()
    }
  }
  handelNativeData() {
    window.addEventListener('message', (e) => {
      if (e.data == null) { return }
      if (e.data.action === 'login' && e.data.type === 'google') {
        let profileObj = {
          email: e.data.response.user && e.data.response.user.email ? e.data.response.user.email : e.data.response.email
        };
        this.googleNativeLogin(e.data, 'google')
      }
      else if (e.data.action === 'login' && e.data.type === 'facebook') {
        let fbUser = JSON.parse(e.data.response._bodyText);

        let user = {
          'email': fbUser.email ? fbUser.email : '',
          'accessToken': e.data.response.token,
          'id': fbUser.id
        };
        this.onFacebookSuccess({ _profile: user, _token: user.accessToken })
      }
      else if (e.data.action === 'push' && e.data.type === 'deviceid') {
        Utils.DeviceToken.setDeviceId(e.data.token);
      }
      else if (e.data.action === 'latLong' && e.data.type === 'deviceLatLong') {
        this.setUserLatLongTrigerDuration(e.data)
      }

    });
  }
  appNativeLogin = (type) => {
    if (window.ReactNativeWebView) {
      let data = {
        action: "login",
        type: type,
        targetFunc: "login"
      };
      this.sendMessageToApp(data);
    }
  }
  sendMessageToApp(action) {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    } catch (error) { }
  }
  // device_id
  responseGoogle = (googleUser, isSuccess) => {
    if (googleUser && isSuccess) {
      this.setState({ posting: true });
      var id_token = googleUser.tokenId;
      var googleId = googleUser.googleId;
      let param = {
        "email": googleUser.profileObj.email ? googleUser.profileObj.email : '',
        "google_id": googleId,
        "google_access_token": id_token,
        "password": '',
        "device_type": Utilities.getDeviceType(),
        "device_id": window.ReactNativeWebView ? ls.getItem('device_id') : WSC.deviceID,
        ...Utilities.getCpSession()
      }

      socialLogin(param).then((responseJson) => {
        WSManager.setTempToken(responseJson.data.Sessionkey);
        if (responseJson.response_code === WSC.successCode) {
          let nextStepData = { data: responseJson.data, facebook_data: null, google_data: googleUser, nextStep: responseJson.data.next_step };
          this.props.history.push(checkFlow(nextStepData))
        }
        this.setState({ posting: false });
      })
    }
  }
  onLogoutSuccess = () => {
    this.setState({
      logged: false,
      currentProvider: ''
    })
  }
  onLogoutFailure = (err) => {
    // console.error(err)
  }
  handleSocialLogin = (user) => {
    this.setState({
      logged: true,
      currentProvider: user._provider
    }, () => this.Login(user))
  }

  handleSocialLoginFailure = (err) => {
    this.setState({
      logged: false,
      currentProvider: ''
    })
  }
  componentDidMount() {
    if (window.ReactNativeWebView) {
      this.handelNativeData()
    }
  }
  render() {
    const { className } = this.props
    // if (Config.cognito.FB_APP_ID == "" && Config.cognito.GOOGLE_CLIENT_ID == "") return ''
    return (
      <div {...{ className: `login-social-wrap ${className}` }}>
        {
          (Config.cognito.FB_APP_ID != "" || Config.cognito.GOOGLE_CLIENT_ID != "") &&
          <div className="text-label"><Trans>Connect instantly with</Trans></div>
        }
        {/* <a onClick={() => this.Login(UserObjFB)}>Facebook</a> */}
        {/* <a onClick={() => this.Login(UserObjGoogle)}>Google</a> */}

        {/* <AppleLogin 
          clientId="tv.pdcfantasydarts.web" 
          redirectURI="https://fantasypdc.vinfotechprojects.com" /> */}

        {
          Config.cognito.FB_APP_ID != "" &&
          <SocialButton
            provider='facebook'
            appId={Config.cognito.FB_APP_ID}
            onLoginSuccess={this.handleSocialLogin}
            onLoginFailure={this.handleSocialLoginFailure}
            onLogoutSuccess={this.onLogoutSuccess}
            getInstance={this.setNodeRef.bind(this, 'facebook')}
            key={'facebook'}
            onInternetFailure={() => { return true }}
            className='fb-btn'
          >
            <i className="icn icon-fb" />
          </SocialButton>
        }
        {
          Config.cognito.GOOGLE_CLIENT_ID != "" && (
            window.ReactNativeWebView ?
              <div>
                <div onClick={() => this.appNativeLogin('google')} className="social-item gplus native">
                  <img src={Images.GOOGLE} alt="" width="30px" />
                </div>
              </div> :
              <SocialButton
                provider='google'
                appId={Config.cognito.GOOGLE_CLIENT_ID}
                onLoginSuccess={this.handleSocialLogin}
                onLoginFailure={this.handleSocialLoginFailure}
                onLogoutSuccess={this.onLogoutSuccess}
                getInstance={this.setNodeRef.bind(this, 'google')}
                key={'google'}
                onInternetFailure={() => { return true }}
                className='google-btn'
              >
                <i className="icn">
                  <img src={Images.GOOGLE} alt="" />
                </i>
              </SocialButton>
          )
        }
      </div>
    )
  }
}
SocialForm.defaultProps = {
  className: ''
}
const SocialFormWraped = ReduxHOC(SocialForm);
export default withRouter(SocialFormWraped, { withRef: true })