export const SET_APP_MASTER = 'SET_APP_MASTER';
export const SET_HEADER_CONFIG = 'SET_HEADER_CONFIG';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_CURRENT_LINEUP = 'SET_CURRENT_LINEUP';
export const LOGOUT = 'LOGOUT';
export const SET_AD_DATA = 'SET_AD_DATA';
export const GTM_EVENT_FIRE = 'GTM_EVENT_FIRE';


const CONSTANT = {
    SET_APP_MASTER: 'SET_APP_MASTER',
    SET_HEADER_CONFIG: 'SET_HEADER_CONFIG',
    SET_PROFILE_DATA: 'SET_PROFILE_DATA',
    SET_CURRENT_LINEUP: 'SET_CURRENT_LINEUP',
    LOGOUT: 'LOGOUT',
    SET_AD_DATA: 'SET_AD_DATA',
    GTM_EVENT_FIRE: 'GTM_EVENT_FIRE',
}

export default CONSTANT

