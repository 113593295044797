/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { API, Auth, Http, Utils, _, Trans } from 'Helpers';
class LanguageSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mdt: Utils.getMasterData()
    }
  }

  handleChange = (lng) => {
    console.log(lng, 'lng');
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
    Utils.setCookie('lang', lng, 365);
    Utils.setLang(lng);
    this.CallSportsConfig()
    if (Auth.getAuth()) {
      localStorage.setItem('lang_posting', JSON.stringify(true));
    }
  };

  CallSportsConfig = () => {
    Http.post(API.SPORTS_LEAG_LIST).then(res => {
      const { data } = res;
      _.map(data.sports, (obj, i) => {
        _.map(data.leagues[obj.sports_id], (item, idx) => {

          if (item.league_uid === Utils.getMultiLeg().league_uid) {
            let itmObj = { ...item, ...obj }
            Utils.setSinglePlayerFlag(obj.sports_config.is_single_player);
            Utils.setMultiLeg(itmObj);
            window.location.reload(false)
          }
        })
      })

    }).catch(err => {
      console.log(err);
      this.setState({
        posting: false
      })
    })
  }

  render() {
    const { i18n, is_footer } = this.props;
    const { mdt } = this.state
    return (
      is_footer ?
        <div className='language-change-block'>
          <h6 className='footer-list-head'><Trans>CHANGE LANGUAGE</Trans></h6>
          <div className="footer-link-list">
            {
              _.map(mdt.language_list, (item, idx) => <span key={idx}><a className={i18n.language === idx ? 'active' : ''} onClick={() => this.handleChange(idx)}><Trans>{item}</Trans></a></span>)
            }
          </div>
        </div>
        :
        <nav className="language-switch">
          {
            _.map(mdt.language_list, (item, idx) => <a key={idx} className={i18n.language === idx ? 'active' : ''} onClick={() => this.handleChange(idx)}><Trans>{item}</Trans></a>)
          }
        </nav>


    )
  }
}
LanguageSwitch.defaultProps = {
  is_footer: false
}
export default withTranslation()(LanguageSwitch);