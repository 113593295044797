/* eslint-disable */
import { API, Http, Utils } from 'Helpers';
import { Component } from 'react';

class ModeHOC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: '0',
    };
  }
  componentDidMount() {
    if (Utils.getAppInfo('enable_maintenance_mode') == 1) {
      this.setState({
        mode: '2' // "0 = Workiing, 1 = Upcoming, 2 = Maintenance"
      }, () => {
        try {
          Http.post(API.GET_PAGE_DETAIL, {
            "page_id": 1,
            "sports_id": 0
          })
        } catch (error) {
          
        }
      })
    } else {
      fetch(`/mode.json?${new Date().getTime()}`, { cache: 'no-cache' })
        .then((response) => response.json())
        .then((res) => {
          this.setState({
            mode: res.mode
          })
        });
    }
  }

  render() {
    const { mode } = this.state;
    return this.props.children({ mode });
  }
}

export default ModeHOC;
