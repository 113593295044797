import Utils from '../Utils'
const Images = {
  LOGO_EN: Utils.getImageBaseUrl('logo-en.png'),
  VINFOTECH_LOGO: Utils.getImageBaseUrl('vinfotech-logo.png'),
  DEFAULT_IMG: Utils.getImageBaseUrl('default_user.png'),
  DEFAULT_IMG_DARK: Utils.getImageBaseUrl('default_user_dark.png'),
  DEFAULT_JERSEY: Utils.getImageBaseUrl('default-jersey.png'),
  DEFAULT_FLAG: Utils.getImageBaseUrl('flag_default.jpg'),
  BOOSTER_1: Utils.getImageBaseUrl('triple-captain.png'),
  BOOSTER_2: Utils.getImageBaseUrl('bench-booster.png'),
  BOOSTER_3: Utils.getImageBaseUrl('free-hit.png'),
  BOOSTER_0: Utils.getImageBaseUrl('wild-card.png'),
  LEAGUE_WALL_LOGO: Utils.getImageBaseUrl('league-wall-logo.png'),
  SAMPLE_ADV_SM: Utils.getImageBaseUrl('sample_adv_sm.png'),
  SWAP_ICON: Utils.getImageBaseUrl('arrw.svg'),
  CNV_SELECT: Utils.getImageBaseUrl('c-n-v-select.svg'),
  CAPTAIN: Utils.getImageBaseUrl('captain.png'),
  VICE_CAPTAIN: Utils.getImageBaseUrl('vice-captain.png'),
  PITCH_LOADER: Utils.getImageBaseUrl('pitch-loader.png'),
  BLUE_PLAYER: Utils.getImageBaseUrl('blue-player.png'),
  PINK_PLAYER: Utils.getImageBaseUrl('pink-player.png'),
  COUNTRY_FLAG: Utils.getImageBaseUrl('country-flag.jpeg'),
  HOME_PLAYERS_IMG: Utils.getImageBaseUrl('home-players-img.png'),
  HOME_JERSEY: Utils.getImageBaseUrl('home_jersey.png'),
  HOME_DART: Utils.getImageBaseUrl('home_dart.png'),
  HOME_AIM: Utils.getImageBaseUrl('home_aim.png'),
  HOME_PLAYERS: Utils.getImageBaseUrl('home_players_update.png'),
  CAP_BOOSTER: Utils.getImageBaseUrl('cap-booster.png'),
  GOOGLE: Utils.getImageBaseUrl('google.svg'),
  LOGO_DRAWER: Utils.getImageBaseUrl('logo-drawer.png'),
  MAINTENANCE_BG: Utils.getImageBaseUrl('maintenance-bg.png'),
  PLAY_STORE: Utils.getImageBaseUrl('play-store.png'),
  '3-4-3': Utils.getImageBaseUrl('3-4-3.png'),
  '3-5-2': Utils.getImageBaseUrl('3-5-2.png'),
  '4-3-3': Utils.getImageBaseUrl('4-3-3.png'),
  '4-4-2': Utils.getImageBaseUrl('4-4-2.png'),
  '4-5-1': Utils.getImageBaseUrl('4-5-1.png'),
  '5-2-3': Utils.getImageBaseUrl('5-2-3.png'),
  '5-3-2': Utils.getImageBaseUrl('5-3-2.png'),
  '5-4-1': Utils.getImageBaseUrl('5-4-1.png'),
  LEAGUE_IMG: Utils.getImageBaseUrl('league-img.png'),
  MINI_BANNER: Utils.getImageBaseUrl('mini-banner.png'),
  PRIVATE_CONTEST_PHONE: Utils.getImageBaseUrl('phone.png'),
  PLAY_STORE_BADGE: Utils.getImageBaseUrl('google-play-badge.svg'),
  APP_STORE_BADGE: Utils.getImageBaseUrl('app-store-badge.svg'),
  YT_THUMB_1: Utils.getImageBaseUrl('yt_thumb_1.jpg'),
  TEMP_PLAYER_CARD: Utils.getImageBaseUrl('temp-player-card.png'),
  DEFAILT_FLAG_SHIELD: Utils.getImageBaseUrl('defailt_flag_shield.png'),

  IMG_GAMES: Utils.getImageBaseUrl('games.png'),
  IMG_ENGAGEMENT: Utils.getImageBaseUrl('engagement.png'),
  IMG_BRAND_POSITIONING: Utils.getImageBaseUrl('brand-positioning.png'),
  IMG_PERCEPTION: Utils.getImageBaseUrl('perception.png'),
  GAMIFICATION_IMG: Utils.getImageBaseUrl('gamification-img.png'),
  GAMIFICATION_IMG_SM: Utils.getImageBaseUrl('gamification-img-sm.png'),
  PRODUCT_IMG1: Utils.getImageBaseUrl('product_img1.png'),
  PRODUCT_IMG2: Utils.getImageBaseUrl('product_img2.png'),
  PRODUCT_IMG3: Utils.getImageBaseUrl('product_img3.png'),
  BACK_IC: Utils.getImageBaseUrl('back_ic.png'),
  BANNER_DUMMY1: Utils.getImageBaseUrl('dmy1.png'),
  BANNER_DUMMY2: Utils.getImageBaseUrl('dmy2.png'),
  BANNER_DUMMY3: Utils.getImageBaseUrl('dmy3.png'),
  SL_RT_IMG: Utils.getImageBaseUrl('SL_imageX2.png'),
  LEAGUE_DEFAULT: Utils.getImageBaseUrl('defaultLeague_banner.png'),
  PRIZESDUMMY: Utils.getImageBaseUrl('prizesDummy.png'),
  LEFT_ARR: Utils.getImageBaseUrl('left.svg'),
  RIGHT_ARR: Utils.getImageBaseUrl('right.svg'),
  COUNTDOWN_WATCH: Utils.getImageBaseUrl('countDown_watch.svg'),
  MOBILE_HOME: Utils.getImageBaseUrl('mobile_home.png'),
  LEFT_LEAFS: Utils.getImageBaseUrl('left-leafs.svg'),
  RIGHT_LEAFS: Utils.getImageBaseUrl('right-leafs.svg'),
  '343': Utils.getImageBaseUrl('343.svg'),
  '352': Utils.getImageBaseUrl('352.svg'),
  '433': Utils.getImageBaseUrl('433.svg'),
  '442': Utils.getImageBaseUrl('442.svg'),
  '451': Utils.getImageBaseUrl('451.svg'),
  '532': Utils.getImageBaseUrl('532.svg'),
  '541': Utils.getImageBaseUrl('541.svg'),
  DOWN_ARR: Utils.getImageBaseUrl('ic_down.png'),
  UP_ARR: Utils.getImageBaseUrl('ic_up.png'),
  TROPHY_PRIZES: Utils.getImageBaseUrl('cup.svg'),
  COPY_IC_SVG: Utils.getImageBaseUrl('C_ic.svg'),
  GW_SCORE_EQ: Utils.getImageBaseUrl('eq-sp.svg'),
  UP_ICN: Utils.getImageBaseUrl('up_icons.svg'),
  DOWN_ICN: Utils.getImageBaseUrl('down_icons.svg'),
  VC: Utils.getImageBaseUrl('vc_img.svg'),
  CAP: Utils.getImageBaseUrl('c_img.svg'),
  TROPHY_IC: Utils.getImageBaseUrl('trophy.svg'),
  SHARE_SVG: Utils.getImageBaseUrl('share_svg.svg'),
  LIMG: Utils.getImageBaseUrl('limg.png'),
  ISL_LOGO: Utils.getImageBaseUrl('ISL_LOGO.png'),
  CLOCK_FMT: Utils.getImageBaseUrl('clock_fMT.svg'),
  DRAWER_NW: Utils.getImageBaseUrl('drawer_nw.svg'),
  LOGIN_SIGNUP_HOME: Utils.getImageBaseUrl('login_signup_ic.svg'),

  //How To Play Slider 
  S1_HTP: Utils.getImageBaseUrl('1-step.png'),
  S2_HTP: Utils.getImageBaseUrl('2-step.png'),
  S3_HTP: Utils.getImageBaseUrl('3-step.png'),
  S4_HTP: Utils.getImageBaseUrl('4-step.png'),

  S1_HTP1: Utils.getImageBaseUrl('1-stepRes.png'),
  S2_HTP2: Utils.getImageBaseUrl('2-stepRes.png'),
  S3_HTP3: Utils.getImageBaseUrl('3-stepRes.png'),
  S4_HTP4: Utils.getImageBaseUrl('4-stepRes.png'),

  PLAYER_CRD_IMG: Utils.getImageBaseUrl('t-imfg.png'),
  CAPTAIN_BLUE: Utils.getImageBaseUrl('captain-b.png'),
  VICE_CAPTAIN_W: Utils.getImageBaseUrl('vice-captain-w.png'),
  // APP_STORE_BADGE
  BTM_ARRW_WINS: Utils.getImageBaseUrl('btm_arrw_wins.svg'),
  DUMMY_WINN: Utils.getImageBaseUrl('dummy_winn.png'),
  BLACK_LOGO: Utils.getImageBaseUrl('logo_wbg.png')

}

export default Images;