import { Component } from 'react';
import { Modal, Button, ModalBody, ModalFooter } from 'reactstrap'
import { NavLink, Trans } from 'Helpers';
class UpdateProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {

    const { isOpen, toggleModal } = this.props;
    return (
      <>

        <Modal isOpen={isOpen} toggleModal={toggleModal} className='update-profile-modal' size={'sm'}>
          <i className="icon-profile-info" />
          <ModalBody>
            <h4 className='title'><Trans>Update your Profile</Trans> </h4>
            <p><Trans>Please update your profile to receive exciting offers!</Trans></p>
          </ModalBody>
          <ModalFooter>
            <Button className='update-prf lbtn'><NavLink to='/profile'>Go To Profile</NavLink></Button>
            <Button className='update-prf rbtn' type='submit' onClick={toggleModal}><Trans>Maybe Later</Trans></Button>
          </ModalFooter>

        </Modal>
      </>
    )
  }
}

export default UpdateProfileModal