/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Row, Col, FormGroup, Input, Button, Form } from 'reactstrap'
import Select from 'react-select';
import { Utils, Http, API, _, Trans } from 'Helpers';
import DatePicker from 'react-date-picker';
import { ChangePasswordModal } from 'Modals';
import { ReduxHOC } from 'HOC';

let _this = null
class ProfileForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      ...props,
      country: null,
      state: null,
      _gender: null,
      ChangePwdShow: false
    }
    this.node = null;
    this.setInstance = this.setInstance.bind(this)
  }

  setInstance (node) {
    this.node = node
    if (typeof this.props.getInstance === 'function') {
      this.props.getInstance({
        ProfileImageUpdate: this.ProfileImageUpdate
      })
    }
  }

  ProfileImageUpdate = (data) => {
    setTimeout(() => {
      _this.setState({
        image: data,
        formValid: true
      })
    }, 200) 
  }

  getSelectData = (type) => {
    let param = {
      "master_country_id": this.state.country.master_country_id
    }
    Http.post((type === 'country_list' ? API.GET_COUNTRY_LIST : API.GET_STATE_LIST), (type === 'country_list' ? {} : param)).then(response => {
      this.setState({
        [type]: response.data[type],
        posting: false
      });
    }).catch(error=>{
      this.setState({ posting: false });
    });
  }
  
  onDateChange = (dob) => {
    this.setState({ dob }, this.validateForm)
  }

  onCalendarOpen = () => {
    if(this.state.dob == '' || this.state.dob == null ) {
      this.setState({ dob: Utils.getDateYearsOld() }, this.validateForm)
    }
  }
  
  handleChange = (e, type) => {
    const name = !type ? e.target.name : type;
    let value = !type ? e.target.value : e;
    if(!_.isObject(value)) {
      let re = name === 'user_name' ? /\s/g : /^\s/g;
      value = value.replace(re, "");
    }
    this.setState({ [name]: value }, () => {
      if (type === 'country') {
        this.setState({
          state: null,
          state_list: []
        })
        this.getSelectData('state_list')
      }
      this.validateForm()
    });
  };

  validateForm() {
     const {  _gender, dob, first_name, user_name, last_name } = this.state;  
    this.setState({
      formValid:  
                  _gender != '' &&
                  dob != '' &&
                  first_name != '' &&
                  user_name != '' &&
                  last_name != '' &&
                  Utils.isFormValid([
                    'first_name',
                    //'phone_no',
                    'user_name',
                    'last_name'
                    //'address',
                    //'city',
                    //'zip_code'
                  ], this.state)
    });
  }

  componentDidMount() {
    const { 
      country_list,
      state_list,
      gender_list,
      master_country_id,
      master_state_id,
      gender,
      dob,
      phone_no,
      address,
      city,
      zip_code } = this.state

    _.forEach(country_list, (item, i) => {
      if(master_country_id == item.master_country_id) {
        this.setState({
          country: item
        })
      }
    })
    _.forEach(state_list, (item, i) => {
      if(master_state_id == item.master_state_id) {
        this.setState({
          state: item
        })
      }
    })
    _.forEach(gender_list, (item, i) => {
      if(gender == item.id) {
        this.setState({
          _gender: item
        })
      }
    })
    if(dob) {
      this.setState({
        dob: new Date(dob)
      })
    }
    if(phone_no == null) {
      this.setState({
        phone_no: ''
      })
    }
    if(address == null) {
      this.setState({
        address: ''
      })
    }
    if(city == null) {
      this.setState({
        city: ''
      })
    }
    if(zip_code == null) {
      this.setState({
        zip_code: ''
      })
    }

    const { form_validate } = this.props;
    if(form_validate) {
      this.validateForm()
    }
   // console.log(form_validate);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { 
      first_name,
      last_name,
      user_name,
      phone_no,
      email,
      dob,
      address,
      country,
      state,
      _gender,
      city,
      team_id,
      image,
      zip_code,
      state_list } = this.state;
      let _dob = dob == "" ? null : dob
    let param = {
      "first_name": first_name,
      "last_name": last_name,
      "email": email,
      "user_name": user_name,
      "phone_no": phone_no,
      "address": address,
      "city": city,
      "zip_code": zip_code,
      "team_id": team_id,
      
      "dob": _dob == null ? _dob : Utils.DobFormat(_dob),
      "gender": !_.isEmpty(_gender) ? _gender.id : '',
      "master_country_id": !_.isEmpty(country) ? country.master_country_id : '',
      "master_state_id": !_.isEmpty(state) ? state.master_state_id : '',
      "image": image,
    }
    Http.post(API.UPDATE_PROFILE, param).then(response => {
      Utils.notify(response.message);
      response.data.user_profile.dob = response.data.user_profile.dob == null ? response.data.user_profile.dob : Utils.DobFormat(response.data.user_profile.dob)
      if(!_.isEmpty(state_list)) {
        response.data.user_profile.state_list = state_list
      }
      this.props.UpdateProfile(response.data.user_profile)
      this.setState({ posting: false });
    }).catch(error => {
      this.setState({ posting: false });
    });
  }

  ChangePwdToggle = () => {
    this.setState({
      ChangePwdShow: !this.state.ChangePwdShow
    })
  }
  
  render() {
    const { t, i18n } = this.props;
    _this = this
    const { 
      posting,
      formValid,
      first_name,
      last_name,
      user_name,
      phone_no,
      email,
      dob,
      address,
      country,
      state,
      _gender,
      city,
      country_list,
      state_list,
      zip_code, 
      gender_list,
      ChangePwdShow
    } = this.state;

    const ChangePasswordProps = {
      isOpen: ChangePwdShow,
      toggle: this.ChangePwdToggle
    }
    return (
      <Form ref={this.setInstance} className="profile-form" onSubmit={this.onSubmit}>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Input
                type="text"
                placeholder={t("First Name")}
                name='first_name'
                value={first_name}
                onChange={this.handleChange}
                invalid={Utils.isInvalid("first_name", first_name)}
                valid={Utils.isValid("first_name", first_name)}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Input
                  type="text"
                  placeholder={t("Last Name")}
                  name='last_name'
                  value={last_name}
                  onChange={this.handleChange}
                  invalid={Utils.isInvalid("last_name", last_name)}
                  valid={Utils.isValid("last_name", last_name)}
                />
            </FormGroup>
          </Col>

          <Col sm={6}>
            <FormGroup>
              <Input
                type="text"
                placeholder={t("Mobile")}
                name='phone_no'
                value={phone_no}
                onChange={this.handleChange}
                invalid={Utils.isInvalid("phone_no", phone_no)}
                valid={Utils.isValid("phone_no", phone_no)}
              />
              <span className='input-mobile-small'><Trans>Please enter number without '+'. It should be in this format 971500101010.</Trans></span>
            </FormGroup>
          </Col>
        
          <Col sm={6}>
            <FormGroup>
              <Input
                type="email"
                placeholder={t("Email")}
                name='email'
                value={email}
                disabled
                // onChange={this.handleChange}
                // invalid={Utils.isInvalid("email", email)}
                // valid={Utils.isValid("email", email)}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Input
                type="text"
                placeholder={t("Username")}
                name='user_name'
                value={user_name}
                onChange={this.handleChange}
                invalid={Utils.isInvalid("user_name", user_name)}
                valid={Utils.isValid("user_name", user_name)}
              />
            </FormGroup>
          </Col>
        
          <Col sm={6}>
            <Row className='custom-gutter'>
              <Col>
                <FormGroup>
                  {/* <Input type="text" placeholder="Date of birth" /> */}

                  <DatePicker
                    dayPlaceholder='DD'
                    monthPlaceholder='MM'
                    yearPlaceholder='YYYY'
                    format="dd-MM-yyyy"
                    locale={i18n.language}
                    onChange={this.onDateChange}
                    onCalendarOpen={this.onCalendarOpen}
                    value={dob}
                    calendarIcon={<i className='icon-calender' />}
                    className='form-control'
                    clearIcon={null}
                    maxDate={Utils.getDateYearsOld()}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Select
                  placeholder={t("Gender")}
                  className='react-select plain'
                  classNamePrefix="react-select"
                  value={_gender}
                  onChange={e => this.handleChange(e, '_gender')}
                  options={gender_list}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                />
              </Col>

            </Row>
          </Col>

          <Col sm={6}>
            <FormGroup>
              <Input
                type="text"
                placeholder={t("Street Address")}
                name='address'
                value={address}
                onChange={this.handleChange}
                invalid={Utils.isInvalid("address", address)}
                valid={Utils.isValid("address", address)}
              />
            </FormGroup>
          </Col>
        
          <Col sm={6}>
            <FormGroup>
              <Select
                placeholder={t("Country")}
                className='react-select plain'
                classNamePrefix="react-select"
                isSearchable
                value={country}
                options={country_list}
                onChange={e => this.handleChange(e, 'country')}
                getOptionLabel={({ country_name }) => country_name}
                getOptionValue={({ master_country_id }) => master_country_id}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Select
                placeholder={t("State")}
                className='react-select plain'
                classNamePrefix="react-select"
                isSearchable
                value={state}
                options={state_list}
                onChange={e => this.handleChange(e, 'state')}
                getOptionLabel={({ state_name }) => state_name}
                getOptionValue={({ master_state_id }) => master_state_id}
              />
            </FormGroup>
          </Col>
        
          <Col sm={6}>
            <FormGroup>
              <Input
                type="text"
                placeholder={t("City")}
                name='city'
                value={city}
                onChange={this.handleChange}
                invalid={Utils.isInvalid("city", city)}
                valid={Utils.isValid("city", city)}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Input
                type="text"
                placeholder={t("Postal Code")}
                name='zip_code'
                value={zip_code}
                onChange={this.handleChange}
                invalid={Utils.isInvalid("zip_code", zip_code)}
                valid={Utils.isValid("zip_code", zip_code)}
              />
            </FormGroup>
          </Col>
        
          <Col sm={6}>
            <div className="change-pwd-btn">
              <Button size="sm" color="primary" onClick={() => this.ChangePwdToggle()}><Trans>Edit</Trans></Button>
              <div className="overflow-hidden">**********</div>
            </div>
          </Col>
        
          <Col>
            <Button disabled={!formValid || posting} className='submit-btn' block color="primary"><Trans>Save</Trans></Button>
          </Col>
        </Row>
        {
          ChangePwdShow &&
          <ChangePasswordModal {...ChangePasswordProps}/>
        }
      </Form>
    )
  }
}

ProfileForm.propTypes = {}

export default ReduxHOC(ProfileForm);