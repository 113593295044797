/* eslint-disable */
import { Component } from 'react';
import { _, API, Http, Utils, NavLink, Trans, Constant, Auth, Images } from 'Helpers';
import { Col, Container, Row } from 'reactstrap';
import { PlayerCardItem, DashLeague, DeadlineClock, CreateJoinButton, FixtureComponent, Banner, TopPLItem, MultiBanner } from 'Components';
import Slider from 'react-slick';
import { PlayerCard, MiniLeagueModal, UpdateProfileModal, GlobalLeagueModal } from 'Modals';
import { ReduxHOC } from 'HOC';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { notify } from 'react-notify-toast';
import { event } from 'jquery';
const {
    REACT_APP_CLIENT_URL,
    REACT_APP_SALARY_SUBFFIX,
    REACT_APP_BASE_URL_PROD
} = process.env

// const { REACT_APP_DOC_TITLE } = process.env;
class PrivateLobby extends Component {

    constructor(props) {
        super(props);
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.state = {
            SelectedLeague: Utils.getMultiLeg(),
            CurrentLineup: props.root.CurrentLineup,
            isLeagueShow: false,
            updateLineupInfo: new Date().valueOf(),
            updateLineupInfoLeague: new Date().valueOf(),
            HomeData: {},
            game_week_scores: {},
            your_team_overview: [],
            firstPrizeDetail: {},
            secondPrizeDetail: {},
            thirdPrizeDetail: {},
            topScoringPlayer: [],
            topScoringPlayerPosting: true,

            SelectedPlayer: {},
            isPlayerCardShow: false,
            miniLeagueData: [],
            minileagueloader: true,
            miniLeagueBlank: false,
            isMiniLeagueShow: false,
            selectedMinileague: '',
            UpdateProfileModalShow: false,
            topScoringPlayerList: [],
            globaalContestPrize: [],
            isGlobalLeagueShow: false,
            miniTotal: 0,
            freeTransfers: '',
            androidLink: '',
            iosLink: '',
            bank_details: {},
            sal_cap: ''
        }
    }
    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }
    getHomeData = () => {
        const { league_id, sports_id } = this.state.SelectedLeague;
        let param = {
            "sports_id": sports_id,
            "league_id": league_id,
            is_top_players_req: 0,
            is_match_list_req: 0
        }
        this.setState({ isLoaderShow: true })
        Http.post(API.GET_HOME_DATA, param).then(res => {
            const { data } = res;
            this.setState({ isLoaderShow: false })
            this.GetTopScoringPlayers();
            const dataflt = data.your_team_overview
            const BankDetails = dataflt.filter(dataflt => dataflt.display_label == 'Bank')
            // your_team_overview
            // console.log(BankDetails, 'BankDetailsBankDetails')
            this.setState({
                HomeData: data,
                game_week_scores: data.game_week_scores,
                your_team_overview: data.your_team_overview,
                freeTransfers: data.my_transafer.available_transfer,
                bank_details: BankDetails
            }, () => {
                if (data.joined_week !== 0 && !_.isEmpty(Utils.getGuestTeam())) {
                    Utils.removeGuestTeam()
                    Utils.notify('Your team has already been created')
                }
                Utils.setJoined(data.joined_week)
                Utils.setActive(data.active_week)
            })

            if (data.winner_prizes.length > 2) {
                this.setState({
                    firstPrizeDetail: data.winner_prizes[0],
                    secondPrizeDetail: data.winner_prizes[1],
                    thirdPrizeDetail: data.winner_prizes[2]
                })
            }

        }).catch(error => {
            console.error(error)
        });

    }

    GetTopScoringPlayers = () => {
        const { league_id, sports_id } = this.state.SelectedLeague;
        let param = {
            "sports_id": sports_id,
            "league_id": league_id,
            "limit": 10
        }
        // let s3_param = [
        //   API.S3_TOP_SCORING_PLAYERS,
        //   league_id
        // ]
        Http.post(API.TOP_SCORING_PLAYERS, param).then((responseJson) => {
            try {
                this.setState({
                    topScoringPlayer: responseJson.data.overall_top_scorer,
                    topScoringPlayerList: responseJson.data.player_list,
                    topScoringPlayerPosting: false
                })
            } catch (error) {
                console.log(error)
                this.setState({
                    topScoringPlayerPosting: false
                })
            }
        })
    }

    YourTeamOverviewFilter = (data, block) => {
        let _filter = _.filter(data, item => {
            return item.block == block;
        })
        if (block == 1 && _filter.length > 0) {
            let arr = new Array(8)
            _.map(arr, (item, idx) => {
                return arr[idx] = _filter[idx]
            })
            return arr;
        } else {
            return _filter;
        }
    }

    PlayerCardInit = (SelectedPlayer) => {
        this.setState({
            SelectedPlayer
        }, () => {
            this.PlayerCardToggle()
        })
    }

    PlayerCardToggle = () => {
        this.setState({
            isPlayerCardShow: !this.state.isPlayerCardShow
        })
    }

    componentDidMount() {
        // this.notificationsCount()

        if (this.state.isMiniLeagueShow || this.state.UpdateProfileModalShow || this.state.isGlobalLeagueShow || this.state.isPlayerCardShow) {
            document.body.style.overflow = 'hidden';
        }
        // this.MasterData()
        this.getHomeData()
        if (Auth.getAuth()) {
            Utils.setLineupMasterId().then(res => {
                this.props.actions.setAppMaster(Utils.getMasterData() || {})
                this.props.actions.setCurrentLineup(Utils.getCurrentLineup())
                this.setState({
                    CurrentLineup: Utils.getCurrentLineup()
                }, () => {
                    const { CurrentLineup, SelectedLeague } = this.state;
                    const { league_id, sports_id } = SelectedLeague;
                    if (!_.isEmpty(CurrentLineup.team_info)) {
                        this.getUserLineupDetails({
                            "league_id": league_id,
                            "sports_id": sports_id,
                            "contest_id": CurrentLineup.contest_id,
                            "lineup_master_id": CurrentLineup.team_info.lineup_master_id,
                            "week": (CurrentLineup.week_info.active_week >= CurrentLineup.team_info.joined_week) ? CurrentLineup.week_info.active_week : CurrentLineup.team_info.joined_week
                        })
                        this.GetMiniLeagueList()
                    } else {
                        this.setState({
                            miniLeagueBlank: true,
                            minileagueloader: false,
                        });
                        this.GetMiniLeagueList()
                    }
                })
            })
        } else {
            this.setState({
                miniLeagueBlank: true,
                minileagueloader: false,
            });
        }

        if (Utils.getProfileModal()) {
            // console.log("in")
        } else {
            Utils.setProfileModal(true)
            this.UpdateProfileModalToggle()
        }
        this.setState({ updateLineupInfoLeague: new Date().valueOf() })
    }
    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    // MasterData = () => {
    //     Http.post(API.GET_APP_MASTER_DATA).then(res => {
    //         const data = res.data
    //         this.setState({
    //             androidLink: data.android_apk_link,
    //             iosLink: data.ios_app_link,
    //         })
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }

    AllLeagueToggle = () => {
        this.setState({
            isLeagueShow: true
        }, () => {

        })
    }

    // Mini League 
    GetMiniLeagueDetail = (contest_id) => {
        const { SelectedLeague } = this.state;
        const { league_id, sports_id } = SelectedLeague;
        let param = {
            "sports_id": sports_id,
            "league_id": league_id,
            "contest_id": contest_id,
        }
        Http.post(API.GET_MINI_LEAGUE_DETAIL, param).then(res => {
            const { data } = res;
            this.setState({
                selectedMinileague: data.mini_league,
            })
            console.log('GET_MINI_LEAGUE_DETAIL', data);
        }).catch(error => {
            console.error(error)
        });
    }

    GetMiniLeagueList = () => {
        const { SelectedLeague } = this.state;
        const { league_id, sports_id } = SelectedLeague;
        let param = {
            "sports_id": sports_id,
            "league_id": league_id,
        }
        Http.post(API.GET_UNJOINED_MINI_LEAGUES, param).then(res => {
            const { data } = res;
            this.setState({
                minileagueloader: false,
                miniLeagueData: data.mini_league_list,
                miniLeagueBlank: _.isEmpty(data.mini_league_list),
                miniTotal: data.mini_league_list && data.mini_league_list.length || 0
            })
        }).catch(error => {
            this.setState({
                minileagueloader: false
            })
            console.error(error)
        });
    }
    toggleGlobalModal = (item = {}) => {
        this.setState({
            isGlobalLeagueShow: !this.state.isGlobalLeagueShow
        })
    }

    multiJoinLeague = (item) => {
        let c_data_block = 1
        let { HomeData } = this.state
        // this.props.history.push({ pathname: '/my-team', state: {} })
        this.toggleGlobalModal(HomeData);
    }

    toggleMiniModal = (item = {}) => {
        const { HomeData } = this.state
        if (item.contest_access_type !== '2') {
            this.setState({
                isMiniLeagueShow: !this.state.isMiniLeagueShow
            }, () => {
                if (!_.isEmpty(item) && !_.isUndefined(item.contest_id)) {
                    if (item.contest_access_type === '2' || HomeData.joined_week === 0) {
                        if (item.contest_access_type === '2') {
                            // this.multiJoinLeague(item)
                            // this.props.history.push({ pathname: '/my-team', state: { c_id: item.contest_id, s_id: item.sports_id, l_id: item.league_id, c_data_block: c_data_block } })
                        }
                        else {
                            this.props.history.push({ pathname: '/my-team', state: { c_id: item.contest_id, s_id: item.sports_id, l_id: item.league_id } })
                        }
                    }
                    else { this.GetMiniLeagueDetail(item.contest_id) }
                }
            })
        }
        else {
            this.multiJoinLeague(item)
        }

    }
    getUserLineupDetails = (options) => {
        Http.post(API.GET_USER_LINEUP_DETAIL, options).then(response => {
            const { data } = response;
            const _lineup = _.orderBy(
                data.lineup,
                function (o) {
                    return new Number(o.rank);
                },
                ['asc']
            )

            this.setState({
                sal_cap: data.salary_cap,
                LineupList: _.cloneDeep(_lineup),
            })
        }).catch(error => {
            console.error(error)
        });

    }

    joinLeagues = () => {

        this.setState({ posting: true });

        const { SelectedLeague, CurrentLineup, selectedMinileague } = this.state;
        const { league_id, sports_id } = SelectedLeague;


        let join_game_param = {
            "sports_id": sports_id,
            "league_id": league_id,
            "contest_id": selectedMinileague.contest_id,
            "lineup_master_id": CurrentLineup.team_info.lineup_master_id,
        }
        Http.post(API.JOIN_GAME, join_game_param).then(responseJson => {
            Utils.notify(responseJson.message, "success", 5000);

            this.props.history.push('/leagues')
            this.setState({ posting: false });

        }).catch(error => {
            console.error(error);
            this.setState({
                posting: false,
                formValid: false,
                join_code: '',
            });
        });


    }

    UpdateProfileModalToggle = () => {
        this.setState({
            UpdateProfileModalShow: !this.state.UpdateProfileModalShow
        })
    }

    initOnboardPage = () => {
        this.props.history.push(Constant.ONBOARD_ROOT)
    }

    gotoClienturl = (url) => {
        const originalUrl = REACT_APP_BASE_URL_PROD;
        const encodedUrl = encodeURIComponent(originalUrl);
        const finalUrl = `${url}?cbkurl=${encodedUrl}`;
        //console.log(finalUrl,"finalUrl");
        let status = Utils.isNativeWeb()
        switch (status) {
            case 0:
                window.open(finalUrl, "_self")
                break;
            case 1:
                window.mobileApp.nativeLogin()
                break;
            case 2:
                window.webkit.messageHandlers.nativeLogin.postMessage("IOS")
                break;
            default:
                break;
        }
    }

    checkValidUrl = () => {
        let { HomeData } = this.state
        const is_sso = Utils.getMasterData().allow_sso == 1;
        if (!is_sso) {
            if (Auth.getAuth()) {
                this.props.history.push('/my-team')
            } else {
                window.location.replace(Constant.ONBOARD_ROOT)
            }
        } else {
            if (is_sso) {
                if (!isEmpty(HomeData.prize_distribution_detail) && Auth.getAuth()) {
                    this.props.history.push('/my-team')
                }
                else {
                    this.gotoClienturl(REACT_APP_CLIENT_URL)
                }

            }

        }


    }
    isMobileView = () => {
        return window.innerWidth <= 767;
    }

    goToMytem = () => {
        this.props.history.push({ pathname: '/my-team', state: {} })
    }
    gotoLeague = (event) => {
        event.stopPropagation()
        this.props.history.push('/leagues')
    }

    showImage(item) {
        if (item.player_image) {
            return item.player_image
        } else if (item.jersey) {
            return item.jersey
        } else {
            return Images.DEFAULT_JERSEY
        }
    }

    render() {
        const { i18n } = this.props
        const is_sso = Utils.getMasterData().allow_sso !== '1';
        const {
            HomeData,
            CurrentLineup,
            isLeagueShow,
            updateLineupInfo,
            SelectedLeague,
            game_week_scores,
            your_team_overview,
            // firstPrizeDetail,
            // secondPrizeDetail,
            // thirdPrizeDetail,
            topScoringPlayer,
            topScoringPlayerPosting,
            isPlayerCardShow,
            SelectedPlayer,
            miniLeagueData,
            miniLeagueBlank,
            minileagueloader,
            isMiniLeagueShow,
            selectedMinileague,
            LineupList,
            UpdateProfileModalShow,
            topScoringPlayerList,
            updateLineupInfoLeague,
            isGlobalLeagueShow,
            miniTotal,
            freeTransfers, androidLink,
            iosLink,
            sal_cap
        } = this.state;

        const my_team_overview = this.YourTeamOverviewFilter(your_team_overview, 1);
        const available_boosters = this.YourTeamOverviewFilter(your_team_overview, 0);

        const AllLeagueProps = {
            updateLineupInfo: updateLineupInfoLeague
        }

        const topPLsettings = {
            dots: false,
            infinite: false,
            arrows: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 6,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 5,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 540,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 1,
                        initialSlide: 0,
                    }
                }
            ]
        };

        const PlayerCardProps = {
            isOpen: isPlayerCardShow,
            toggle: this.PlayerCardToggle,
            selected_league: SelectedLeague,
            Player: SelectedPlayer,
        }

        const GlobalLeagueProps = {
            isOpen: isGlobalLeagueShow,
            toggle: this.toggleGlobalModal,
            HomeData: HomeData,
            joinLeagues: this.joinLeagues
        }

        const MiniLeagueProps = {
            isOpen: isMiniLeagueShow,
            toggle: this.toggleMiniModal,
            selectedMinileague: selectedMinileague,
            joinLeagues: this.joinLeagues
        }

        var SliderSettingsMiniLeague = {
            infinite: true,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        // rows: 2,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                    }
                },
            ]
        };

        const DeadlineClockProps = {
            date: Utils.getUtcToLocal(HomeData.next_week_start_time),
            gameweek: 1,
            is_full: false
        }

        const UpdateProfileModalProps = {
            isOpen: UpdateProfileModalShow,
            toggleModal: this.UpdateProfileModalToggle
        }
        const {
            last_week,
            active_week,
            joined_week,
            next_week,
        } = HomeData
        // console.log(HomeData.game_week_scores, 'HomeData.game_week_scores')
        return (

            <Container fluid className='prl-bg-clr'>
                <div className='lobby-main-wfix'>
                    <div className='title-for-dashboard'>
                        <div className='title-name'><Trans>Season Long</Trans></div>
                        <NavLink to={'/how-to-play'} className='link-howtoplay'><Trans>How to Play</Trans>?</NavLink>
                    </div>
                    {/* <Banner type="21" paddingTop paddingBottom /> */}
                    <div className='multibanner-wrap home'>
                        {/* style={{ paddingTop: '15px' }} */}
                        <Banner type='53' />
                        <MultiBanner type="21" />
                    </div>
                    {HomeData.joined_week !== 0 &&
                        <Row>
                            <div>
                                <div className='gw-score-boxs' onClick={() => { this.goToMytem() }}>
                                    <div className='gw-txt'>
                                        {Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} <Trans>Score</Trans>
                                        <span className='eq-img' onClick={(e) => this.gotoLeague(e)}>
                                            <img src={Images.GW_SCORE_EQ} alt='' />
                                        </span>
                                    </div>
                                    <div className='Score-block'>
                                        {!_.isUndefined(game_week_scores.week_score) && HomeData.game_week_scores.week_score}
                                        {(_.isEmpty(game_week_scores.week_score) || _.isUndefined(game_week_scores.week_score)) && '--'}
                                    </div>
                                    <div className='pt-txt'>
                                        Points
                                        <div className='gr-dot-main'>
                                            <span className='green-dot'></span>
                                            {Utils.getMultiLeg().week_abbr[HomeData.active_week]}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>}
                    <Row className='gutter-fixs'>
                        <Col>
                            <div className="myteaminfo-box height-auto">

                                <div {...{ className: `deadline-info-wrap deadline-private background-change ${_.isEmpty(HomeData) ? 'loader' : ''}` }}>
                                    {
                                        !_.isEmpty(HomeData) &&
                                        <>
                                            {
                                                (joined_week == 0 && active_week != last_week) ?
                                                    <div className="cell">
                                                        <div className="title">
                                                            <Trans>INVITE FRIENDS AND COMPETE FOR EXCITING PRIZES</Trans>
                                                        </div>
                                                        <p className='sub-title sm'>
                                                            <Trans>Submit your team before:</Trans>
                                                        </p>
                                                        <p className='sub-title text-bold text-uppercase'>
                                                            {Utils.getUtcToLocal(HomeData.next_week_start_time, 'D MMM, HH:mm')}
                                                        </p>
                                                    </div>
                                                    :
                                                    <>
                                                        {
                                                            (next_week == joined_week && active_week != last_week) ?
                                                                <div className="cell">
                                                                    <div className="title">
                                                                        <Trans>Manage team deadline</Trans>
                                                                    </div>
                                                                    <p className='sub-title'>
                                                                        <Trans>You can manage your team for the upcoming games till</Trans>{" "}

                                                                    </p>
                                                                    <p className='sub-title'> <b>{Utils.getUtcToLocal(HomeData.next_week_start_time, 'DD MMM,  H:mm')}</b></p>
                                                                </div>
                                                                :
                                                                active_week != last_week ?
                                                                    <>
                                                                        <div className='gw-score-box' onClick={() => { this.goToMytem() }}>
                                                                            <div className='gw-txt'>
                                                                                {Utils.getWeekLabel(Utils.getMultiLeg().week_label['1'])} <Trans>Score</Trans>
                                                                                <span className='eq-img' onClick={(e) => this.gotoLeague(e)}>
                                                                                    <img src={Images.GW_SCORE_EQ} alt='' />
                                                                                </span>
                                                                            </div>
                                                                            <div className='Score-block'>
                                                                                {!_.isUndefined(game_week_scores.week_score) && HomeData.game_week_scores.week_score}
                                                                                {(_.isEmpty(game_week_scores.week_score) || _.isUndefined(game_week_scores.week_score)) && '--'}
                                                                            </div>
                                                                            <div className='pt-txt'>
                                                                                <Trans>Points</Trans>
                                                                                <div className='gr-dot-main'>
                                                                                    <span className='green-dot'></span>
                                                                                    {Utils.getMultiLeg().week_abbr[HomeData.active_week]}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="cell deadline-wraps">
                                                                            <div className="title">
                                                                                <Trans>Transfer deadline</Trans>
                                                                            </div>
                                                                            <p className='sub-title'>
                                                                                <Trans>You can make transfers for the coming week till</Trans>{" "}
                                                                                <b>{Utils.getUtcToLocal(HomeData.next_week_start_time, 'DD MMM,  H:mm')}</b>
                                                                            </p>
                                                                            {/* <p className='sub-title sm'>{SelectedLeague.free_transfer[next_week]} <Trans>Free</Trans> {" "} */}
                                                                            <p className='sub-title sm'>{Utils.getMultiLeg().free_transfer_week == HomeData.next_week ? <Trans>Unlimited</Trans> : freeTransfers || '--'} <Trans>Free Transfers</Trans> {" "}
                                                                                {
                                                                                    SelectedLeague.sports_config.is_team_available != 0 &&
                                                                                    <>
                                                                                        (<Trans>Max</Trans> {SelectedLeague.player_per_team[next_week]} <Trans>per team</Trans>)
                                                                                    </>
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div className="cell">
                                                                        <div className="title">
                                                                            <Trans>This tournament is over!</Trans>
                                                                        </div>
                                                                        <p className='sub-title sm'>
                                                                            <Trans>Please visit later to join more exciting games!</Trans>
                                                                        </p>
                                                                    </div>
                                                        }
                                                    </>
                                            }


                                            {
                                                active_week != last_week &&
                                                <div className="cell last">
                                                    <div className="deadline-clock-wrap">
                                                        <DeadlineClock {...DeadlineClockProps} />
                                                    </div>
                                                    {
                                                        (joined_week == 0 && active_week != last_week) ?
                                                            // <NavLink className='btn btn-secondary btn-sm trn-btn' to='/my-team'>
                                                            <>
                                                                {is_sso ?
                                                                    <div key="12" className='btn btn-secondary btn-sm trn-btn' onClick={() => this.checkValidUrl()}>
                                                                        <Trans>Create Your Team</Trans>
                                                                    </div> :

                                                                    !_.isNull(Utils.getMasterData()) && Utils.getMasterData().allow_sso_type == 'GODG' ?
                                                                        Auth.getAuth() ?
                                                                            <a onClick={() => this.checkValidUrl()} className='btn btn-secondary btn-sm trn-btn'><Trans>Create Your Team</Trans></a> :
                                                                            <a href="http://test-portal.godigital.tv/" className='btn btn-secondary btn-sm trn-btn' target="_parent"><Trans>Create Your Team</Trans> </a>

                                                                        :
                                                                        <a onClick={() => this.checkValidUrl()} className='btn btn-secondary btn-sm trn-btn'><Trans>Create Your Team</Trans></a>

                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    (next_week == joined_week && active_week != last_week) ?
                                                                        <NavLink className='btn btn-sm trn-btn' to={{ pathname: '/my-team', state: { c_id: '', s_id: '', l_id: '', c_data_block: '' } }}>
                                                                            <Trans>Manage Team</Trans>
                                                                        </NavLink>
                                                                        :
                                                                        active_week != last_week ?
                                                                            <NavLink className='btn btn-sm trn-btn' to={{ pathname: '/transfers', state: { c_id: '', s_id: '', l_id: '', c_data_block: '' } }}>
                                                                                <Trans>Make Transfer</Trans>
                                                                            </NavLink>
                                                                            :
                                                                            <></>
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            }

                                        </>
                                    }
                                </div>


                            </div>
                        </Col>
                    </Row>
                    <Row className='gutter-fix'>
                        <Col sm={6}>
                            <div className="current-lineup-wrap">
                                <div className={(_.isEmpty(miniLeagueData) || _.isUndefined(miniLeagueData)) ? 'clw-head dn-home' : "clw-head"}>
                                    <div className="cell">

                                        <div className="lbl label-h-fix">
                                            {(_.isEmpty(miniLeagueData) || _.isUndefined(miniLeagueData)) ? <div></div> : <div><span className='new-join-btm'>{miniTotal} New</span> League{miniTotal > 1 && 's'} to Join</div>}
                                        </div>
                                    </div>
                                </div>
                                {(_.isEmpty(miniLeagueData) || _.isUndefined(miniLeagueData)) ? <Banner type='23' /> :
                                    <div className='banner-wrap-join'>
                                        {/* <Banner type='23' /> */}
                                        <Slider {...SliderSettingsMiniLeague}>
                                            {_.map(miniLeagueData, (item, idx) => {
                                                {/* console.log(item.web_banner_image_url, 'asdjoi') */ }
                                                return (
                                                    <div className='available-league-banner' key={idx} onClick={() => this.toggleMiniModal(item)}>
                                                        <img src={item.web_banner_image_url !== '' ? item.web_banner_image_url : Images.LEAGUE_DEFAULT} className={item.web_banner_image_url ? '' : 'transparent-img'} alt='banner-img' />
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>}
                            </div>

                        </Col>
                        <Col sm={6}>
                            {
                                Auth.getAuth() &&
                                <div className="current-lineup-wrap">
                                    <div className="clw-head p-fix-join pt-fx">
                                        <div className="cell">
                                            <div className="lbl">
                                                <Trans>My Team Overview</Trans>
                                            </div>
                                        </div>
                                        <div className='overview-coll'>
                                            <div>
                                                <div className='title-ob'><Trans>Overview</Trans></div>
                                                <div className='ovrview-wrap'>
                                                    <div className='overview-card'>
                                                        <span className='overview-main'>

                                                            {(game_week_scores.overall_rank !== 0 || game_week_scores.overall_rank !== '') && (Utils.getActive() > parseInt(Utils.getJoined())) &&
                                                                !_.isEmpty(game_week_scores) &&
                                                                <span>
                                                                    {game_week_scores.prev_overall_rank
                                                                        < game_week_scores.overall_rank && game_week_scores.prev_overall_rank !== '0'
                                                                        ?
                                                                        < img src={Images.DOWN_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                                        :
                                                                        < img src={Images.UP_ICN} className='icon-arrows' alt='up-down-ranks' />
                                                                    }
                                                                </span>
                                                            }
                                                            {game_week_scores.overall_rank || '--'}</span>
                                                        <span className='sub-title-overview'><Trans>Overall Rank</Trans></span>
                                                    </div>
                                                    <div className='overview-card'>
                                                        {/* <span className='overview-main'>{freeTransfers || '--'}</span> */}
                                                        <span className='overview-main'>{Utils.getMultiLeg().free_transfer_week == HomeData.next_week ? <Trans>Unlimited</Trans> : freeTransfers || '--'}</span>
                                                        <span className='sub-title-overview'><Trans>Free Transfers</Trans></span>
                                                    </div>
                                                    <div className='overview-card'>
                                                        <span className='overview-main'>{Utils.getProfile().currency}{' '}{_.isEmpty(Utils.getCurrentLineup().team_info) ? '100' : Utils.AvailableSalary(sal_cap, LineupList)}{REACT_APP_SALARY_SUBFFIX}</span>
                                                        {/* <span className='overview-main'>{Utils.getProfile().currency}{' '}{Utils.getProfile().balance || '--'}</span> */}
                                                        <span className='sub-title-overview'><Trans>Bank</Trans></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className='title-ob mt20'><Trans>Boosters</Trans></div>
                                                <div>
                                                    {
                                                        available_boosters.length != 0 ?
                                                            <div className="booster-overview-tbl">
                                                                {
                                                                    _.map(available_boosters, (item, idx) => {
                                                                        {/* console.log(item, 'asadadsad') */ }
                                                                        return (
                                                                            <div className={`flx-booster ${item.value == 'متاح' || item.value == 'Available' ?
                                                                                'bg-blues' : 'gray-cls'}`}>
                                                                                {item.icon === "wildcard" ? <img className='booster-im' src={Images.BOOSTER_0} />
                                                                                    :
                                                                                    item.icon === "triple_captain" ? <img className='booster-im' src={Images.BOOSTER_1} />
                                                                                        :
                                                                                        item.icon === "bench_booster" ? <img className='booster-im' src={Images.BOOSTER_2} />
                                                                                            :
                                                                                            <img className='booster-im' src={Images.BOOSTER_3} />}
                                                                                <div className="booster-row" key={idx}>
                                                                                    <div className="cell text-semibold booster-labels">{item.display_label}</div>
                                                                                    {
                                                                                        i18n.language === 'ar' ?
                                                                                            <div className='booster-name'>
                                                                                                {item.value}
                                                                                            </div>
                                                                                            :
                                                                                            <div className='booster-name'>
                                                                                                {item.value}
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                            :
                                                            <div className="booster-overview-tbl nodata-view ">
                                                                No Data Available
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row className='gutter-fix'>
                        <Col xs={12} sm={6}>
                            <div className={HomeData.joined_week !== 0 && HomeData.contest_id === '1' ? 'joined-league' : 'joined-league h230-p'}>
                                <div className='prizes-joined-text flex-for-vlp'>
                                    {HomeData.joined_week !== 0 && HomeData.contest_id === '1' ? <span className='jl-pb20'><Trans>Joined Leagues</Trans></span>
                                        : <span>win exciting prizes</span>}
                                    {
                                        (_.isEmpty(miniLeagueData) || _.isUndefined(miniLeagueData)) && HomeData.joined_week !== 0 && HomeData.contest_id === '1' ?
                                            <div className='fw-fix'>
                                                <NavLink to='/leagues' className='spcl-prizes'><Trans>View All Leagues</Trans> </NavLink>
                                                <span className='blue-arrs'> {'>>'}</span>
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                                {HomeData.joined_week !== 0 && HomeData.contest_id === '1' ?
                                    <div className='joined-container'>
                                        <div className='classic-joined'><Trans>Classic Leagues</Trans>
                                            <span><Trans>full Season</Trans></span>
                                        </div>
                                        <div className='title-joined'>
                                            <span><Trans>League</Trans></span>
                                            <span><Trans>Rank</Trans></span>
                                        </div>
                                        <div>
                                            <DashLeague {...AllLeagueProps} />
                                        </div>
                                    </div> :
                                    (_.isUndefined(HomeData.joined_week)) ? <div className='nodata-view'>No Data Available</div> :
                                        <Banner type='24' />}
                                {(_.isEmpty(miniLeagueData) || _.isUndefined(miniLeagueData)) ?
                                    <div>
                                        <NavLink to='/prizes/1' className='spcl-prizes'><Trans>View All Prizes</Trans></NavLink>
                                        <span className='blue-arrs'> {'>>'}</span>
                                    </div>

                                    :
                                    <div>
                                        {HomeData.joined_week !== 0 && HomeData.contest_id === '1' ?
                                            <NavLink to='/prizes/1' className='spcl-prizes' nw={true}><Trans>See the Special Leagues Prizes</Trans></NavLink>
                                            :
                                            <NavLink to='/prizes/1' className='view-all-pr'><Trans>View All Prizes</Trans></NavLink>
                                        }
                                        {HomeData.joined_week !== 0 && HomeData.contest_id === '1' ? <span className='blue-arrs'> {'>>'}</span> : ''}
                                    </div>}
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className='mt-cls'>
                            <CreateJoinButton is_banner {...this.props} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="gray-box m-t mb-f bgc-none">
                                <div className="gray-box-head p-fix-hd">
                                    <span className='top-pl-head'><Trans>Top Players</Trans></span>
                                    <span>
                                        <NavLink to='/statistics/all' className='all-stats-btn'><span className='decoration'><Trans>View All Players</Trans></span> <span className='blue-arrs'> {'>>'}</span></NavLink>

                                    </span>
                                </div>
                                <div className="gray-box-body statistics-slider">
                                    {
                                        topScoringPlayerPosting ?
                                            <div className="spinner-static">
                                                <Utils.Spinner primary />
                                            </div>
                                            :
                                            <>
                                                {
                                                    _.isEmpty(topScoringPlayer) ?
                                                        <div className="blank-msz"><span><Trans>There is no scoring available yet</Trans></span></div>
                                                        :
                                                        <Slider {...topPLsettings}>
                                                            {
                                                                _.map(topScoringPlayer, (item, idx) => {
                                                                    return <TopPLItem key={idx} {...{ item: _.merge(topScoringPlayerList[item.player_uid], item), PlayerCardInit: this.PlayerCardInit, topScoringPlayerList: topScoringPlayerList, showImage: this.showImage }} />
                                                                })
                                                            }
                                                        </Slider>
                                                }
                                            </>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {(iosLink || androidLink) &&
                        <Row className='mt-40'>
                            <Col>
                                <div className='app-banner'>
                                    <div className='text-section'>
                                        <span className='title-main'>Manage from anywhere</span>
                                        <span className='sub-tl'>Download the Season Long Fantasy App to track your roster and stay up-to-date with goal, lineup and injury alerts wherever you are.</span>
                                    </div>
                                    <div className='image-section'>
                                        {iosLink && <a target='blank' href={iosLink}><img src={Images.APP_STORE_BADGE} /></a>}

                                        {androidLink && <a target='blank' href={androidLink}><img src={Images.PLAY_STORE_BADGE} /></a>}
                                    </div>
                                </div>
                            </Col>
                        </Row>}
                    <div className='banner-block-lobby'>
                        {!this.isMobileView() ?
                            <Banner type="1" paddingBottom />
                            :
                            <Banner type="33" paddingBottom />}
                    </div>
                    {/* <Banner type="8" paddingTop paddingBottom /> */}

                    {
                        isPlayerCardShow &&
                        <PlayerCard {...PlayerCardProps} />
                    }
                    {
                        isMiniLeagueShow &&
                        <MiniLeagueModal {...MiniLeagueProps} />
                    }
                    {
                        isGlobalLeagueShow && <GlobalLeagueModal {...GlobalLeagueProps} />
                    }
                    {/* {
                        UpdateProfileModalShow &&
                        <UpdateProfileModal {...UpdateProfileModalProps} />
                    } */}
                </div>
            </Container>
        );
    }
}

export default withRouter(ReduxHOC(PrivateLobby));