/* eslint-disable */
import { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Utils, Constant, Trans, Images } from 'Helpers';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReduxHOC } from 'HOC';
class ShareLeagueInfo extends Component {
  onCopyToggle = (msz) => {
    Utils.notify(msz, "success", 2000);
  }
  render() {
    const { isOpen, toggle, inviteData, leagueName } = this.props;
    console.log(inviteData);
    return (
      <Modal isOpen={isOpen} toggle={toggle}
        {...{
          className: `custom-modal join-n-create modal-share`
        }} size={'sm'}>
        <i className="close-btn icon-cancel" onClick={() => toggle()} />
        <ModalHeader>

          {/* {leagueName} */}
          <div className='share-title'>SHARE</div>

        </ModalHeader>
        <ModalBody>
          <div className="share-wrapper">

            <p className='top-disc'>
              <Trans>Send invite to your friends through</Trans>
            </p>

            <div className="invite-wrapper">
              <div className="link-displ">{origin}/invite/{inviteData.code}</div>
              <CopyToClipboard onCopy={() => this.onCopyToggle(Constant.COPY_URL_MSZ)} text={origin + '/invite/' + inviteData.code}>
                <div className='copy-box'>COPY</div>
              </CopyToClipboard>
              {/* <i className="icn icon-share" /> */}
            </div>
            {/* <div className="or-div">
              <hr /> <span><Trans>or</Trans></span>
            </div>
            <h4 className="title sm">
              <Trans>Copy League Code</Trans>
            </h4> */}

            <div className='or-block'>Or, share league code</div>

            <div className='League-code-wrap'>
              <div className="code-text">{inviteData.code || '--'}</div>

              <CopyToClipboard onCopy={() => this.onCopyToggle(Constant.COPY_CODE_MSZ)} text={inviteData.code}>
                {/* <i className="code-icn icon-copy" /> */}
                <img className='c-ic-img' src={Images.COPY_IC_SVG} alt='copy-ic' title='copy-ic' />
              </CopyToClipboard>

            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ReduxHOC(ShareLeagueInfo);
