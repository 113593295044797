/* eslint-disable eqeqeq */
import { Component } from 'react';
import { Auth, Constant, Trans, Utils, _ } from 'Helpers';
import { Button } from 'reactstrap';
import { AppDownloadModal, LeagueModal } from 'Modals';
const { REACT_APP_IS_PRIVATE_CONTEST } = process.env;
class CreateJoinButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLeagueModalShow: false,
      isAppDownloadModalShow: false,
    }
  }

  LeagueModalToggle = (type) => {
    this.setState({
      isLeagueModalShow: !this.state.isLeagueModalShow
    }, () => {
      if (type == 'joined') {
        this.props.callback()
      }
      if (type == 'is_private') {
        this.AppDownloadModalToggle()
      }
    })
  }

  AppDownloadModalToggle = () => {
    this.setState({
      isAppDownloadModalShow: !this.state.isAppDownloadModalShow
    })
  }
  componentDidMount() {
    if (this.state.isLeagueModalShow) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'unset';
  }
  onTrigger = () => {

    if (Auth.getAuth()) {
      if (REACT_APP_IS_PRIVATE_CONTEST == 'false') {
        this.LeagueModalToggle()
      } else {
        this.AppDownloadModalToggle()
      }
    } else {
      if (Utils.getMasterData().allow_sso !== '1') {
        this.props.history.push(Constant.ONBOARD_ROOT)
      }

      else {


        if (!_.isNull(Utils.getMasterData()) && Utils.getMasterData().allow_sso_type == 'GODG') {
          window.top.location.href('http://test-portal.godigital.tv/')
        } else {
          window.location.replace(Constant.CLIENT_URL)
        }


      }
    }
  }

  render() {
    const { is_banner } = this.props;
    const {
      isLeagueModalShow,
      isAppDownloadModalShow
    } = this.state;
    const LeagueModalProps = {
      isOpen: isLeagueModalShow,
      toggle: this.LeagueModalToggle
    }
    const AppDownloadModalProps = {
      isOpen: isAppDownloadModalShow,
      toggle: this.AppDownloadModalToggle
    }

    return (
      <>
        {
          is_banner ?
            <div className={this.props.leagues ? "mt-30 join-league-banner" : "join-league-banner"} onClick={() => this.onTrigger()}>
              <div className="jlb-top">
                <div className="cell is-txt">
                  <div className="jlb-title"><Trans>Play with friends!</Trans></div>
                  <div className="jlb-subtitle"><Trans>Create or join leagues to play with friends, family and colleagues over a season</Trans></div>
                </div>
                <div className="cell last">
                  <i className="jlb-graphic" />
                </div>
              </div>
              <div className="jlb-footer">
                <div className="fcell">
                  <Trans>Create a League</Trans>
                </div>
                <div className="fcell">
                  <Trans>Join a League</Trans>
                </div>
              </div>
            </div >
            :
            <Button block onClick={() => this.onTrigger()}><Trans>Create & Join</Trans></Button>
        }
        {
          isLeagueModalShow &&
          <LeagueModal {...LeagueModalProps} />
        }

        <AppDownloadModal {...AppDownloadModalProps} />

      </>
    );
  }
}
CreateJoinButton.defaultProps = {
  callback: () => { },
  is_banner: false
}

export default CreateJoinButton;
