/* eslint-disable */
import { Component, Fragment } from 'react';
import { _, Images } from 'Helpers';
import { Col, Container, Row } from 'reactstrap';

// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// // import "swiper/css";
// // import "swiper/css/scrollbar";
// // import required modules
// import { Pagination, Scrollbar } from "swiper";

class AboutUs extends Component {
  render() {
    return (
      <>
        <Container fluid className='fluid-container-row top-banner'>
          <Container className='center-container'>
            <Row>
              <Col>
                <div className="top-header-content">
                  <h1 className="header-title-50 text-bold">Are you looking to grow your brand riding on the popularity of <span className="secondary-color">FIFA WORLD CUP 2022?</span></h1>
                  <p>World Cup soccer is one of the biggest sporting events on the planet drawing attention from a massive fan base all over the world. Its one of the most watched events globally <br /> If you are a brand looking to engage your customers around World Cup then we bring you the ultimate solution you need for the biggest sporting stage of it all. </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className='center-container-second'>
          <Row>
            <Col>
              <h2 className="header-title-40 text-center">Vinfotech brings to you FIFA World Cup Fantasy white label product – The ultimate fan engagement product</h2>
              <div className="feature-box-wrap">
                 <div className="cell-wrap">
                  <div className="cell">
                    <div className="top-cell">
                      <p>Engage Millennials and Gen-Z and capture their attention</p>
                    </div>
                    <div className="figure">
                      <img src={Images.IMG_GAMES} alt="" />
                    </div>
                  </div>
                 </div>
                 <div className="cell-wrap">
                  <div className="cell">
                    <div className="top-cell">
                      <p>Bring them regularly on to your platform</p>
                    </div>
                    <div className="figure">
                      <img src={Images.IMG_ENGAGEMENT} alt="" />
                    </div>
                  </div>
                 </div>
                 <div className="cell-wrap">
                  <div className="cell">
                    <div className="top-cell">
                      <p>Bring in a sense of competition</p>
                    </div>
                    <div className="figure">
                      <img src={Images.IMG_BRAND_POSITIONING} alt="" />
                    </div>
                  </div>
                 </div>
                 <div className="cell-wrap">
                  <div className="cell">
                    <div className="top-cell">
                      <p>Change perception of your users – make your brand fun and engaging</p>
                    </div>
                    <div className="figure">
                      <img src={Images.IMG_PERCEPTION} alt="" />
                    </div>
                  </div>
                 </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className='fluid-container-row gray-container'>
          <Container className='center-container-second'>
            <Row>
              <Col>
                <h2 className="header-title-40 text-center no-margin-b">Powering fantasy sports for World Cup would allow you to grow popularity of your brand tremendously </h2>
                <h2 className="header-title-32 text-center secondary-color">Gamification in action - From passive viewing to active involvement</h2>
                <div className="feature-box-wrap">
                 <img src={Images.GAMIFICATION_IMG} alt="" className='gamification_img hidden-sm'/>
                 <img src={Images.GAMIFICATION_IMG_SM} alt="" className='gamification_img visible-sm'/>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container className='center-container-second'>
          <Row>
            <Col>
              <h2 className="header-title-40 text-center">World Cup Fantasy Software</h2>
              <div className="video_wrapper">
                  <iframe width="100%" height="auto" src="https://www.youtube.com/embed/cIQ2JgnW5G8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className='fluid-container-row gray-container'>
          <Container className='center-container-third'>
            <Row>
              <Col>
                <h2 className="header-title-40 text-center">Some incredible benefits that the product comes with</h2>
                <div className="feature-box-wrap scrollable">
                  <div className="product-cell">
                    <div className="figure">
                      <img src={Images.PRODUCT_IMG1} alt="" />
                    </div>
                    <div className="paragraph">Sponsor Visibility with link to their product / website thus helping draw a massive traffic to those properties</div>
                  </div>
                  <div className="product-cell">
                    <div className="figure">
                      <img src={Images.PRODUCT_IMG2} alt="" />
                    </div>
                    <div className="paragraph">Private Contests allow users to play with their friends helping your brand reach to newer audience</div>
                  </div>
                  <div className="product-cell">
                    <div className="figure">
                      <img src={Images.PRODUCT_IMG3} alt="" />
                    </div>
                    <div className="paragraph">Generate valuable user data and utilize it to promote your products</div>
                  </div>
                  {/* <Swiper
                    scrollbar={{
                      hide: false
                    }}
                    modules={[Scrollbar]}
                    className="mySwiper"
                  >
                    <SwiperSlide>
                      <div className="product-cell">
                        <div className="figure">
                          <img src={Images.PRODUCT_IMG1} alt="" />
                        </div>
                        <div className="paragraph">Sponsor Visibility with link to their product / website thus helping draw a massive traffic to those properties</div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="product-cell">
                        <div className="figure">
                          <img src={Images.PRODUCT_IMG2} alt="" />
                        </div>
                        <div className="paragraph">Private Contests allow users to play with their friends helping your brand reach to newer audience</div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="product-cell">
                        <div className="figure">
                          <img src={Images.PRODUCT_IMG3} alt="" />
                        </div>
                        <div className="paragraph">Generate valuable user data and utilize it to promote your products</div>
                      </div>
                    </SwiperSlide>
                  </Swiper> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container className='center-container-second'>
          <Row>
            <Col>
              <h2 className="header-title-40 text-center">The benefits of engagement <br /> Long term, loyal Fans</h2>
              <div className="benefit-list">
                <div className="benefit-item">
                  <div className="num"><span>01</span></div>
                  <div className="content">Engagement of a Wider Fan Base</div>
                </div>
                <div className="benefit-item">
                  <div className="num"><span>02</span></div>
                  <div className="content">Accurate User Insight for Greater Sales</div>
                </div>
                <div className="benefit-item">
                  <div className="num"><span>03</span></div>
                  <div className="content">Low initial investment to build the platform</div>
                </div>
                <div className="benefit-item">
                  <div className="num"><span>04</span></div>
                  <div className="content">Superior Word-of-mouth marketing</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default AboutUs;
