/* eslint-disable */
import { Component, Fragment } from 'react';
import { _, Utils, Trans, Images  } from 'Helpers';
import { Col, Row } from 'reactstrap';
import { GameweekNativeSlider, Img } from 'Components';

const get_league_season = require('../../fixture.json');
const get_teams = require('../../team.json');
const final_fixture = require('../../final_fixture.json');

class TempFixture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FixturesList: []
    }
  }
  
  componentDidMount() {
    this.setState({
      FixturesList: final_fixture
    })

    // this.dataMatch()
  }

  dataMatch  = async () => {
    const fixture_data = get_league_season.response.data
    const team_data = get_teams.response.data
    let res = await  _.map(fixture_data, (item) => {
      item.away_team_name = "";
      item.home_team_name = "";
      item.flag_away = "https://sl-dev-vtech.s3.amazonaws.com/assets/img/defailt_flag_shield.png";
      item.flag_home = "https://sl-dev-vtech.s3.amazonaws.com/assets/img/defailt_flag_shield.png";
      item.timestamp = new Date(item.season_scheduled_date).getTime() / 1000
      _.map(team_data, (obj) => {
        if(item.away_uid == obj.team_uid) {
          item.away_team_name = obj.team_name;
          item.flag_away = obj.flag_url;
        }
        if(item.home_uid == obj.team_uid) {
          item.flag_home = obj.flag_url;
          item.home_team_name = obj.team_name;
        }
        return obj
      })
      return item
    })

    console.log(JSON.stringify(res));
  
  }
  
  render() {
    const { FixturesList } = this.state
    let current_date = '';
    return (
      <div>
        <Row>
          <Col>
            <GameweekNativeSlider {...{
              scoring_weeks: [{ week: 1 }],
              SelectedWeek: 1,
              gameweeklastupdate: new Date().valueOf(),
              SelectWeekHandle: () => { },
              is_fixture: true,
              is_temp: true
            }} />

          </Col>
        </Row>
        <Row>
          <Col className='fixtures-list-wrap'>
            <div className="fixtures-list" >
              {
                _.map(_.orderBy(FixturesList, ['timestamp'],
                  ['asc']), (item, index) => {
                    let firstChar = Utils.getUtcToLocal(item.season_scheduled_date, "dddd, MMM D");
                    let ShowHeadShow = false;
                    if (firstChar != current_date) {
                      current_date = firstChar
                      ShowHeadShow = true;
                    }
                    return (
                      <Fragment key={index}>
                        {
                          ShowHeadShow &&
                          <div className="fixture-header">
                            {firstChar} 
                          </div>
                        }
                        <>
                          <div className={`fixture-item`}>
                            <div className="cell">
                              <div {...{ className: `fx-name` }} title={item.home_team_name || item.home}>
                                {item.home_team_name || item.home || 'TBD'}
                                {
                                  (!item.home_team_goal && Utils.isDateTimePast(item.season_scheduled_date, item)) &&
                                  <div className="fx-score-wrap">
                                    <b>{item.home_team_score}/{item.home_wickets}</b> <small>({item.home_overs} <Trans>OVR</Trans>)</small>
                                  </div>
                                }
                              </div>
                              <figure className='fx-flag'>
                                <Img src={item.flag_home} fallbacksrc={Images.DEFAULT_FLAG} alt="" />
                              </figure>
                            </div>


                            <div className="cell mid">
                              <div className="score-capsule">
                                {
                                  Utils.isDateTimePast(item.season_scheduled_date, item) ?
                                    (item.home_team_goal ? (item.home_team_goal + ' - ' + item.away_team_goal) : 'vs')
                                    // (item.home_team_goal || `${item.home_team_score}/${item.home_wickets} - Ov: ${item.home_overs}`) + ' - ' + (item.away_team_goal || `${item.away_team_score}/${item.away_wickets} - Ov: ${item.away_overs}`)
                                    :
                                    Utils.DateFormat(Utils.getUtcToLocal(item.season_scheduled_date), "HH:mm")
                                }
                              </div>
                            </div>

                            <div className="cell last">
                              <figure className='fx-flag'>
                                <Img src={item.flag_away} fallbacksrc={Images.DEFAULT_FLAG} alt="" />
                              </figure>
                              <div {...{ className: `fx-name` }} title={item.away_team_name || item.away}>
                                {item.away_team_name || item.away || 'TBD'}
                                {
                                  (!item.away_team_goal && Utils.isDateTimePast(item.season_scheduled_date, item)) &&
                                  <div className="fx-score-wrap">
                                    <b>{item.away_team_score}/{item.away_wickets}</b> <small>({item.away_overs} <Trans>OVR</Trans>)</small>
                                  </div>
                                }
                              </div>
                            </div>

                          </div>
                        </>


                      </Fragment>
                    )
                  })
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TempFixture;
