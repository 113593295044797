/* eslint-disable */
import { Component } from 'react';
import { Container, Row, Col, FormGroup, Input, Button, Form, Alert } from 'reactstrap'
import { SocialForm } from 'Components'
import { Utils, Http, API, Auth, Constant, withRouter, Trans } from 'Helpers'
import { ForgotPassword } from 'Modals';
import { ReduxHOC } from 'HOC';

const {
  REACT_APP_IS_DEMO_USE
} = process.env

const md5 = require('md5');
class LoginFormSM extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      posting: false,
      formValid: false,
      isEye: false,
      email: "",
      password: "",
      ForgotPasswordShow: false,
      DemoDataShow: false,
      DemoData: ''
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'password') {
      value = value.trim()
    }
    this.setState({ [name]: value }, this.validateForm);
  };

  validateForm() {
    const { password, email } = this.state
    this.setState({
      formValid:
        password != '' &&
        email != '' &&
        Utils.isFormValid(['password', 'email'], this.state)
    });
  }
  sendMessageToApp(action) {

    try {
      window.ReactNativeWebView.postMessage(JSON.stringify(action));
    } catch (error) { }

  }
  reload = () => {
    // const { history } = this.props
    // history.push(Constant.DASHBOARD_PATH);
    // window.location.reload();
    // setTimeout(() => {
    // }, 500);
  }
  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ posting: true });
    const { email, password } = this.state;
    const { isGuestUser, history, closeModal, onTeamSubmit, actions } = this.props;

    let param = {
      "email": email,
      "password": md5(password),
      "device_type": Constant.DEVICE_TYPE,
      "device_id": Constant.DEVICE_ID
    };
    Http.post(API.LOGIN, param).then(response => {
      if (window.ReactNativeWebView) {
        let data = {
          action: "login",
          type: 'login',
          targetFunc: "loginFunc",
          userData: response.data
        };
        this.sendMessageToApp(data);
      }
      Auth.setAuth(response.data.session_key);
      Utils.setProfile(response.data.user_profile);
      Utils.setExistingUser(response.data.user_profile.existing_user)


      actions.gtmEventFire('login', {
        'user_id': response.data.user_profile.user_unique_id,
        'user_name': response.data.user_profile.user_name,
        'login_method': 'email',
      }, true)

      // Utils.setLineupMasterId().then(res => {
      //   console.log('LoginFormSM');
      //   localStorage.setItem('_jl', 1)
      //   actions.setAppMaster(Utils.getMasterData() || {})
      //   actions.setCurrentLineup(Utils.getCurrentLineup())
      // })
      actions.setProfileData(response.data.user_profile)
      history.push(Constant.DASHBOARD_PATH);
      closeModal()

    }).catch(error => {
      console.log(error)
      this.setState({ posting: false });
      // if(!isGuestUser) {
      // }
      // closeModal()
    });
  }




  componentDidMount() {
    if (Utils.isDev()) {
      // this.setState({
      //   // email: "murtaza.fida@vinfotech.com",
      //   // password: "ab!@3456"
      //   email: "dev@mailinator.com",
      //   password: "12345678"
      // }, this.validateForm)
    }

    fetch(`/demo-data.txt?${new Date().getTime()}`, { cache: 'no-cache' })
      .then((response) => response.text())
      .then((res) => {
        this.setState({
          DemoData: res,
          DemoDataShow: true
        })
      });

  }
  ForgotPasswordToggle = () => {
    this.setState({
      ForgotPasswordShow: !this.state.ForgotPasswordShow
    })
  }

  render() {
    const { toggleForm, t } = this.props
    const {
      isEye,
      email,
      password,
      formValid,
      posting,
      ForgotPasswordShow,
      DemoDataShow,
      DemoData
    } = this.state;

    const ForgotPasswordProps = {
      isOpen: ForgotPasswordShow,
      toggle: this.ForgotPasswordToggle
    }
    const SocialFormProps = {
      ...this.props
    }

    return (
      <div>
        <div className="landing-form popup">
          <Row className='onboarding-gutter-fix'>
            <Col>
              <h4 className='landing-form-title'><Trans>Login to your account</Trans></h4>
            </Col>
          </Row>
          <Row className='onboarding-gutter-fix'>

            <Col md={12}>
              <Form onSubmit={this.onSubmit}>
                <Row>
                  <Col sm={12}>
                    <FormGroup>
                      <Input type="email"
                        id='email'
                        name='email'
                        placeholder={t('Email')}
                        required
                        value={email}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("email", email)}
                        valid={Utils.isValid("email", email)} />
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup className='pwd-form-group'>
                      <i className={`pwd-eye ${isEye ? 'icon-eye' : 'icon-eye-hide'}`} onClick={() => this.setState({ isEye: !isEye })}></i>
                      <Input
                        id='password'
                        name='password'
                        placeholder={t('Password')}
                        required
                        type={isEye ? 'text' : 'password'}
                        maxLength={50}
                        value={password}
                        autoComplete="off"
                        onChange={this.handleChange}
                        invalid={Utils.isInvalid("password", password)}
                        valid={Utils.isValid("password", password)} />
                    </FormGroup>
                    <a className='fgt-pwd-btn' onClick={() => this.ForgotPasswordToggle()}><Trans>Forgot Password?</Trans></a>
                  </Col>
                </Row>
                <Row className='m-t-20 onboarding-gutter-fix'>
                  <Col>
                    <Button type='submit' className='btn-block'
                      disabled={!formValid || posting}><Trans>Login</Trans></Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col md={12}>
              <SocialForm className="popup" {...SocialFormProps} />
            </Col>

            <Col md={12}>
              <div className="form-switch-wrap"><Trans>Not Registered?</Trans> <a className='text-medium color-blk' onClick={() => toggleForm(false)}><Trans>Sign Up</Trans></a></div>
            </Col>
            {
              (REACT_APP_IS_DEMO_USE == 'true' && DemoDataShow) &&
              <Col md={12} className='m-t-md'>
                <Alert color="light" className='over-budget-msz'>
                  <span dangerouslySetInnerHTML={{ __html: DemoData }}></span>
                </Alert>
              </Col>
            }

          </Row>
        </div>
        {
          ForgotPasswordShow &&
          <ForgotPassword {...ForgotPasswordProps} />
        }

      </div>
    )
  }
}
LoginFormSM.defaultProps = {
  onTeamSubmit: () => { },
  closeModal: () => { },
  isGuestUser: false
}
const LoginFormSMWraped = withRouter(ReduxHOC(LoginFormSM));

export default LoginFormSMWraped;



